<template>
  <field-autocomplete
    ref="autocomplete"
    v-bind="$attrs"
    v-model="iValue"
    :attach="attach"
    :field="{}"
    :remote="remote"
    :return-object="returnObject"
    cache-items
    custom-item
    custom-selection
    hide-selected
    item-text="name"
    item-value="id"
    v-on="$listeners"
  >
    <template #selection="data">
      <div class="v-select__selection v-select__selection--comma">
        <v-avatar
          v-if="initial(data.item)"
          color="primary"
          size="24"
        >
          <span class="white--text text-caption">
            {{ initial(data.item) | uppercase }}
          </span>
        </v-avatar>
        <span class="text--truncate ml-1">{{ getName(data.item) }}</span>
      </div>
    </template>
    <template #item="data">
      <v-avatar
        v-if="initial(data.item)"
        color="primary"
        size="30"
        class="mr-2"
      >
        <span class="white--text">
          {{ initial(data.item) | uppercase }}
        </span>
      </v-avatar>
      <v-list-item-content>
        <v-list-item-title v-html="getName(data.item)" />
      </v-list-item-content>
    </template>
  </field-autocomplete>
</template>

<script>
import FieldAutocomplete from '../FieldAutocomplete'

export default {
  name: 'FieldEntityCompanyRetailer',
  components: {
    FieldAutocomplete
  },
  props: {
    attach: {
      type: Boolean,
      default: true
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Array, Object, Number],
      default: null
    }
  },
  computed: {
    iValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('change', val)
      }
    },
    remote () {
      return {
        url: '/companies/retailers',
        urlFind: '/companies/retailers/:id',
        urlFindKey: 'id',
        method: 'GET',
        params: {
          searchParam: 'name'
        }
      }
    }
  },
  created () {
    this.$off('change')
  },
  methods: {
    getName (item) {
      return item.name ?? this.$t('common.undefined')
    },
    initial (item) {
      return item.name?.charAt(0)
    }
  }
}
</script>
