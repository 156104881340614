<template>
  <component
    :is="displayMode"
    ref="mode"
    v-bind="$attrs"
    :can-add="canAdd"
    :can-write="canWrite"
    :documents.sync="documents"
    :extensions="extensions"
    :is-upload-error="uploadError"
    :loading="loading"
    :mime-type="assetTag"
    :options="options"
    :results-per-page="itemsPerPage"
    :upload="uploadMethod"
    :multiple="multiple"
    @update:page="updatePage"
    @change-page="page = $event"
    @upload-error="uploadError = $event"
    @uploaded="addDocument"
    @delete="deleteFile"
    @page="page = $event"
    @see-all="fetchAllDocuments"
    @sort="sortDocuments"
    @update="updateDocument"
  >
    <template #list-title>
      <slot name="list-title" />
    </template>
  </component>
</template>

<script>
import Modes from '@/constants/DocumentManager'
import DocumentFetcher from '@/services/fetchers/Documents'
import { useMessages } from '@/compositions/messages'
import _ from 'lodash'

export default {
  name: 'DocumentManager',
  components: {
    Swiper: () => import('./Modes/Swiper'),
    Grid: () => import('./Modes/Grid'),
    List: () => import('./Modes/List')
  },
  props: {
    assetTag: {
      type: String,
      default: null
    },
    canWrite: Boolean,
    entity: {
      type: Object,
      required: true
    },
    extensions: {
      type: Array,
      default: null
    },
    fetch: {
      type: Function,
      default: params => DocumentFetcher.get(params)
    },
    gridOptions: {
      type: Object,
      default: null
    },
    max: {
      type: Number,
      default: null
    },
    mode: {
      type: String,
      default: Modes.grid,
      validator: value => Object.keys(Modes).indexOf(value) !== -1
    },
    multiple: {
      type: Boolean,
      default: false
    },
    swiperOptions: {
      type: Object,
      default: null
    }
  },
  setup () {
    const { errorMessage, successMessage } = useMessages()
    return { errorMessage, successMessage }
  },
  data () {
    return {
      documents: [],
      loading: false,
      metaData: null,
      uploadError: false
    }
  },
  computed: {
    canAdd () {
      const totalDocs = this.documents?.length ?? 0
      return this.canWrite && !(this.max && this.max <= totalDocs)
    },
    displayMode () {
      return this.$vuetify.breakpoint.name === 'xs'
        ? Modes.swiper
        : this.mode
    },
    itemsPerPage () {
      if (this.pagination?.resultsPerPage) {
        return this.pagination.resultsPerPage
      }
      return this.displayMode === Modes.swiper ? 50 : 20
    },
    options () {
      switch (this.mode) {
        case Modes.swiper:
          return this.swiperOptions
        default:
          return {
            ...this.gridOptions,
            lastPage: this.metaData?.last_page ?? 1,
            page: this.metaData?.current_page ?? 1
          }
      }
    }
  },
  watch: {
    entity: {
      deep: true,
      handler (val, prevVal) {
        if (!_.isEqual(val, prevVal)) {
          this.fetchDocuments({ page: 1 })
        }
      }
    }
  },
  created () {
    if (this.mode === 'grid' && this.gridOptions?.sortable) {
      this.fetchDocuments({ page: 1 }).then(() => {
        this.fetchAllDocuments()
      })
    } else {
      this.fetchDocuments({ page: 1 })
    }
  },
  methods: {
    _fetch (params) {
      return this.fetch({
        tag: this.assetTag ?? undefined,
        entityId: this.entity.entityId,
        entityType: this.entity.entityType.description,
        page: params.page,
        resultsPerPage: this.itemsPerPage
      })
    },
    addDocument (document) {
      document.uploadSuccess = true
      setTimeout(() => {
        document.uploadSuccess = false
      }, 5000)

      if (this.mode === Modes.grid && this.options.sortable) {
        this.documents = [...this.documents, document]
      } else {
        this.documents = _.take([...this.documents, document], this.itemsPerPage)
      }
    },
    async deleteFile (id) {
      try {
        await DocumentFetcher.delete(id)
        this.documents = this.documents?.filter(asset => asset.damAssetId !== id) ?? []
        this.successMessage('common.delete.success')
      } catch (error) {
        this.errorMessage(error.message)
      }
    },
    async fetchAllDocuments () {
      const meta = this.metaData
      try {
        for (let i = meta.current_page + 1; i <= meta.last_page; i++) {
          await this.fetchDocuments({ page: i, stack: true })
        }
      } catch { }
    },
    async fetchDocuments (params) {
      try {
        this.loading = true
        const resp = await this._fetch(params)
        this.documents = params.stack
          ? [...this.documents, ...resp.data.data]
          : resp.data.data
        this.metaData = resp?.data.meta ?? []
      } catch (e) {
        this.errorMessage('common.error.base')
        if (params.stack) {
          throw new Error(e)
        }
      } finally {
        this.loading = false
      }
    },
    updateDocument (document) {
      this.documents.find(f => f.damAssetId === document.documentId).title = document.title
    },
    updateOptions (options) {
      switch (this.mode) {
        case Modes.swiper:
          this.$emit('update:swiper-options', { ...options })
          break
        default:
          this.$emit('update:grid-options', { ...options })
          break
      }
    },
    updatePage (page) {
      this.fetchDocuments({ page, stack: this.options.sortable })
    },
    uploadMethod (file, onProgressUpload) {
      return DocumentFetcher.add({
        file,
        title: file.name,
        entityId: this.entity.entityId,
        entityType: this.entity.entityType.description,
        tag: this.assetTag ?? undefined
      }, onProgressUpload)
    },
    sortDocuments (e) {
      const { newIndex, oldIndex } = e.moved
      this.documents.splice(newIndex - 1, 0, this.documents.splice(oldIndex - 1, 1)[0])
    }
  }
}
</script>
