<script setup>
import colors from 'vuetify/lib/util/colors'
import { computed, getCurrentInstance } from 'vue'

const props = defineProps({
  color: {
    type: String,
    default: undefined
  },
  size: {
    type: [Number, String],
    default: undefined
  },
  tile: Boolean
})
const iColor = computed(() => {
  if (props.color) return props.color

  const root = getCurrentInstance().proxy
  if (root.$slots?.default?.length > 0 && typeof root.$slots.default[0].text === 'string') {
    const trimmed = root.$slots.default[0].text.trim()
    const hash = Object.values(trimmed).reduce((acc, val) => {
      return acc + val.charCodeAt(0)
    }, 0)
    const cc = Object.values(colors)
    return cc[hash % cc.length].lighten2
  }
  return undefined
})
</script>

<template>
  <v-avatar
    :color="iColor"
    :size="size"
    :tile="tile"
  >
    <slot />
  </v-avatar>
</template>
