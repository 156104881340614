<script setup>
import BtnSocial from '@/components/Buttons/BtnSocial'
import { useUniverseStore } from '@/stores/universe'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

const universeStore = useUniverseStore()

const { getSettingByPath } = storeToRefs(universeStore)

const socialNetworks = computed(() => {
  const values = getSettingByPath.value('footer.socialNetworks', {})
  return Object.keys(values)
    .filter(key => values[key].link)
    .reduce((obj, key) => {
      obj[key] = values[key]
      return obj
    }, {})
})
</script>

<template>
  <div>
    <btn-social
      v-for="network in socialNetworks"
      :key="network.title"
      v-bind="network"
      :title="$t(network.title)"
    >
      <v-icon small>
        {{ network.icon }}
      </v-icon>
    </btn-social>
  </div>
</template>
