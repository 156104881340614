<script setup>
import DataTableTask from '@/components/DataTables/DataTableTask'
import dtHeaders from '@/constants/dataTableDefinitions/taskManager'
import TaskFetcher from '@/services/fetchers/Tasks'
import TaskManagerHeader from './TaskManagerHeader'
import TaskManagerFooter from './TaskManagerFooter'
import { useTaskEditorStore } from '@/stores/taskEditor'
import { useAuthStore } from '@/stores/auth'
import { useTaskStore } from '@/stores/task'
import { storeToRefs } from 'pinia'
import { computed, getCurrentInstance, ref, watch } from 'vue'

const root = getCurrentInstance().proxy

const authStore = useAuthStore()
const taskStore = useTaskStore()
const taskEditorStore = useTaskEditorStore()

const { user } = storeToRefs(authStore)
const { entity, dialog, title } = storeToRefs(taskStore)
const { saved } = storeToRefs(taskEditorStore)

const headers = ref([])
const filters = ref({})

const entityDisplayName = computed(() => entity.value?.entityName ?? null)
const entityType = computed(() => entity.value?.entityType ?? null)
const iValue = computed({
  get: () => dialog.value,
  set: val => {
    if (!val) {
      taskStore.hideDialog()
    }
  }
})
const userId = computed(() => user.value?.id ?? null)
const refDataTable = computed(() => root.$refs?.datatable?.$refs?.datatable ?? null)
const remote = computed(() => {
  const p = {
    callback: entityType.value ? TaskFetcher.fetch : TaskFetcher.priorities,
    params: {
      withEntities: 1
    }
  }
  if (entityType.value) {
    p.params['entity[entityType]'] = entityType.value
    p.params['entity[entityId]'] = entity.value.entityId
  }
  return p
})

watch(dialog, (val, oldVal) => {
  if (val && val !== oldVal) {
    updateHeader()
    root.$nextTick(() => {
      if (refDataTable.value) {
        refresh()
      }
    })
  }
})
watch(saved, val => {
  if (val && dialog.value) {
    refresh()
  }
})

function refresh () {
  refDataTable.value.fetch()
}
function showDialogTask (task = null) {
  const dialog = {}
  if (task) {
    dialog.task = task
    dialog.entities = task.entities
  } else if (entity.value) {
    dialog.entities = [entity.value]
  }
  taskEditorStore.showDialog(dialog)
}
function updateHeader () {
  let cpy = [...dtHeaders]
  if (!entity.value) {
    cpy = dtHeaders.filter(f => f.value !== 'performerUserProfileId')
  }
  headers.value = cpy
}
</script>

<template>
  <v-dialog
    v-model="iValue"
    @click:outside="$emit('close')"
  >
    <v-card>
      <task-manager-header
        :title="$t(title, { name: entityDisplayName })"
        @close="iValue = false"
      />
      <v-card-text class="pa-5">
        <div class="text-right">
          <v-btn
            color="primary"
            text
            @click="showDialogTask()"
          >
            <v-icon class="mr-2">
              fa-plus-circle
            </v-icon>
            {{ $t('task.manager.create') }}
          </v-btn>
        </div>
        <data-table-task
          ref="datatable"
          :headers="headers"
          :remote="remote"
          :filters.sync="filters"
          :pagination="!!entityType"
          @edit-item="showDialogTask"
        />
        <task-manager-footer :user-id="userId" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
