<template>
  <field-autocomplete
    v-model="iValue"
    v-bind="$attrs"
    :field="{}"
    :filter="customFilter"
    :item-text="itemText"
    :item-value="itemValue"
    :remote="remote"
    :return-object="returnObject"
    cache-items
    class="field"
    custom-selection
    custom-item
    hide-selected
    v-on="$listeners"
  >
    <template #selection="data">
      <div class="v-select__selection v-select__selection--comma d-flex align-center">
        <avatar
          :size="24"
          :initials="initial(data.item)"
          :url="data.item.avatar && data.item.avatar.urlCached"
        />
        <span class="text--truncate ml-1">{{ getName(data.item) }}</span>
      </div>
    </template>
    <template #item="data">
      <avatar
        :size="30"
        :initials="initial(data.item)"
        :url="data.item.avatar && data.item.avatar.urlCached"
        class="mr-2"
      />
      <v-list-item-content>
        <v-list-item-title v-html="getName(data.item)" />
        <v-list-item-subtitle v-html="data.item.email" />
      </v-list-item-content>
    </template>
  </field-autocomplete>
</template>

<script>
import Autocomplete from '@/services/Autocomplete'
import FieldAutocomplete from '../FieldAutocomplete'
import initials from '@/services/initials'

export default {
  name: 'FieldEntityUserProfileFacilitator',
  components: {
    FieldAutocomplete
  },
  props: {
    excludeIds: {
      type: Array,
      default: () => ([])
    },
    itemText: {
      type: String,
      default: 'email'
    },
    itemValue: {
      type: String,
      default: 'id'
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    teamId: {
      type: Number,
      default: null
    },
    value: {
      type: [Array, Object, Number],
      default: null
    },
    withProfile: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('change', val)
      }
    },
    remote () {
      const userProfileId = this.returnObject
        ? this.iValue?.profile?.userProfileId
        : this.iValue

      const params = {
        searchParam: 'search',
        withProfile: this.withProfile ? 1 : 0,
        excludeIds: this.excludeIds
      }
      if (this.teamId) {
        params.teamId = this.teamId
      }

      return {
        url: '/users/facilitators',
        urlFind: '/users/facilitators',
        urlFindKey: 'id',
        urlFindParams: {
          id: userProfileId ?? null
        },
        method: 'GET',
        params
      }
    }
  },
  created () {
    this.$off('change')
  },
  methods: {
    customFilter: Autocomplete.userEntitycustomFilter,
    getName (item) {
      if (!item.firstName && !item.lastName) {
        return this.$t('common.undefined')
      }
      return `${item.firstName || ''} ${item.lastName || ''}`
    },
    initial (item) {
      return initials(item.firstName, item.lastName, item.displayName, item.email)
    }
  }
}
</script>
