import Rights from '@/constants/Rights'

export default class {
  /**
   * Format rights object according to entity type and array of rights from server

   * @param rightsArray
   * @return {Object}
   */
  static formatRights (rightsArray) {
    const rights = {}
    for (const entity of Object.getOwnPropertySymbols(Rights)) {
      const entityRights = Rights[entity]
      rights[entity.description.toLowerCase()] = rightsArray.reduce((acc, right) => {
        if (Object.values(entityRights).indexOf(right) > -1) {
          acc[Object.keys(entityRights).find(key => entityRights[key] === right)] = true
        }
        return acc
      }, {})
    }
    return rights
  }

  static isAllowed (rightsToCheck, userRights) {
    let allowed = true
    if (rightsToCheck) {
      rightsToCheck.forEach(right => {
        const ownPropertySymbols = Object.getOwnPropertySymbols(Rights)
        for (const s of ownPropertySymbols) {
          const sRights = Rights[s]
          const indexOf = Object.values(sRights).indexOf(right)
          allowed = indexOf > -1 && userRights[s.description.toLowerCase()][Object.keys(sRights)[indexOf]]
          if (allowed) return true
        }
      })
    }
    return allowed
  }
}
