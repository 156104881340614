<template>
  <div>
    <span v-if="iValue === getStatusId('new')">
      {{ $t('common.status') }}: <strong>{{ $t('ref-status.new') }}</strong>
    </span>
    <field-autocomplete
      v-else
      v-model="iValue"
      v-bind="$attrs"
      :rules="iRules"
      :return-object="returnObject"
      :items="items"
      :item-text="getItemText"
      item-value="id"
    />
  </div>
</template>

<script>
import refStatuses from '@/services/refStatuses'
import { useValidation } from '@/compositions/validation'
import { useRefStore } from '@/stores/ref'
import { mapState } from 'pinia'

export default {
  name: 'FieldUserStatus',
  components: {
    FieldAutocomplete: () => import('../FieldAutocomplete')
  },
  props: {
    excludeSlugs: {
      type: Array,
      default: null
    },
    mode: {
      type: String,
      default: null
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    rules: {
      type: [String, Object],
      default: () => ({})
    },
    value: {
      type: Number,
      default: null
    }
  },
  setup (props) {
    const { iRules } = useValidation(props)

    return { iRules }
  },
  computed: {
    items () {
      let slugs = ['active', 'inactive', 'closed']
      if (this.excludeSlugs) {
        slugs = slugs.filter(f => this.excludeSlugs.some(s => s !== f))
      }
      return this.getRefs.refStatuses.filter(s => slugs.includes(s.slug))
    },
    iValue: {
      get () {
        if (this.value) {
          return this.value
        } else if (this.mode === 'creation') {
          return this.getStatusId('new')
        }
        return null
      },
      set (status) {
        this.$emit('input', status)
      }
    },
    ...mapState(useRefStore, {
      getRefs: 'getRefs'
    })
  },
  methods: {
    getItemText (i) {
      if (i.slug === 'closed') {
        return this.$t('user.unavailable')
      }
      return this.$t(`ref-status.${i.slug}`)
    },
    getStatusId (state) {
      return refStatuses.get(state).id
    }
  }
}
</script>
