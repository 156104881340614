<script setup>
import { useAuthStore } from '@/stores/auth'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
import { computed, defineAsyncComponent, ref } from 'vue'

const DialogLoadingProfile = defineAsyncComponent(() => import('@/components/Dialogs/DialogLoadingProfile'))
const ProfileSelectorSmall = defineAsyncComponent(() => import('./ProfileSelectorSmall'))
const ProfileSelectorLarge = defineAsyncComponent(() => import('./ProfileSelectorLarge'))

const authStore = useAuthStore()
const userStore = useUserStore()

const { user } = storeToRefs(authStore)
const { profile } = storeToRefs(userStore)

const loading = ref(false)

const profileComponent = computed(() => profiles.value.length < 10 ? ProfileSelectorSmall : ProfileSelectorLarge)
const profiles = computed(() => user.value?.profiles ?? [])
</script>

<template>
  <div>
    <component
      :is="profileComponent"
      :profile="profile"
      :profiles="profiles"
      @set-profile="loading = $event"
    >
      <template #activator="{ on, attrs }">
        <slot
          name="activator"
          v-bind="{ on, attrs }"
        />
      </template>
    </component>
    <dialog-loading-profile
      v-if="loading"
      :profile="loading"
      :visible="!!loading"
      @visible="loading = $event"
    />
  </div>
</template>
