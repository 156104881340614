<template>
  <field-string
    v-model="iValue"
    v-bind="$attrs"
    :rules="iRules"
    :validation="validation"
    type="email"
  />
</template>

<script>
import { useValidation } from '@/compositions/validation'
import FieldString from '../FieldString'

export default {
  name: 'FieldEmail',
  components: {
    FieldString
  },
  props: {
    rules: {
      type: [String, Object],
      default: () => ({})
    },
    validation: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: null
    }
  },
  setup (props) {
    const { iRules } = useValidation(props, 'email')

    return { iRules }
  },
  computed: {
    iValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>
