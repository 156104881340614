import RefFetcher from '@/services/fetchers/Ref'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useRefStore = defineStore('ref', () => {
  const list = ref({})

  const getRefs = computed(() => list.value)

  function clear () {
    list.value = {}
  }
  async function loadRefs () {
    try {
      const response = (await RefFetcher.getRefs()).data
      if (response) {
        list.value = response
      } else {
        throw new Error('Failed to fetch the ref statuses: invalid response.')
      }
    } catch (e) {
      throw new Error(e.toJSON())
    }
  }

  return {
    list,
    getRefs,
    clear,
    loadRefs
  }
}, { persist: true })

if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(acceptHMRUpdate(useRefStore, import.meta.webpackHot))
}
