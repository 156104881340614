<template>
  <section
    v-infinite-scroll="loadMore"
    class="notification"
    data-test="drawer-notifications"
    infinite-scroll-distance="10"
  >
    <div class="primary notification__header">
      <div
        data-test="drawer-notifications-title"
        class="notification__header__title"
      >
        {{ $t('common.notifications') }}
      </div>
      <div class="notification__header__actions">
        <v-btn
          v-if="anyUnread"
          icon
          @click.stop="readAll"
        >
          <v-icon
            :title="$t('common.notifications.read-all')"
            color="white"
            class="notification__header__actions__icon"
            size="18"
          >
            fas fa-check
          </v-icon>
        </v-btn>
        <v-btn
          icon
          @click.stop="reload"
        >
          <v-icon
            color="white"
            class="notification__header__actions__icon"
            size="18"
          >
            fas fa-redo-alt
          </v-icon>
        </v-btn>
        <v-btn
          icon
          @click.stop="toggleExpand"
        >
          <v-icon
            v-if="!isExpanded"
            color="white"
            class="notification__header__actions__icon"
            size="18"
          >
            fas fa-expand-alt
          </v-icon>
          <v-icon
            v-if="isExpanded"
            color="white"
            class="notification__header__actions__icon"
            size="18"
          >
            fas fa-compress-alt
          </v-icon>
        </v-btn>
        <v-btn
          icon
          data-test="btn-close-notifications"
          @click.stop="$emit('close')"
        >
          <v-icon
            color="white"
            class="notification__header__actions__icon"
            size="18"
          >
            fas fa-times
          </v-icon>
        </v-btn>
      </div>
    </div>
    <div class="notification__content">
      <div
        v-if="loading"
        class="notification__content__loader"
      >
        <v-progress-circular
          :width="3"
          :color="$vuetify.theme.themes.light['secondary']"
          indeterminate
        />
      </div>
      <template v-if="notifications && notifications.length > 0">
        <div
          v-for="(group, key) in groups"
          :key="key"
          class="px-3"
        >
          <div class="notification__content__header">
            <div class="notification__content__header__date">
              {{ key }}
            </div>
          </div>
          <notif
            v-for="n in group"
            :key="n.id"
            :title="getTitle(n)"
            :subtitle="subtitle(n)"
            :avatar="n.payload.avatar || n.payload.senderAvatar"
            :initials="getInitials(n)"
            :description="description(n)"
            :second-subtitle="secondSubtitle(n)"
            :type="n.refNotificationType"
            :date="$t('common.date-time', {
              date: date(n),
              time: time(n)
            })"
            :path="n.payload.path"
            :see-more-link="n.payload.seeMoreLink"
            :no-see-more-link="n.payload.noSeeMoreLink"
            :status-type="n.payload.statusType"
            :message="message(n)"
            :unread="!n.readAt"
            :sender-id="n.payload.senderId"
            :payload="n.payload"
            class="mb-3"
            @click="readAndGo(n)"
            @delete="deleteNotification(n)"
          />
        </div>
      </template>
      <div
        v-else-if="!loading"
        class="notification__none"
      >
        {{ $t('common.notifications.no_notif') }}
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue'
import Notif from './Notif'
import infiniteScroll from 'vue-infinite-scroll'
import initials from '@/services/initials'
import { useNotificationStore } from '@/stores/notification'
import { mapActions, mapState } from 'pinia'
import moment from 'moment'
import _ from 'lodash'

Vue.use(infiniteScroll)

export default {
  components: {
    Notif
  },
  props: {
    isExpanded: {
      type: Boolean,
      required: true
    },
    userId: {
      type: Number,
      required: true
    },
    profileType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    notifications () {
      return this.getNotifications
    },
    groups () {
      return _.groupBy(this.notifications, notif => {
        return moment(notif.createdAt).format('DD/MM/YYYY')
      })
    },
    anyUnread () {
      return this.getTotalUnreadNotification
    },
    ...mapState(useNotificationStore, {
      getNotifications: 'getNotifications',
      getTotalUnreadNotification: 'getTotalUnreadNotification'
    })
  },
  watch: {
    notifications () {
      this.$emit('numberOfNotifications', this.notifications.length)
    }
  },
  created () {
    this.reload()
  },
  methods: {
    getTitle (n) {
      if (n.refNotificationType === 'admin') {
        return null
      } else {
        return n.payload.project_title
      }
    },
    getInitials (n) {
      return initials(null, null, n.payload.authorDisplayName ?? n.payload.senderDisplayName)
    },
    message (n) {
      if (n.refNotificationType === 'answer' || n.refNotificationType === 'project') {
        return this.$t(n.payload.message)
      } else if (n.refNotificationType === 'task') {
        return n.payload.message
      } else if (n.refNotificationType === 'admin') {
        return this.$t(n.payload.message, n.payload)
      }
      return null
    },
    secondSubtitle (n) {
      if (n.refNotificationType === 'new-message') {
        if (n.payload.senderCompanyName) {
          return `<strong>${n.payload.senderDisplayName}</strong> | ${n.payload.senderCompanyName}`
        } else {
          return `<strong>${n.payload.senderDisplayName}</strong>`
        }
      } else if (n.refNotificationType === 'answer') {
        return n.payload.productName ? n.payload.productName : null
      }
    },
    description (n) {
      if (n.refNotificationType === 'new-message') {
        let str = n.payload.messageText?.substring(0, 115)
        if (n.payload.messageText && n.payload.messageText.length > 115) {
          str += '…'
        }
        return str
      } else if (n.refNotificationType === 'answer' || n.refNotificationType === 'project') {
        return this.$t(n.payload.subTitle, n.payload)
      }
    },
    subtitle (n) {
      let str = ''
      if (n.payload.request_type) {
        str = `${n.payload.request_type}`
      }

      return str
    },
    date (n) {
      const dateIsoString = n.payload.date ?? n.updatedAt
      return moment(dateIsoString).format('L')
    },
    time (n) {
      const dateIsoString = n.payload.date ?? n.updatedAt
      return moment(dateIsoString).format('HH:mm')
    },
    toggleExpand () {
      this.$emit('expand', !this.isExpanded)
    },
    async loadMore () {
      const lastNotification = _.last(this.notifications)
      if (lastNotification) {
        this.loading = true
        await this.fetchMoreNotifications({
          userId: this.userId,
          lastNotification: lastNotification.createdAt
        })
        this.loading = false
      }
    },
    async readAll () {
      this.loading = true
      await this.readAllNotifs({
        userId: this.userId
      })
      this.loading = false
    },
    readAllVisible () {
      _.each(this.notifications, notif => {
        this.read(notif)
      })
    },
    deleteNotification (notification) {
      this.deleteNotif({
        notificationId: notification.id
      })
    },
    async read (notification) {
      if (!notification.readAt) {
        await this.readNotif({
          notificationId: notification.id
        })
      }
    },
    async readAndGo (notification) {
      this.$emit('close')
      await this.read(notification)
      if (this.url(notification)) {
        location.href = notification.payload.path
      }
    },
    async reload () {
      this.loading = true
      await this.fetchNotifications({
        userId: this.userId
      })
      this.loading = false
    },
    url (notification) {
      const payload = notification.payload
      return {
        path: notification.payload.path,
        params: payload
      }
    },
    ...mapActions(useNotificationStore, {
      fetchNotifications: 'fetchNotifications',
      fetchMoreNotifications: 'fetchMoreNotifications',
      readAllNotifs: 'readAll',
      deleteNotif: 'deleteNotif',
      readNotif: 'read'
    })
  }
}
</script>

<style lang="scss" scoped>
.notification {
  &__header {
    padding: 6px 14px 17px 22px;
    display: flex;
    color: white;
    justify-content: space-between;

    &__title {
      font-size: 1.25rem;
      font-weight: 600;
      margin-top: 6px;
    }
  }

  &__none {
    padding: 12px 7px;
  }

  &__content {
    padding: 12px 7px;
    overflow-y: auto;
    height: 100%;

    &__loader {
      display: flex;
      justify-content: center;
      padding: 30px;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 11px;
      &__date {
        margin-left: 16px;
        font-weight: 600;
        font-size: 1.125rem;
      }
      &__read {
        font-weight: 400;
        font-size: 0.875rem;
        cursor: pointer;
      }
    }
  }
}
</style>
