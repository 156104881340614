export default Object.freeze({
  Admin: Symbol('Admin'),
  Answer: Symbol('Answer'),
  AnswerFeedback: Symbol('AnswerFeedback'),
  Api: Symbol('Api'),
  Applicant: Symbol('Applicant'),
  Channel: Symbol('Channel'),
  Collaborator: Symbol('Collaborator'),
  ContentManagement: Symbol('ContentManagement'),
  Contract: Symbol('Contract'),
  Expert: Symbol('Expert'),
  Facilitator: Symbol('Facilitator'),
  Favorite: Symbol('Favorite'),
  Feedback: Symbol('Feedback'),
  Need: Symbol('Need'),
  Product: Symbol('Product'),
  Project: Symbol('Project'),
  Quotation: Symbol('Quotation'),
  Retailer: Symbol('Retailer'),
  Task: Symbol('Task'),
  Team: Symbol('Team')
})
