<template>
  <validation-provider
    ref="provider"
    v-slot="{ errors, invalid, validated }"
    :name="$attrs.name"
    :rules="iRules"
    :vid="vid"
  >
    <v-input
      :value="iValue"
      :error-messages="errors"
    >
      <field-phone-country
        v-model="countryCode"
        v-bind="$props"
        :allowed-country-codes="allowedCountryCodes"
        :background-color="backgroundColor"
        class="mr-1 mt-0"
        style="max-width: 120px"
        @input="changeCountry"
      />
      <v-text-field
        v-model="iValue"
        v-bind="$props"
        :background-color="backgroundColor"
        :error="validation && invalid && validated"
        :label="displayLabel"
        :rules="[]"
        type="tel"
        hide-details
        outlined
        @click:clear="iValue = null"
      />
    </v-input>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import FieldPhoneCountry from './FieldPhoneCountry'
import minMetadata from 'libphonenumber-js/metadata.min'
import { Metadata } from 'libphonenumber-js/core'
import {
  AsYouType,
  parsePhoneNumberFromString,
  getCountryCallingCode
} from 'libphonenumber-js'

const metadata = new Metadata(minMetadata)

export default {
  name: 'FieldPhone',
  components: {
    FieldPhoneCountry,
    ValidationProvider
  },
  props: {
    appendIcon: {
      type: String,
      default: null
    },
    appendOuterIcon: {
      type: String,
      default: null
    },
    allowedCountryCodes: {
      type: Array,
      default: null
    },
    backgroundColor: {
      type: String,
      default: 'white'
    },
    defaultCountryCode: {
      type: String,
      default: () => 'FR'
    },
    dense: {
      type: Boolean,
      default: true
    },
    disabled: Boolean,
    label: {
      type: String,
      default: null
    },
    mode: {
      type: String,
      default: 'national'
    },
    rules: {
      type: [String, Object],
      default: () => ({})
    },
    validation: {
      type: Boolean,
      default: false
    },
    vid: {
      type: String,
      default: null
    },
    value: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      countryCode: this.defaultCountryCode
    }
  },
  computed: {
    componentRules () {
      return (typeof this._rules === 'string')
        ? this.rulesBuilder(this._rules)
        : this._rules
    },
    parentRules () {
      return (typeof this.rules === 'string')
        ? this.rulesBuilder(this.rules)
        : this.rules
    },
    iRules () {
      return this.validation ? {
        ...this.componentRules,
        ...this.parentRules
      } : {}
    },
    isRequired () {
      return this.iRules?.required ?? false
    },
    displayLabel () {
      return `${this.label}${this.isRequired ? '*' : ''}`
    },
    countryCodeNumber () {
      return getCountryCallingCode(this.countryCode)
    },
    iValue: {
      get () {
        let numberToDisplay = ''
        if (this.value && this.value.number && this.value.number !== '') {
          numberToDisplay = parsePhoneNumberFromString(this.value?.number, this.countryCode)
          // if number is not valid returns it instead of clearing
          if (numberToDisplay == null) { return this.value.number }
        }
        if (!numberToDisplay) {
          return ''
        }
        const cc = this.countryCode ?? this.defaultCountryCode
        return new AsYouType(cc).input(
          (numberToDisplay == null) || numberToDisplay === ''
            ? '+' + getCountryCallingCode(cc)
            : numberToDisplay.number)
      },
      set (val) {
        const ccc = this.countryCode ? `+${getCountryCallingCode(this.countryCode)}` : null
        const valtoParse = (typeof val === 'object') ? val?.number ?? null : val
        if (this.$refs.provider && this.value && this.value.country !== ccc) {
          this.$refs.provider.validate(valtoParse)
        }
        if (!val) {
          this.$emit('input', { number: '', country: this.countryCode })
        } else if (val.number !== '') {
          const phoneNumber = parsePhoneNumberFromString(valtoParse, this.countryCode)
          this.$emit('input', {
            number: phoneNumber ? phoneNumber.formatInternational() : valtoParse,
            country: this.countryCode
          })
        }
      }
    },
    _rules () {
      return this.$attrs.field?.rules?.required || this.$refs?.provider?.flags?.changed
        ? { phone_international: { lang: this.countryCode, codeNumber: this.countryCodeNumber } }
        : {}
    }
  },
  watch: {
    defaultCountryCode (val) {
      this.countryCode = val
    },
    value: {
      immediate: true,
      handler (val) {
        this.countryCode = val?.country ?? this.defaultCountryCode
      }
    }
  },
  created () {
    this.setMetaDataCountry()
  },
  methods: {
    changeCountry () {
      this.setMetaDataCountry()
      this.iValue = null
    },
    rulesBuilder (stringRules) {
      let rules = {}
      const arrayRules = stringRules?.split('|') ?? []
      arrayRules.filter(f => f !== '').forEach(f => {
        rules = {
          ...rules,
          ...this.ruleBuilder(f)
        }
      })
      return rules
    },
    ruleBuilder (stringRule) {
      const keyVal = stringRule.split(':')
      return { [keyVal[0]]: (keyVal.length === 2) ? keyVal[1] : true }
    },
    setMetaDataCountry () {
      if (this.countryCode) {
        metadata.country(this.countryCode)
      }
    }
  }
}
</script>
