<template>
  <v-dialog
    v-model="iValue"
    :width="width"
    @click:outside="hideDialog"
  >
    <v-card>
      <dialog-header
        :title="$t(dialogTitle)"
        @close="hideDialog"
      />
      <v-card-text>
        <v-row>
          <v-col
            v-if="isEntity"
            cols="12"
          >
            <p>
              {{ $t(id ? 'task.edit-for' : 'task.create-for') }}
              <span
                v-for="(taskEntity, idx) in taskEntities"
                :key="taskEntity.entityId"
              >
                <strong>
                  {{ $t('task.entity-type.' + taskEntity.entityType.toLowerCase()) }}
                  {{ taskEntity.entityName }}
                </strong>
                <template v-if="idx < taskEntities.length - 1">
                  {{ $t('common.and') }}
                </template>
              </span>
            </p>
          </v-col>
        </v-row>
        <form-validation
          ref="form"
          @validated="submitTask"
        >
          <v-col cols="12">
            <p>{{ $t('task.dialog.required-fields') }}</p>
          </v-col>
          <v-col
            v-if="showPerformerField"
            cols="12"
          >
            <component
              :is="performerComponentType"
              v-model="performerUser"
              :disabled="disabled"
              :field="{key: 'performerUserProfileId'}"
              :label="$t('task.dialog.performerUserProfileId')"
              :team-id="teamIfNoAssignAll"
              rules="required"
              return-object
              with-profile
              validation
              @input="setPerformerUserProfileId"
            />
          </v-col>
          <v-col cols="12">
            <field-textarea
              v-model="title"
              rules="required|max:300"
              :label="$t('task.dialog.title')"
              :disabled="disabled"
              validation
              counter
            />
          </v-col>
          <v-col cols="7">
            <field-toggle
              v-model="refTaskPriorityId"
              :items="taskPriorities"
              :disabled="disabled"
              :label="$t('task.dialog.refTaskPriorityId')"
              rules="required"
              validation
            >
              <template #item="{ item }">
                <v-btn
                  :key="item.slug"
                  :value="item.id"
                >
                  <v-icon
                    :color="getColor(item.slug)"
                    class="mr-2"
                    x-small
                  >
                    fa-circle
                  </v-icon>
                  {{ $t(`priority.${item.slug}`) }}
                </v-btn>
              </template>
            </field-toggle>
          </v-col>
          <v-col cols="12">
            <field-autocomplete
              v-model="typeId"
              :disabled="disabled"
              :field="{key: 'typeId'}"
              :items="taskTypes"
              :item-text="item => $t(item.value)"
              :label="$t('task.dialog.typeId')"
              :return-object="false"
              item-value="id"
              rules="required"
              validation
            />
          </v-col>
          <v-col cols="12">
            <field-datetime
              :disabled="disabled"
              :value="dueDate"
              :label="$t('task.dialog.due-date')"
              :min="initialDueDate"
              :rules="{
                after: initialDueDate
              }"
              close-on-content-click
              validation
              @change="dueDate = $event"
            />
          </v-col>
          <v-col
            v-if="task"
            cols="12"
          >
            <document-manager
              :entity="{entityType, entityId: task.id}"
              :swiper-options="swiperOptions"
              mode="swiper"
              can-write
            />
          </v-col>
          <template #actions>
            <v-btn
              class="ml-3"
              color="primary"
              type="submit"
            >
              {{ $t(btnLabel) }}
            </v-btn>
            <v-btn
              :disabled="disabled"
              class="ml-5"
              @click.prevent="iValue = false"
            >
              {{ $t('common.cancel') }}
            </v-btn>
          </template>
        </form-validation>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import EntityTypes from '@/constants/EntityTypes'
import ColorPriorities from '@/constants/ColorPriorities'
import DialogHeader from '@/components/Dialogs/DialogHeader'
import DocumentManager from '@/components/DocumentManager'
import FormValidation from '@/components/FormValidation'
import FieldAutocomplete from '@/components/Fields/FieldAutocomplete'
import FieldTextarea from '@/components/Fields/FieldTextarea'
import FieldDatetime from '@/components/Fields/FieldDatetime'
import { useMessages } from '@/compositions/messages'
import profiles from '@/services/profiles'
import TaskAdapter from '@/adapters/Task'
import { useTaskEditorStore } from '@/stores/taskEditor'
import { useUniverseStore } from '@/stores/universe'
import { useAuthStore } from '@/stores/auth'
import { useUserStore } from '@/stores/user'
import { useTaskStore } from '@/stores/task'
import { useRefStore } from '@/stores/ref'
import { mapActions, mapState } from 'pinia'

export default {
  name: 'DialogTask',
  components: {
    DocumentManager,
    DialogHeader,
    FormValidation,
    FieldAutocomplete,
    FieldTextarea,
    FieldDatetime
  },
  props: {
    width: {
      type: Number,
      default: 800
    }
  },
  setup () {
    const { errorMessage, formErrorMessages, successMessage } = useMessages()
    return { errorMessage, formErrorMessages, successMessage }
  },
  data () {
    return {
      assets: [],
      id: null,
      dueDate: null,
      entities: null,
      performerUser: null,
      performerUserProfileId: null,
      refTaskPriorityId: null,
      title: null,
      typeId: null,
      initialDueDate: null
    }
  },
  computed: {
    btnLabel () {
      return this.task?.id ? 'common.modify' : 'common.save'
    },
    disabled () {
      return this.savingTask ||
        (this.task?.id && !this.isOwner && !this.rights.task.update)
    },
    entityType () {
      return EntityTypes.Task
    },
    assignAll () {
      return this.rights?.task?.assignAll ?? false
    },
    assignTeam () {
      return this.rights?.task?.assignTeam ?? false
    },
    canAssign () {
      return this.assignAll || this.assignTeam
    },
    defaultTaskTypeValue () {
      return this.settings?.app?.defaultTaskTypeValue
    },
    defaultTaskTypeId () {
      return this.defaultTaskTypeValue ? this.taskTypes?.find(f =>
        f.value === this.defaultTaskTypeValue)?.id : null
    },
    dialogTitle () {
      return this.task?.id ? 'task.dialog.title.edit' : 'task.dialog.title.create'
    },
    isOwner () {
      return this.user?.id === this.task?.ownerUserProfile?.user?.id
    },
    isEntity () {
      return this.taskEntities?.length ?? false
    },
    isApplicant () {
      return this.profile ? profiles.isApplicant(this.profile.type) : false
    },
    iValue: {
      get () {
        return this.dialogTask
      },
      set (val) {
        !val ? this.closeDialog() : this.showDialog()
      }
    },
    performerComponentType () {
      if (profiles.userIsFacilitator()) {
        return 'field-entity-user-profile-facilitator'
      } else if (profiles.userIsRetailer()) {
        return 'field-entity-user-profile-retailer'
      }
      return null
    },
    showPerformerField () {
      return !this.isApplicant && this.canAssign
    },
    status () {
      return this.getRefs?.refStatuses ?? []
    },
    swiperOptions () {
      return {
        breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 25
          },
          460: {
            slidesPerView: 2,
            spaceBetween: 25
          }
        },
        breakpointsBase: 'container'
      }
    },
    taskPriorities () {
      return this.getRefs?.refTaskPriorities ?? []
    },
    taskTypes () {
      return this.getDictionary('task-type') ?? []
    },
    teamIfNoAssignAll () {
      if (profiles.userIsFacilitator() && !this.rights.task.assignAll) {
        return this.user.teamId
      }
      return null
    },
    ...mapState(useUserStore, {
      profile: 'getProfile',
      rights: 'getRights'
    }),
    ...mapState(useTaskEditorStore, {
      dialogTask: 'isEditorVisible',
      taskEntities: 'getEntities',
      savingTask: 'isSaving',
      task: 'getTask'
    }),
    ...mapState(useAuthStore, {
      user: 'getUser'
    }),
    ...mapState(useRefStore, {
      getRefs: 'getRefs'
    }),
    ...mapState(useUniverseStore, {
      getDictionary: 'getDictionary',
      settings: 'getSettings'
    })
  },
  watch: {
    taskEntities: {
      immediate: true,
      deep: true,
      handler (val) {
        this.entities = val ?? null
      }
    },
    dialogTask: {
      immediate: true,
      handler (val) {
        if (val) {
          this.assets = this.task?.assets ?? []
          this.id = this.task?.id ?? null
          this.dueDate = this.task?.dueDate
            ? this.$utils.moment(this.task.dueDate).format('YYYY-MM-DD')
            : null
          this.initialDueDate = this.$utils.moment().format('YYYY-MM-DD')
          this.title = this.task?.title ?? null
          this.typeId = this.task?.id ? this.task.typeId ?? null : this.defaultTaskTypeId
          this.refTaskPriorityId = this.task?.refTaskPriorityId ??
            this.taskPriorities?.find(f => f.slug === 'medium')?.id ?? null
          if (this.task) {
            this.performerUser = this.task.performerUserProfile?.user ?? null
            this.performerUserProfileId = this.task.performerUserProfileId ?? null
          } else {
            this.performerUser = this.user ?? null
            this.performerUserProfileId = this.profile?.userProfileId ?? null
          }
        } else {
          this.typeId = null
          this.assets = []
          this.dueDate = null
          this.initialDueDate = null
          this.title = null
          this.refTaskPriorityId = null
          this.performerUser = null
          this.performerUserProfileId = null
        }
      }
    }
  },
  methods: {
    ...mapActions(useTaskStore, {
      showDialog: 'showDialog'
    }),
    ...mapActions(useTaskEditorStore, {
      hideDialog: 'hideDialog',
      saveTask: 'saveTask'
    }),
    closeDialog () {
      this.$refs.form.$refs.observer.reset()
      this.$refs.form.$refs.form.reset()
      this.hideDialog()
    },
    getColor (slug) {
      return ColorPriorities[slug]?.color ?? null
    },
    setPerformerUserProfileId (user) {
      this.performerUserProfileId = user?.profile?.userProfileId ?? null
    },
    async submitTask () {
      try {
        await this.saveTask(TaskAdapter.toEntityData(this))
        this.closeDialog()
        this.successMessage('common.save.success')
        this.$emit('saved')
      } catch (error) {
        if (error.response?.data?.errors) {
          this.formErrorMessages(error.response.data.errors)
        } else {
          this.errorMessage('common.save.error')
        }
      }
    }
  }
}
</script>
