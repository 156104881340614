<template>
  <field-string
    v-model="iValue"
    v-bind="$attrs"
    :rules="iRules"
    type="number"
  />
</template>

<script>
import { useValidation } from '@/compositions/validation'
import FieldString from '../FieldString'

export default {
  name: 'FieldFloat',
  components: {
    FieldString
  },
  props: {
    precision: {
      type: Number,
      default: 2
    },
    rules: {
      type: [String, Object],
      default: () => ({})
    },
    value: {
      type: Number,
      default: null
    }
  },
  setup (props) {
    const { iRules } = useValidation(props, 'numeric')

    return { iRules }
  },
  computed: {
    iValue: {
      get () {
        return this.value?.toString() ?? null
      },
      set (val) {
        const value = val
          ? Number(parseFloat(val)?.toFixed(this.precision)) ?? null
          : null
        this.$emit('input', value)
      }
    }
  }
}
</script>
