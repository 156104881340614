import moment from 'moment'
import i18n from '@/plugins/vue-i18n'

export default {
  params: ['target'],
  message: (_fieldName, placeholders) => {
    return i18n.t('validation.before', {
      placeholder_1: placeholders._target_
    })
  },
  validate (value, { target }) {
    if (!target || !value) {
      return true
    }
    let val = moment(value, 'L')
    if (!val.isValid()) {
      val = moment(value)
    }
    let ma = moment(target, 'L')
    if (!ma.isValid()) {
      ma = moment(target)
    }
    return val.isBefore(ma) || val.isSame(ma)
  }
}
