<template>
  <field-autocomplete
    ref="input"
    v-bind="$attrs"
    v-model="iValue"
    :field="{ key: 'user-retailer' }"
    :filter="customFilter"
    :remote="remote"
    :return-object="returnObject"
    item-text="email"
    item-value="id"
    cache-items
    hide-selected
    custom-selection
    custom-item
    v-on="$listeners"
  >
    <template #selection="data">
      <v-chip
        close
        style="margin-top: 0"
        @click:close="removeItem(data.item)"
      >
        <avatar
          :size="24"
          :initials="initial(data.item)"
          :url="data.item.avatar && data.item.avatar.urlCached"
        />
        <span class="text--truncate ml-1">{{ getName(data.item) }}</span>
      </v-chip>
    </template>
    <template #item="data">
      <avatar
        :size="30"
        :initials="initial(data.item)"
        :url="data.item.avatar && data.item.avatar.urlCached"
        class="mr-2"
      />
      <v-list-item-content>
        <v-list-item-title v-html="getName(data.item)" />
        <v-list-item-title v-html="getCompanyName(data.item)" />
        <v-list-item-subtitle v-html="data.item.email" />
      </v-list-item-content>
    </template>
  </field-autocomplete>
</template>

<script>
import Autocomplete from '@/services/Autocomplete'
import FieldAutocomplete from '../FieldAutocomplete'
import initials from '@/services/initials'

export default {
  name: 'FieldEntityUserProfileRetailer',
  components: {
    FieldAutocomplete
  },
  props: {
    companyId: {
      type: Number,
      default: undefined
    },
    excludeIds: {
      type: Array,
      default: () => ([])
    },
    productId: {
      type: Number,
      default: undefined
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Array, Object, Number],
      default: null
    },
    withProfile: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('change', val)
      }
    },
    remote () {
      const userProfileId = this.returnObject
        ? this.iValue?.profile?.userProfileId
        : this.iValue
      return {
        url: '/users/retailers',
        urlFind: '/users/retailers',
        urlFindKey: 'id',
        urlFindParams: {
          id: userProfileId ?? null
        },
        method: 'GET',
        params: {
          searchParam: 'search',
          companyId: this.companyId,
          productId: this.productId,
          excludeIds: this.excludeIds,
          withProfile: this.needMoreInformation || this.withProfile ? 1 : 0,
          withCompany: this.needMoreInformation ? 1 : 0,
          withProduct: this.needMoreInformation ? 1 : 0
        }
      }
    },
    needMoreInformation () {
      return this.companyId || this.productId
    }
  },
  watch: {
    companyId () {
      this.$refs.input.asyncItems = []
      this.$refs.input.$refs['user-retailer'].cachedItems = []
      this.$nextTick(() => {
        this.$refs.input.fetch()
      })
    }
  },
  created () {
    this.$off('change')
  },
  methods: {
    customFilter: Autocomplete.userEntitycustomFilter,
    getCompanyName (item) {
      return item.profile?.company?.name ?? null
    },
    getName (item) {
      if (!item.firstName && !item.lastName) {
        return this.$t('common.undefined')
      }
      return `${item.firstName || ''} ${item.lastName || ''}`
    },
    removeItem (item) {
      if (Array.isArray(this.value)) {
        this.$emit('input', this.iValue.filter(f => f !== item.id))
      } else {
        this.$emit('input', null)
      }
    },
    initial (item) {
      return initials(item.firstName, item.lastName, item.displayName, item.email)
    }
  }
}
</script>
