<script setup>
import { useRefStore } from '@/stores/ref'
import { computed } from 'vue'

const props = defineProps({
  userId: {
    type: Number,
    default: null
  }
})

const { getRefs } = useRefStore()

const idStatusInProgress = computed(() => getRefs.refStatuses?.find(f =>
  f.slug === 'in-progress')?.id ?? null)
const linkMyTasks = computed(() => props.userId ? `/tasks?refStatusId=${idStatusInProgress.value}&performerUserId=${props.userId}` : null)

</script>

<template>
  <div class="d-flex justify-end mt-5">
    <v-icon
      size="16"
      color="gray"
      class="mr-1"
    >
      fas fa-chevron-circle-right
    </v-icon>
    <a :href="linkMyTasks">
      {{ $t('task.manager.see.mine') }}
    </a>
  </div>
</template>
