<template>
  <validation-observer>
    <v-label
      v-if="fixedLabel && displayLabel"
      :dark="fixedLabelDark"
    >
      {{ displayLabel }}
    </v-label>
    <div class="date-range">
      <div class="date-range__input mr-3">
        <field-datetime
          v-bind="$attrs"
          :fixed-label="fixedLabel"
          :fixed-label-dark="fixedLabelDark"
          :value="gte"
          :label="(!fixedLabel && $t('common.date-range.from')) || ''"
          :rules="iRules"
          :validation="validation"
          :placeholder="(fixedLabel && $t('common.date-range.from')) || ''"
          vid="min"
          @change="gte = $event"
        />
      </div>
      <div class="date-range__input">
        <field-datetime
          v-bind="$attrs"
          :fixed-label="fixedLabel"
          :fixed-label-dark="fixedLabelDark"
          :value="lte"
          :picker-date.sync="pickerDateTo"
          :label="(!fixedLabel && $t('common.date-range.to')) || ''"
          :placeholder="(fixedLabel && $t('common.date-range.to')) || ''"
          :min="gte"
          :rules="iRules"
          :validation="validation"
          vid="max"
          @change="lte = $event"
        />
      </div>
    </div>
  </validation-observer>
</template>

<script>
import { useValidation } from '@/compositions/validation'
import { ValidationObserver } from 'vee-validate'
import FieldDatetime from '../FieldDatetime'

export default {
  name: 'FieldDateRange',
  components: {
    FieldDatetime,
    ValidationObserver
  },
  props: {
    gteKey: {
      type: String,
      default: 'gte'
    },
    lteKey: {
      type: String,
      default: 'lte'
    },
    fixedLabel: Boolean,
    fixedLabelDark: Boolean,
    label: {
      type: String,
      default: null
    },
    rules: {
      type: [String, Object],
      default: () => ({})
    },
    validation: {
      type: Boolean,
      default: false
    },
    vid: {
      type: String,
      default: null
    },
    value: {
      type: [Object, String],
      default: null
    }
  },
  setup (props) {
    const { iRules, displayLabel } = useValidation(props, { before: '@max' })

    return { iRules, displayLabel }
  },
  data () {
    return {
      menu: false,
      pickerDateTo: null
    }
  },
  computed: {
    gte: {
      get () {
        return this.value ? this.value[this.gteKey] : null
      },
      set (value) {
        if (value) {
          this.pickerDateTo = this.$utils.moment(value).format('YYYY-MM')
        } else {
          this.pickerDateTo = this.$utils.moment().format('YYYY-MM')
        }
        this.$emit('input', {
          [this.gteKey]: value,
          [this.lteKey]: this.value ? this.value[this.lteKey] : null
        })
      }
    },
    lte: {
      get () {
        return this.value ? this.value[this.lteKey] : null
      },
      set (value) {
        this.$emit('input', {
          [this.gteKey]: this.value ? this.value[this.gteKey] : null,
          [this.lteKey]: value
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.date-range) {
  display: flex;
  font-size: 1rem;
  align-content: stretch;
  .date-range__input {
    width: 100%;
    .v-icon {
      font-size: 1rem;
    }
  }
}
</style>
