<template>
  <validation-provider
    v-slot="{ errors }"
    :name="$attrs.name"
    :rules="iRules"
    :vid="vid"
  >
    <label
      v-if="label"
      :class="getErrorLabel(errors)"
    >
      {{ displayLabel }}
    </label>
    <v-input
      :disabled="disabled"
      :error-messages="errors"
      :error="validation && !!errors.length"
      :value="value"
      class="input-toggle"
      v-on="$listeners"
    >
      <v-btn-toggle
        v-model="iValue"
        color="primary"
        :class="['btn-toggle', btnToggleClass]"
        group
      >
        <slot
          v-for="(item, idx) in items"
          name="item"
          :item="item"
          :css-class="getErrorClass(errors)"
        >
          <v-btn
            :key="idx"
            :value="getItemValue(item)"
          >
            {{ getItemText(item) }}
          </v-btn>
        </slot>
      </v-btn-toggle>
    </v-input>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { useValidation } from '@/compositions/validation'

export default {
  name: 'FieldToggle',
  components: {
    ValidationProvider
  },
  props: {
    btnToggleClass: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => ([])
    },
    itemText: {
      type: [String, Function],
      default: null
    },
    itemValue: {
      type: [String, Function],
      default: null
    },
    label: {
      type: String,
      default: null
    },
    rules: {
      type: [String, Object],
      default: () => ({})
    },
    validation: {
      type: Boolean,
      default: false
    },
    vid: {
      type: String,
      default: null
    },
    value: {
      type: Number,
      default: null
    }
  },
  setup (props) {
    const { iRules, displayLabel } = useValidation(props)

    return { iRules, displayLabel }
  },
  computed: {
    iValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    getErrorLabel (errors) {
      if (errors.length) {
        return 'v-label--error'
      }
      return 'v-label'
    },
    getErrorClass (errors) {
      if (errors.length) {
        return ['v-btn--error']
      }
      return []
    },
    getItemValue (item) {
      if (typeof this.itemValue === 'function') {
        return this.itemValue()
      }
      return item[this.itemValue || 'id']
    },
    getItemText (item) {
      if (typeof this.itemText === 'function') {
        return this.itemText()
      }
      return item[this.itemText || 'id']
    }
  }
}
</script>

<style lang="scss" scoped>
.v-label {
  &--error {
    color: var(--v-error-base);
  }
}

:deep(.input-toggle) {
  .v-messages__message {
    padding-left: 8px !important;
  }

  .btn-toggle {
    width: 100%;

    button {
      flex-grow: 1 !important;
    }
  }
}
</style>
