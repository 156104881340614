export function useFieldStore (props) {
  let store = null
  let iStoreKey = null
  if (props.storeKey) {
    const storeName = props.storeKey.split('.')[0]
    const fStore = require(`@/stores/${storeName}`)
    iStoreKey = props.storeKey.replace(`${storeName}.`, '')
    store = fStore[`use${storeName[0].toUpperCase()}${storeName.substring(1)}Store`]()
  }
  return { store, iStoreKey }
}
