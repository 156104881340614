<template>
  <field-list
    v-bind="$attrs"
    :value="iValue"
    :items="items"
    :item-text="itemText"
    :item-value="itemValue"
    @change="iValue = $event"
  />
</template>

<script>
import getOptionsFromListKey from '@/services/fields/getOptionsFromListKey'

export default {
  name: 'FieldStoreList',
  props: {
    itemText: {
      type: [String, Function],
      default: 'text'
    },
    itemValue: {
      type: String,
      default: 'value'
    },
    listKey: {
      type: String,
      required: true
    },
    value: {
      type: [String, Object, Number, Array],
      default: null
    },
    whitelist: {
      type: Array,
      default: null
    }
  },
  computed: {
    items () {
      return getOptionsFromListKey(this.listKey, this.whitelist)
    },
    iValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('change', val)
      }
    },
    storeKey () {
      return this.listKey.toLowerCase()?.replace(/::/gi, '-') ?? null
    }
  }
}
</script>
