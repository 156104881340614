import axios from '@/plugins/axios'
import jsonToFormData from '@/services/jsonToFormData'

export default {
  create (task) {
    const formData = jsonToFormData(task)
    delete formData.id
    return axios.post('/tasks', formData.data, formData.config)
  },
  delete (taskId) {
    return axios.delete(`/tasks/${taskId}`)
  },
  fetch (params) {
    const urlParams = new URLSearchParams(params).toString()
    return axios.get('/tasks?' + urlParams)
  },
  get (taskId, params) {
    const urlParams = new URLSearchParams(params).toString()
    return axios.get(`/tasks/${taskId}?${urlParams}`)
  },
  owners () {
    return axios.get('/tasks/owners')
  },
  performers () {
    return axios.get('/tasks/performers')
  },
  priorities (params) {
    const urlParams = new URLSearchParams(params).toString()
    return axios.get('/tasks/priorities?' + urlParams)
  },
  update (task) {
    task._method = 'patch'
    const formData = jsonToFormData(task)
    return axios.post(`/tasks/${task.id}`, formData.data, formData.config)
  },
  getCountPriorities () {
    return axios.get('/tasks/priorities/count')
  }
}
