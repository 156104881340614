<template>
  <div>
    <span class="need-type__container d-flex flex-wrap">
      <v-btn
        v-for="type in availableNeedTypes"
        :key="type.value"
        :elevation="0"
        :class="{
          'primary': isTypeChecked(type)
        }"
        class="need-type d-flex flex-column mr-5"
        :disabled="isTypeDisabled(type)"
        @click="onNeedTypeClick(type)"
      >
        <v-tooltip
          v-if="$te(`universe-dictionary.${type.value}-description`)"
          bottom
        >
          <template #activator="{ on, attrs }">
            <v-icon
              class="need-type__tooltip-icon"
              v-bind="attrs"
              small
              v-on="on"
            >
              far fa-question-circle
            </v-icon>
          </template>
          <span>
            {{ $t(`universe-dictionary.${type.value}-description`) }}
          </span>
        </v-tooltip>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              class="need-type__label"
            >
              <img
                class="need-type__icon"
                :src="slug(type.id)"
                :alt="`image-${slug(type.id)}`"
              >
              <div>{{ $t(`universe-dictionary.${type.value}`) }}</div>
            </v-col>
          </v-row>
        </v-container>
      </v-btn>
    </span>
  </div>
</template>

<script>
import { useUniverseStore } from '@/stores/universe'
import { useAssetsStore } from '@/stores/assets'
import { mapActions, mapState } from 'pinia'
import _ from 'lodash'

export default {
  name: 'FieldNeedType',
  props: {
    value: {
      type: Array,
      default: null
    },
    disabledNeedTypesIds: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState(useAssetsStore, {
      getDamAsset: 'getDamAsset'
    }),
    ...mapState(useUniverseStore, {
      dictionary: 'getDictionary'
    }),
    iValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    availableNeedTypes () {
      return this.dictionary('need-type')
    }
  },
  async created () {
    await _.each(this.availableNeedTypes, async nt => {
      const avatarDamAssetId = nt.extra?.avatarDamAssetId
      if (nt.extra?.avatarDamAssetId) {
        await this.fetchProjectImage({
          avatarDamAssetId
        })
      }
    })
  },
  methods: {
    slug (typeId) {
      const avatarDamAssetId = this.availableNeedTypes.find(t => t.id === typeId)?.extra?.avatarDamAssetId
      return this.getDamAsset(avatarDamAssetId)?.urlCached || ''
    },
    isTypeDisabled (need) {
      return this.disabledNeedTypesIds.indexOf(need.id) !== -1
    },
    isTypeChecked (type) {
      return this.iValue.indexOf(type.id) > -1
    },
    onNeedTypeClick (type) {
      if (this.iValue.indexOf(type.id) > -1) {
        this.iValue = _.filter(this.iValue, v => {
          return v !== type.id
        })
      } else {
        this.iValue = _.concat(this.iValue, [type.id])
      }
    },
    ...mapActions(useAssetsStore, {
      fetchProjectImage: 'fetchProjectImage'
    })
  }
}
</script>

<style lang="scss" scoped>
.need-type {
  position: relative !important;
  border-style: solid;
  border-width: 1px;
  transition: border-color 0.3s, background-color 0.3s;
  &__icon,
  &__label,
  &__tooltip-icon {
    transition: color 0.3s, opacity 0.3s;
  }
  &__icon {
    height: 45px;
  }
  &__label {
    white-space: normal;
  }
  &__tooltip-icon {
    position: absolute;
    top: 12px;
    right: 0;
  }
  &__container {
    .v-btn {
      text-transform: initial;
      width: 140px;
      height: 140px !important;
      border-radius: 15px !important;
      margin: 12px 0;
      flex-grow: 0 !important;

      &:hover,
      &.v-btn--active {
        &::before {
          opacity: 0;
        }
      }
      &.v-btn--disabled {
        .need-type {
          &__icon {
            opacity: 0.4;
          }
        }
        &::before {
          opacity: 0;
        }
      }
    }
  }
}
</style>
