<template>
  <field-autocomplete
    v-bind="$attrs"
    v-model="iValue"
    :field="{}"
    :item-text="itemText"
    :item-value="itemValue"
    :remote="remote"
    :return-object="returnObject"
    hide-selected
    cache-items
    v-on="$listeners"
  />
</template>

<script>
import FieldAutocomplete from '../FieldAutocomplete'

export default {
  name: 'FieldEntityProduct',
  components: {
    FieldAutocomplete
  },
  props: {
    companyId: {
      type: Number,
      default: null
    },
    itemText: {
      type: String,
      default: 'title'
    },
    itemValue: {
      type: String,
      default: 'id'
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Array, Number],
      default: null
    }
  },
  computed: {
    iValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('change', val)
      }
    },
    remote () {
      return {
        url: '/products',
        urlFind: '/products/:id',
        urlFindKey: 'id',
        method: 'GET',
        params: {
          companyId: this.companyId,
          searchParam: 'label'
        }
      }
    }
  },
  created () {
    this.$off('change')
  }
}
</script>
