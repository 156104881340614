<script setup>
import { computed } from 'vue'

const emits = defineEmits(['input', 'cancel', 'confirm'])
const props = defineProps({
  loading: Boolean,
  value: Boolean,
  title: {
    type: String,
    default: null
  },
  maxWidth: {
    type: Number,
    default: 390
  },
  message: {
    type: String,
    default: null
  },
  confirmLabel: {
    type: String,
    default: null
  },
  cancelLabel: {
    type: String,
    default: null
  }
})

const iValue = computed({
  get () {
    return props.value
  },
  set (val) {
    emits('input', val)
  }
})
</script>

<template>
  <v-dialog
    v-model="iValue"
    :max-width="maxWidth"
    :persistent="loading"
    data-test="dialog-confirm"
    v-on="$listeners"
  >
    <v-card :loading="loading">
      <v-card-title data-test="title">
        {{ title }}
      </v-card-title>
      <v-card-text data-test="text">
        <slot>
          {{ message }}
        </slot>
      </v-card-text>
      <v-card-actions data-test="actions">
        <slot name="actions">
          <v-spacer />
          <v-btn
            v-if="cancelLabel"
            :disabled="loading"
            data-test="cancel"
            @click.stop="$emit('cancel')"
          >
            {{ cancelLabel }}
          </v-btn>
          <v-btn
            v-if="confirmLabel"
            :disabled="loading"
            color="primary"
            data-test="confirm"
            @click.stop="$emit('confirm')"
          >
            {{ confirmLabel }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
