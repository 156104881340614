import axios from '@/plugins/axios'

export default {
  edit (id, content) {
    return axios
      .patch(`notes/${id}`, content)
  },
  get (entity, id) {
    return axios
      .get(`/${entity}/${id}/note`)
  }
}
