import EntityTypes from '@/constants/EntityTypes'
import { useUserStore } from '@/stores/user'

export default {
  userIsFacilitator () {
    const userStore = useUserStore()
    return userStore.getProfile.type === EntityTypes.Facilitator.description.toLowerCase()
  },
  userIsApplicant () {
    const userStore = useUserStore()
    return userStore.getProfile.type === EntityTypes.Applicant.description.toLowerCase()
  },
  userIsRetailer () {
    const userStore = useUserStore()
    return userStore.getProfile.type === EntityTypes.Retailer.description.toLowerCase()
  },
  isRetailer (type) {
    return type === EntityTypes.Retailer.description.toLowerCase()
  },
  isApplicant (type) {
    return type === EntityTypes.Applicant.description.toLowerCase()
  },
  isFacilitator (type) {
    return type === EntityTypes.Facilitator.description.toLowerCase()
  }
}
