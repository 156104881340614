import * as rules from 'vee-validate/dist/rules'
import { extend, setInteractionMode } from 'vee-validate'

import after from '@/validators/after'
import before from '@/validators/before'
import gte from '@/validators/greater_than_or_equal'
import lte from '@/validators/lower_than_or_equal'
import max from '@/validators/max'
import min from '@/validators/min'
import numeric from '@/validators/numeric'
import phoneInternational from '@/validators/phone_international'
import positive from '@/validators/positive'
import url from '@/validators/url'

setInteractionMode('eager')

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

extend('after', after)
extend('before', before)
extend('greater_than_or_equal', gte)
extend('lower_than_or_equal', lte)
extend('max', max)
extend('min', min)
extend('numeric', numeric)
extend('phone_international', phoneInternational)
extend('positive', positive)
extend('url', url)
