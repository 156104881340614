<script setup>
import { computed } from 'vue'

const emits = defineEmits(['input'])

const props = defineProps({
  height: {
    type: String,
    default: null
  },
  value: {
    type: Boolean,
    default: false
  },
  filter: {
    type: [Number, String, Array, Object],
    default: null
  }
})

const color = computed(() => {
  if (Array.isArray(props.filter)) {
    return props.filter.length ? 'primary' : null
  }
  return props.filter ? 'primary' : null
})
const iValue = computed({
  get () {
    return props.value
  },
  set (val) {
    emits('input', val)
  }
})
</script>

<template>
  <v-menu
    v-model="iValue"
    :close-on-content-click="false"
    :nudge-bottom="10"
    offset-y
    bottom
  >
    <template #activator="{ on, attrs }">
      <v-icon
        v-bind="attrs"
        :color="color"
        x-small
        class="mr-1"
        v-on="on"
      >
        fa fa-filter
      </v-icon>
    </template>
    <v-card
      :class="[$attrs.padding || 'pa-5']"
      :height="height"
    >
      <slot />
    </v-card>
  </v-menu>
</template>
