
<template>
  <component :is="layout">
    <router-view />
    <didomi
      v-if="didomiKey"
      :api-key="didomiKey"
      :config="config"
    />
  </component>
</template>

<script>
import getFirstMenuRouteName from '@/services/getFirstMenuRouteName'
import { useAuthStore } from '@/stores/auth'
import { useUserStore } from '@/stores/user'
import { useUniverseStore } from '@/stores/universe'
import { useTranslationStore } from './stores/translation'
import { useAppStore } from './stores/app'
import _ from 'lodash'

const defaultLayout = 'authenticated'

export default {
  name: 'App',
  data () {
    return {
      didomiKey: window.env.VUE_APP_DIDOMI_KEY
    }
  },
  metaInfo () {
    const universeStore = useUniverseStore()
    const faviconUrl = universeStore.getSettingByPath('theme.faviconUrl', null)
    const appName = this.$t('common.app-name') !== 'common.app-name' ? this.$t('common.app-name') : window.env.VUE_APP_NAME

    return {
      title: appName,
      link: [
        { rel: 'icon', href: faviconUrl, type: 'image/png' }
      ],
      meta: _.map(this.layer, l => {
        return {
          vmid: l.meta,
          name: l.meta,
          content: l.value
        }
      })
    }
  },
  computed: {
    layout () {
      return (this.$route.meta.layout || defaultLayout) + '-layout'
    },
    layer () {
      const appName = this.$t('common.app-name') !== 'common.app-name'
        ? this.$t('common.app-name')
        : window.env.VUE_APP_NAME

      return [{
        key: 'name',
        meta: 'ipd:siteName',
        value: appName
      }, {
        key: 'cmp',
        meta: 'ipd:cmp',
        value: 'Sébastien OLSZANSKI'
      }, {}]
    },
    config () {
      const isoCode = window.env.VUE_APP_I18N_LOCALE.split('-')
      const lang = isoCode.length ? isoCode[0] : 'fr'

      const app = {}
      _.each(this.layer, l => {
        app[l.key] = l.value
      })

      return {
        app,
        languages: {
          enabled: [lang],
          default: lang
        }
      }
    }
  },
  async mounted () {
    const authStore = useAuthStore()
    const appStore = useAppStore()
    if (!appStore.appIsReady) {
      const response = await authStore.login({ instance: this })
      const translationStore = useTranslationStore()

      await translationStore.loadTranslations()

      if (response.data.data.universes) {
        this.$router.push({
          name: 'user-set-universe',
          params: { path: this.$route?.params?.path }
        })
      } else if (authStore.user.profiles) {
        this.$router.push({
          name: 'user-set-profile',
          params: { path: this.$route?.params?.path }
        })
      } else {
        const authStore = useAuthStore()
        const userStore = useUserStore()

        const profile = authStore.user.profile
        const menuItems = response.data.data.refMenu
        const routeName = getFirstMenuRouteName(menuItems?.main ?? null)
        await userStore.setData({ profile, menuItems })
        Object.keys(translationStore.messages).forEach(f => {
          this.$i18n.setLocaleMessage(f, translationStore.messages[f])
        })
        this.$router.push({ name: routeName })
      }
    }
  }
}
</script>
