<script setup>
import { computed } from 'vue'

const props = defineProps({
  link: {
    type: Object,
    default: null
  },
  label: {
    type: String,
    required: true
  },
  value: {
    type: [String, Number],
    default: ''
  },
  prepend: {
    type: Boolean,
    default: true
  },
  separator: {
    type: Boolean,
    default: false
  }
})

const routerLinkProps = computed(() => {
  return props.link ? {
    hasRight: props.link.right,
    name: props.link.name,
    filters: props.link.filters,
    params: props.link.params,
    hash: props.link.hash
  } : {}
})
</script>

<template>
  <div class="mb-2">
    <component
      :is="link ? 'custom-router-link' : 'div'"
      v-bind="routerLinkProps"
    >
      <template v-if="prepend">
        <span
          class="primary--text text-h6"
          v-html="value"
        />
        <span v-html="separator ? ' ' + $t('common.separator.colon') : ''" />
        <span v-html="' ' + label" />
      </template>
      <template v-else>
        <span v-html="label" />
        <span v-html="separator ? ' ' + $t('common.separator.colon') : ''" />
        <span
          class="primary--text text-h6"
          v-html="' ' + value"
        />
      </template>
    </component>
  </div>
</template>

<style lang="scss" scoped>
a {
  text-decoration-line: none !important;
}
</style>
