<script setup>
const emits = defineEmits(['close'])

defineProps({
  title: {
    type: String,
    default: null
  },
  closePosition: {
    type: String,
    default: 'left'
  },
  showClose: {
    type: Boolean,
    default: true
  }
})

function close () {
  emits('close')
}
</script>

<template>
  <v-card-title
    style="width: 100%"
    class="d-flex align-center justify-space-between"
  >
    <div class="d-flex align-center">
      <v-btn
        v-if="showClose && closePosition === 'left'"
        color="black"
        icon
        @click="close"
      >
        <v-icon>fa-times</v-icon>
      </v-btn>
      <slot name="prepend" />
      <h2
        v-if="title"
        class="text-h2 justify-self-start"
      >
        {{ title }}
      </h2>
      <slot name="avatar" />
    </div>
    <div class="justify-self-end">
      <slot name="actions" />
    </div>
    <v-btn
      v-if="showClose && closePosition === 'right'"
      class="justify-self-end"
      icon
      @click="close"
    >
      <v-icon>fa fa-times</v-icon>
    </v-btn>
  </v-card-title>
</template>
