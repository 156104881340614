<template>
  <validation-provider
    v-slot="{ errors }"
    :name="$attrs.name"
    :rules="iRules"
    :vid="vid"
  >
    <v-label
      v-if="fixedLabel"
      :dark="fixedLabelDark"
    >
      {{ displayLabel }}
    </v-label>
    <v-file-input
      ref="input"
      v-model="iValue"
      v-bind="$attrs"
      :background-color="backgroundColor"
      :error-messages="errors"
      :error="validation && errors.length > 0"
      :clear-icon="'far fa-times-circle'"
      :counter="counter"
      :dark="fixedLabelDark || dark"
      :hide-details="(!validation || errors.length === 0) && !counter"
      :label="(!fixedLabel && displayLabel) || ''"
      :outlined="outlined"
      :dense="dense"
      class="field-file"
      v-on="$listeners"
      @input="$emit('input', $event)"
    >
      <template
        v-if="$slots['append-outer']"
        #append-outer
      >
        <slot name="append-outer" />
      </template>
    </v-file-input>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { useValidation } from '@/compositions/validation'

export default {
  name: 'FieldFile',
  components: {
    ValidationProvider
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'white'
    },
    counter: {
      type: [Boolean, Number, String],
      default: null
    },
    dark: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: true
    },
    fixedLabel: Boolean,
    fixedLabelDark: Boolean,
    label: {
      type: String,
      default: null
    },
    outlined: {
      type: Boolean,
      default: true
    },
    rules: {
      type: [String, Object],
      default: () => ({})
    },
    validation: {
      type: Boolean,
      default: false
    },
    value: {
      type: File,
      default: null
    },
    vid: {
      type: String,
      default: null
    }
  },
  setup (props) {
    const { iRules, displayLabel } = useValidation(props)

    return { iRules, displayLabel }
  },
  computed: {
    iValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    focus () {
      this.$refs.input.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.field-file) {
  .v-input__append-outer {
    margin-top: 0 !important;
  }
}
</style>
