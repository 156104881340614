<script setup>
import SocialNetworks from './SocialNetworks'
import BtnLink from '@/components/Buttons/BtnLink'
import DialogAbout from '@/components/Dialogs/DialogAbout'
import FooterLayout from '@/components/FooterLayout'
import { useUniverseStore } from '@/stores/universe'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
import { computed, getCurrentInstance, ref } from 'vue'
import moment from 'moment'
import _ from 'lodash'

const root = getCurrentInstance().proxy

defineProps({
  color: {
    type: String,
    default: undefined
  }
})

const userStore = useUserStore()
const universeStore = useUniverseStore()

const { menuItems } = storeToRefs(userStore)
const { settings } = storeToRefs(universeStore)

const about = ref(false)

const links = computed(() => {
  const items = menuItems.value ? [...menuItems.value.footer] : []

  if (!links) return []
  items.sort((a, b) => a.position - b.position)
  return _.map(items, link => {
    const hrefSetting = _.get(settings.value, link.destination)
    if (hrefSetting) {
      link.destination = hrefSetting
    }
    return getLink(link)
  })
})
const year = computed(() => moment().year())

function getLink (item) {
  let to, href, target, disabled
  let onClick = () => null

  let { alt, text } = getTitle(item.slug)

  switch (item.type) {
    case 'newTab':
      href = item.destination
      target = '_blank'
      disabled = false
      break
    case 'disabled':
      alt = null
      disabled = true
      break
    case 'modalWindow':
      onClick = getAction(item.destination)
      disabled = false
      break
    case null:
      to = { path: `/${item.destination}` }
      disabled = false
      break
  }

  return {
    text,
    to,
    href,
    alt,
    target,
    disabled,
    onClick,
    slug: item.slug
  }
}
function getAction (destination) {
  switch (destination) {
    case 'didomi':
      return () => window.Didomi ? window.Didomi.preferences.show() : null
    case 'about':
      return () => showDialogAbout()
  }
}
function getTitle (slug) {
  const handle = { year: year.value }
  return {
    alt: root.$t(`${slug}.alt`, handle),
    text: root.$t(`${slug}.text`, handle)
  }
}
function showDialogAbout () {
  about.value = true
}
</script>

<template>
  <div class="mt-10">
    <footer-layout
      :color="color"
      class="px-12"
    >
      <template #subtitle>
        <span v-html="$tc('footer.sav')" />
      </template>
      <template #links>
        <btn-link
          v-for="(link, index) in links"
          :key="index"
          :label="link.text"
          :title="link.alt"
          :alt="link.alt"
          :disabled="link.disabled"
          :href="link.href"
          :target="link.target"
          :to="link.to"
          :data-test="link.slug"
          class="body-2 font-weight-medium text-decoration-none"
          @click="link.onClick"
        />
      </template>
      <template #social>
        <social-networks />
      </template>
    </footer-layout>
    <div class="white">
      <v-container
        class="pt-0 pb-5 px-12"
        fluid
      >
        <v-divider class="mb-6" />
        <v-row no-gutters>
          <v-col class="d-flex justify-space-between body-2">
            <span>{{ $t('footer.brand') }}</span>
            <span>©{{ $utils.moment().format('Y') }} {{ $t('footer.all-rights-reserved') }}</span>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <dialog-about v-model="about" />
  </div>
</template>
