<template>
  <validation-provider
    v-slot="{ errors }"
    :name="$attrs.name"
    :rules="iRules"
    :vid="vid"
  >
    <div class="d-flex justify-space-between mb-2">
      <v-label>
        <span :class="(validation && !!errors.length) ? 'error--text' : null">
          {{ displayLabel }}
        </span>
      </v-label>
      <v-icon
        v-if="intl"
        class="mr-3"
        :color="(validation && !!errors.length) ? 'error' : null"
        small
      >
        fa-globe
      </v-icon>
    </div>
    <v-input
      :error-messages="errors"
      :error="validation && !!errors.length"
    >
      <v-tiptap
        ref="editor"
        v-model="iValue"
        style="width: 100%"
        :extensions="extensions"
        :disable-toolbar="disabled"
        :class="(validation && !!errors.length) ? 'field-error' : ''"
        :toolbar="['bold', 'italic', 'underline', 'bulletList', 'orderedList', 'link', 'clear']"
      >
        <template
          v-if="limit"
          #bottom="{ editor }"
        >
          <v-divider />
          <div class="d-flex align-center justify-space-between ml-2">
            <div class="subtitle-2">
              {{ $tc('common.char-left', limit - editor.storage.characterCount.characters(), [limit -
                editor.storage.characterCount.characters()]) }}
            </div>
          </div>
        </template>
      </v-tiptap>
    </v-input>
  </validation-provider>
</template>

<script>
import CharacterCount from '@tiptap/extension-character-count'
import { useValidation } from '@/compositions/validation'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'FieldEditor',
  components: {
    ValidationProvider
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    intl: Boolean,
    label: {
      type: String,
      default: null
    },
    limit: {
      type: Number,
      default: null
    },
    rules: {
      type: [String, Object],
      default: () => ({})
    },
    validation: {
      type: Boolean,
      default: false
    },
    vid: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: null
    }
  },
  setup (props) {
    const { iRules, displayLabel } = useValidation(props)

    return { iRules, displayLabel }
  },
  computed: {
    extensions () {
      if (this.limit) {
        return [
          CharacterCount.configure({
            limit: this.limit
          })
        ]
      }
      return []
    },
    iValue: {
      get () {
        return this.value
      },
      set (val) {
        const regex = /(<([^>]+)>)/ig
        if (!val.replace(regex, '')) {
          return this.$emit('input', null)
        } else {
          this.$emit('input', val)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.field-error) {
  .v-card {
    border: 2px solid var(--v-error-base) !important;
  }
}
</style>
