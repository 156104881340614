<template>
  <validation-provider
    v-slot="{ errors }"
    :name="$attrs.name"
    :rules="iRules"
    :vid="vid"
  >
    <v-autocomplete
      v-bind="{ ...$props, ...$attrs }"
      :value="selection"
      :error-messages="errors"
      :items="predictions"
      :hide-details="errors.length === 0 || !validation"
      :label="label + (isRequired ? '*' : '')"
      :clear-icon="'far fa-times-circle'"
      :disabled="disabled"
      :dense="dense"
      :search-input.sync="searchValue"
      :rules="[]"
      item-text="description"
      return-object
      data-form-type="other"
      attach
      @change="selectPrediction"
      @update:search-input="search"
    />
  </validation-provider>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader'
import { useValidation } from '@/compositions/validation'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'FieldAddress',
  components: {
    ValidationProvider
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'white'
    },
    counter: {
      type: [Boolean, Number, String],
      default: null
    },
    dense: {
      type: Boolean,
      default: true
    },
    disabled: Boolean,
    readonly: Boolean,
    filled: Boolean,
    fixedLabel: Boolean,
    fixedLabelDark: Boolean,
    label: {
      type: String,
      default: null
    },
    outlined: {
      type: Boolean,
      default: true
    },
    rules: {
      type: [String, Object],
      default: () => ({})
    },
    singleLine: Boolean,
    validation: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: null
    },
    vid: {
      type: String,
      default: null
    }
  },
  setup (props) {
    const { iRules, isRequired } = useValidation(props)

    return { iRules, isRequired }
  },
  data () {
    return {
      searchValue: null,
      selection: null,
      loader: null,
      googleApiClient: null,
      sessionToken: null,
      predictions: []
    }
  },
  async mounted () {
    this.loader = new Loader({
      apiKey: window.env.VUE_APP_GOOGLE_PLACES_KEY,
      version: 'weekly',
      libraries: ['places']
    })
    const google = await this.loader.load()
    this.googleApiClient = await google.maps.places
    if (this.value) {
      this.search(this.value[0])
      this.selection = this.value[0]
    }
  },
  methods: {
    selectPrediction (val) {
      this.sessionToken = null
      this.selection = val
      this.$emit('input', [val?.description] ?? null)
    },
    search (payload) {
      this.$debounce(this.fetch, payload, 1000)
    },
    fetch (payload) {
      if (!this.sessionToken) {
        this.sessionToken = new this.googleApiClient.AutocompleteSessionToken()
      }
      new this.googleApiClient.AutocompleteService().getPlacePredictions({
        input: payload,
        sessionToken: this.sessionToken
      }, (predictions, status) => {
        if (status === this.googleApiClient.PlacesServiceStatus.ZERO_RESULTS) {
          this.predictions = []
          return []
        }
        if (status !== this.googleApiClient.PlacesServiceStatus.OK || !predictions) {
          return []
        }
        this.predictions = predictions
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.autocomplete-menu .v-menu__content {
  z-index: 9999 !important;
  /* Ajustez la valeur de z-index selon vos besoins */
}
</style>
