import Vue from 'vue'

import CustomRouterLink from '@/components/CustomRouterLink'
import Avatar from '@/components/Avatar'
import ItemList from '@/components/ItemList'
import InfoBlock from '@/components/InfoBlock'
import PageLoader from '@/components/PageLoader'
import GlobalEvents from 'vue-global-events'

Vue.component('GlobalEvents', GlobalEvents)
Vue.component('CustomRouterLink', CustomRouterLink)
Vue.component('Avatar', Avatar)
Vue.component('ItemList', ItemList)
Vue.component('InfoBlock', InfoBlock)
Vue.component('PageLoader', PageLoader)
