<template>
  <validation-provider
    v-slot="{ errors }"
    :name="$attrs.name"
    :rules="iRules"
    :vid="vid"
  >
    <v-radio-group
      v-model="iValue"
      v-bind="$attrs"
      :disabled="disabled"
      :error-messages="errors"
      :error="validation && !!errors.length"
      :hide-details="!errors.length || !validation"
      :label="displayLabel"
      :row="row"
      class="wRadio"
      v-on="$listeners"
    >
      <v-radio
        v-for="item in internalItems"
        :key="item.value"
        :disabled="disabled"
        :label="getLabel(item)"
        :value="item[itemValue]"
        :class="{'v-input-radio--prepend-divider': item.prependDivider}"
        color="primary"
      />
    </v-radio-group>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { useValidation } from '@/compositions/validation'
import { useUniverseStore } from '@/stores/universe'
import { mapState } from 'pinia'

export default {
  name: 'FieldRadio',
  components: {
    ValidationProvider
  },
  props: {
    dictionaryKey: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: null
    },
    itemText: {
      type: [String, Function],
      default: 'text'
    },
    itemValue: {
      type: [String, Number, Object],
      default: 'value'
    },
    label: {
      type: String,
      default: null
    },
    row: {
      type: Boolean,
      default: true
    },
    rules: {
      type: [String, Object],
      default: () => ({})
    },
    validation: {
      type: Boolean,
      default: false
    },
    vid: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number, Object, Boolean],
      default: null
    }
  },
  setup (props) {
    const { iRules, displayLabel } = useValidation(props)

    return { iRules, displayLabel }
  },
  computed: {
    internalItems () {
      return this.dictionaryKey
        ? this.getDictionary(this.dictionaryKey)
        : this.items
    },
    iValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    ...mapState(useUniverseStore, {
      getDictionary: 'getDictionary'
    })
  },
  methods: {
    getLabel (item) {
      if (typeof this.itemText === 'function') {
        return this.itemText(item)
      }
      return item[this.itemText]
    }
  }
}
</script>

<style lang="scss" scoped>
.wRadio {
  .v-input__slot {
    margin-bottom: 0;
  }
  .v-input-radio--prepend-divider {
    padding-top: 16px;
    margin-top: 8px;
  }
}
</style>
