<template>
  <div />
</template>
<script>
export default {
  props: {
    apiKey: {
      type: String,
      default: null
    },
    iabVersion: {
      type: Number,
      default: 2
    },
    noticeId: {
      type: String,
      default: null
    },
    config: {
      type: Object,
      default: () => ({})
    },
    gdprAppliesGlobally: {
      type: Boolean,
      default: null
    },
    onReady: {
      type: Function,
      default: null
    },
    onConsentChanged: {
      type: Function,
      default: null
    },
    onNoticeShown: {
      type: Function,
      default: null
    },
    onNoticeHidden: {
      type: Function,
      default: null
    },
    onNoticeBackdropclick: {
      type: Function,
      default: null
    },
    onNoticeClickAgree: {
      type: Function,
      default: null
    },
    onNoticeClickMoreInfo: {
      type: Function,
      default: null
    },
    onPreferencesClickAgreeToAll: {
      type: Function,
      default: null
    },
    onPreferencesClickDisagreeToAll: {
      type: Function,
      default: null
    },
    onPreferencesClickPurposeAgree: {
      type: Function,
      default: null
    },
    onPreferencesClickPurposeDisagree: {
      type: Function,
      default: null
    },
    onPreferencesClickViewVendors: {
      type: Function,
      default: null
    },
    onPreferencesClickSaveChoices: {
      type: Function,
      default: null
    },
    onPreferencesClickVendorAgree: {
      type: Function,
      default: null
    },
    onPreferencesClickVendorDisagree: {
      type: Function,
      default: null
    },
    onPreferencesClickVendorSaveChoices: {
      type: Function,
      default: null
    },
    sdkPath: {
      type: String,
      default: 'https://sdk.privacy-center.org/'
    },
    embedTCFStub: {
      type: Boolean,
      default: true
    }
  },
  mounted () {
    this.setEvents()
    this.init()
  },
  methods: {
    getApiKey () {
      let apiKey
      if (this.config.app && this.config.app.apiKey) {
        apiKey = this.config.app.apiKey
      }
      return this.apiKey || apiKey
    },
    setEvents () {
      if (this.onReady) {
        window.didomiOnReady = window.didomiOnReady || []
        window.didomiOnReady.push(this.onReady)
      }

      window.didomiEventListeners = window.didomiEventListeners || []
      if (this.onConsentChanged) {
        window.didomiEventListeners.push({
          event: 'consent.changed',
          listener: (e) => {
            this.onConsentChanged(e.consentToken)
          }
        })
      }

      if (this.onNoticeShown) {
        window.didomiEventListeners.push({
          event: 'notice.shown',
          listener: () => this.onNoticeShown()
        })
      }

      if (this.onNoticeHidden) {
        window.didomiEventListeners.push({
          event: 'notice.hidden',
          listener: () => this.onNoticeHidden()
        })
      }

      if (this.onNoticeBackdropclick) {
        window.didomiEventListeners.push({
          event: 'notice.backdropclick',
          listener: () => this.onNoticeBackdropclick()
        })
      }

      if (this.onNoticeClickAgree) {
        window.didomiEventListeners.push({
          event: 'notice.clickagree',
          listener: () => this.onNoticeClickAgree()
        })
      }

      if (this.onNoticeClickMoreInfo) {
        window.didomiEventListeners.push({
          event: 'notice.clickmoreinfo',
          listener: () => this.onNoticeClickMoreInfo()
        })
      }

      if (this.onPreferencesClickAgreeToAll) {
        window.didomiEventListeners.push({
          event: 'preferences.clickagreetoall',
          listener: () => this.onPreferencesClickAgreeToAll()
        })
      }

      if (this.onPreferencesClickDisagreeToAll) {
        window.didomiEventListeners.push({
          event: 'preferences.clickdisagreetoall',
          listener: () => this.onPreferencesClickDisagreeToAll()
        })
      }

      if (this.onPreferencesClickPurposeAgree) {
        window.didomiEventListeners.push({
          event: 'preferences.clickpurposeagree',
          listener: () => this.onPreferencesClickPurposeAgree()
        })
      }

      if (this.onPreferencesClickPurposeDisagree) {
        window.didomiEventListeners.push({
          event: 'preferences.clickpurposedisagree',
          listener: () => this.onPreferencesClickPurposeDisagree()
        })
      }

      if (this.onPreferencesClickViewVendors) {
        window.didomiEventListeners.push({
          event: 'preferences.clickviewvendors',
          listener: () => this.onPreferencesClickViewVendors()
        })
      }

      if (this.onPreferencesClickSaveChoices) {
        window.didomiEventListeners.push({
          event: 'preferences.clicksavechoices',
          listener: () => this.onPreferencesClickSaveChoices()
        })
      }

      if (this.onPreferencesClickVendorAgree) {
        window.didomiEventListeners.push({
          event: 'preferences.clickvendoragree',
          listener: () => this.onPreferencesClickVendorAgree()
        })
      }

      if (this.onPreferencesClickVendorDisagree) {
        window.didomiEventListeners.push({
          event: 'preferences.clickvendordisagree',
          listener: () => this.onPreferencesClickVendorDisagree()
        })
      }

      if (this.onPreferencesClickVendorSaveChoices) {
        window.didomiEventListeners.push({
          event: 'preferences.clickvendorsavechoices',
          listener: () => this.onPreferencesClickVendorSaveChoices()
        })
      }
    },
    init () {
      let loaderParams
      const apiKey = this.getApiKey()
      const gdprAppliesGlobally = this.gdprAppliesGlobally !== false
      window.didomiConfig = this.config || {}

      // Set the SDK path
      window.didomiConfig.sdkPath = this.sdkPath

      // Embed the Didomi SDK on the page
      window.gdprAppliesGlobally = gdprAppliesGlobally
      if (this.noticeId) {
        loaderParams = `target_type=notice&target=${this.noticeId}`
      } else {
        loaderParams = `target=${document.location.hostname}`
      }

      // Embed the TCF stub
      if (this.embedTCFStub) {
        if (this.iabVersion === 2) {
          // TCF v2
          // eslint-disable-next-line
          (function(){function a(e){if(!window.frames[e]){if(document.body&&document.body.firstChild){var t=document.body;var n=document.createElement("iframe");n.style.display="none";n.name=e;n.title=e;t.insertBefore(n,t.firstChild)}else{setTimeout(function(){a(e)},5)}}}function e(n,r,o,c,s){function e(e,t,n,a){if(typeof n!=="function"){return}if(!window[r]){window[r]=[]}var i=false;if(s){i=s(e,t,n)}if(!i){window[r].push({command:e,parameter:t,callback:n,version:a})}}e.stub=true;function t(a){if(!window[n]||window[n].stub!==true){return}if(!a.data){return}var i=typeof a.data==="string";var e;try{e=i?JSON.parse(a.data):a.data}catch(t){return}if(e[o]){var r=e[o];window[n](r.command,r.parameter,function(e,t){var n={};n[c]={returnValue:e,success:t,callId:r.callId};a.source.postMessage(i?JSON.stringify(n):n,"*")},r.version)}}if(typeof window[n]!=="function"){window[n]=e;if(window.addEventListener){window.addEventListener("message",t,false)}else{window.attachEvent("onmessage",t)}}}e("__tcfapi","__tcfapiBuffer","__tcfapiCall","__tcfapiReturn");a("__tcfapiLocator");})();
        } else {
          // TCF v1
          // eslint-disable-next-line
          (function(){function r(){if(!window.frames.__cmpLocator){if(document.body&&document.body.firstChild){var e=document.body;var t=document.createElement("iframe");t.style.display="none";t.name="__cmpLocator";t.title="cmpLocator";e.insertBefore(t,e.firstChild)}else{setTimeout(r,5)}}}function e(e,t,r){if(typeof r!=="function"){return}if(!window.__cmpBuffer){window.__cmpBuffer=[]}if(e==="ping"){r({gdprAppliesGlobally:window.gdprAppliesGlobally,cmpLoaded:false},true)}else{window.__cmpBuffer.push({command:e,parameter:t,callback:r})}}e.stub=true;function t(a){if(!window.__cmp||window.__cmp.stub!==true){return}if(!a.data){return}var n=typeof a.data==="string";var e;try{e=n?JSON.parse(a.data):a.data}catch(t){return}if(e.__cmpCall){var o=e.__cmpCall;window.__cmp(o.command,o.parameter,function(e,t){var r={__cmpReturn:{returnValue:e,success:t,callId:o.callId}};a.source.postMessage(n?JSON.stringify(r):r,"*")})}}if(typeof window.__cmp!=="function"){window.__cmp=e;if(window.addEventListener){window.addEventListener("message",t,false)}else{window.attachEvent("onmessage",t)}}r()})();
        }
      }

      const spcloaderId = 'spcloader'
      const spcloaderScript = document.getElementById(spcloaderId)

      // Didomi is already loaded, no need to add the script again
      if (spcloaderScript) {
        return null
      }

      // Embed the SDK
      const loaderScript = document.createElement('script')
      loaderScript.id = spcloaderId
      loaderScript.type = 'text/javascript'
      loaderScript.async = true
      loaderScript.src = this.sdkPath + apiKey + '/loader.js?' + loaderParams
      loaderScript.charset = 'utf-8'

      const firstScriptTagInDocument = document.getElementsByTagName('script')[0]
      firstScriptTagInDocument.parentNode.insertBefore(
        loaderScript,
        firstScriptTagInDocument
      )
    }
  }
}
</script>
