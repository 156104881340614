<script setup>
import ColorPriorities from '@/constants/ColorPriorities'
import { useUserStore } from '@/stores/user'
import { useRefStore } from '@/stores/ref'
import { storeToRefs } from 'pinia'
import { computed, getCurrentInstance } from 'vue'

const root = getCurrentInstance().proxy

const props = defineProps({
  title: {
    type: String,
    default: null
  },
  subtitle: {
    type: String,
    default: null
  },
  avatar: {
    type: String,
    default: null
  },
  initials: {
    type: Array,
    default: null
  },
  description: {
    type: String,
    default: null
  },
  secondSubtitle: {
    type: String,
    default: null
  },
  unread: {
    type: Boolean,
    default: true
  },
  date: {
    type: String,
    default: null
  },
  path: {
    type: String,
    default: null
  },
  statusType: {
    type: String,
    default: null
  },
  message: {
    type: String,
    default: null
  },
  senderId: {
    type: Number,
    default: null
  },
  type: {
    type: String,
    default: null
  },
  payload: {
    type: Object,
    default: null
  }
})

const refStore = useRefStore()
const userStore = useUserStore()

const { getRefs } = storeToRefs(refStore)
const { getConnectedUserById } = storeToRefs(userStore)

const dueDate = computed(() => props.payload.dueDate
  ? root.$options.filters.formatDate(props.payload.dueDate)
  : '')
const hasEntities = computed(() => props.payload.entityNames?.length)
const prioritySlug = computed(() => priority.value?.slug ?? null)
const priority = computed(() => props.payload.priority
  ? taskPriorities.value.find(f =>
    f.id.toString() === props.payload.priority.toString())
  : null)
const priorityColor = computed(() => priority.value
  ? ColorPriorities[priority.value.slug]?.color ?? null
  : null)
const taskPriorities = computed(() => getRefs.value?.refTaskPriorities ?? [])

function isPresent () {
  return props.senderId && !!getConnectedUserById.value(props.senderId)
}
</script>

<template>
  <div>
    <v-row>
      <v-col class="notif__avatar mr-3">
        <avatar
          :size="50"
          :url="avatar"
          :initials="initials"
          :connected="isPresent()"
        />
      </v-col>
      <v-col class="pt-6 notif__head font-weight-regular">
        <div>
          {{ message }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="notif__content pt-1">
        <div v-if="hasEntities">
          {{ $t('notification.task.relates-to') }}
          <ul>
            <li
              v-for="i in payload.entityNames"
              :key="i"
            >
              {{ i }}
            </li>
          </ul>
        </div>
        <div v-if="dueDate">
          {{ $t('notification.task.due-date', { dueDate }) }}
        </div>
        <div v-if="prioritySlug">
          <v-icon
            :color="priorityColor"
            class="mr-2"
            x-small
          >
            fa fa-circle
          </v-icon>
          {{ $t(`priority.${prioritySlug}`) }}
        </div>
        <v-row class="d-flex justify-space-between align-end">
          <v-col cols="8">
            {{ payload.title }}
          </v-col>
          <v-col
            class="d-flex justify-end align-end"
            cols="4"
          >
            <v-btn
              v-if="path"
              color="primary"
              :href="path"
              @click.prevent.stop="$emit('click')"
            >
              {{ $t('label.task.see') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss" scoped>
.notif__head {
  font-size: 0.875rem !important;
}
</style>
