<script setup>
import { computed } from 'vue'

const emits = defineEmits(['input'])

const props = defineProps({
  color: {
    type: String,
    default: '#fff'
  },
  height: {
    type: [Number, String],
    default: 64
  },
  logo: {
    type: String,
    default: null
  },
  mobile: Boolean,
  value: Boolean,
  items: {
    type: Array,
    default: () => ([])
  }
})

const iValue = computed({
  get () {
    return props.value
  },
  set (val) {
    emits('input', val)
  }
})
</script>

<template>
  <v-app-bar
    :color="color"
    :height="height"
    app
  >
    <v-app-bar-nav-icon
      v-if="mobile"
      @click.stop="iValue = !iValue"
    />
    <v-toolbar-title v-if="$slots['logo'] && !mobile">
      <slot name="logo" />
    </v-toolbar-title>
    <v-spacer v-if="$slots['logo'] && !mobile" />
    <slot name="navigation">
      <v-toolbar-items
        v-if="!mobile"
        :color="color"
      >
        <v-btn
          v-for="item in items"
          :key="item.name"
          :to="item.to"
          :ripple="false"
          plain
          text
        >
          {{ item.title }}
        </v-btn>
      </v-toolbar-items>
    </slot>
    <v-spacer />
    <div class="d-flex actions">
      <slot
        v-if="$slots['actions']"
        name="actions"
      />
    </div>
  </v-app-bar>
</template>

<style lang="scss" scoped>
:deep(.actions) {
  .v-btn {
    &--icon {
      color: var(--v-night-base) !important;
    }
  }
}
</style>
