<template>
  <div>
    <v-combobox
      v-model="iValue"
      :background-color="backgroundColor"
      :search-value.sync="search"
      :clearable="false"
      :items="codes"
      label=""
      item-text="label"
      hide-details
      outlined
      dense
      return-object
      hide-selected
    >
      <template #item="{ item }">
        <country-flag :country="item.value" />
        <span class="ml-2 mt-2">
          {{ item.label }}
        </span>
      </template>
      <template #selection="selection">
        <template v-if="selection.item.value">
          <country-flag
            v-if="!$attrs.dense"
            size="small"
            :country="selection.item.value"
          />
          <span class="pl-2 mt-1">
            {{ selection.item.value }}
          </span>
        </template>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import CountryFlag from 'vue-country-flag'
import dialCodes from '@/constants/DialCodes'

export default {
  name: 'FieldPhoneCountry',
  components: {
    CountryFlag
  },
  props: {
    allowedCountryCodes: {
      type: Array,
      default: null
    },
    backgroundColor: {
      type: String,
      default: 'white'
    },
    dense: {
      type: Boolean,
      default: true
    },
    outlined: {
      type: Boolean,
      default: true
    },
    value: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      search: null
    }
  },
  computed: {
    codes () {
      return dialCodes.filter(f => !this.allowedCountryCodes ||
        this.allowedCountryCodes.some(s => s === f.code))
        .map(m => ({
          label: `${m.dial_code} ${m.name}`,
          value: m.code
        }))
    },
    iValue: {
      get () {
        return this.codes.find(f => f.value === this.value) ?? null
      },
      set (val) {
        this.$emit('input', val?.value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.v-autocomplete.v-select.v-input--is-focused input) {
  width: 27px !important;
  min-width: 5px !important;
}
</style>
