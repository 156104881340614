import i18n from '@/plugins/vue-i18n'
import { useSnackbarStore } from '@/stores/snackbar'

export function useMessages () {
  const snackbarStore = useSnackbarStore()

  const setMessage = (message, color, timeout) => {
    snackbarStore.setMessage({
      message,
      timeout,
      color
    })
  }

  const successMessage = (key, attributes, timeout) => {
    setMessage(i18n.t(key, attributes), 'success', timeout)
  }

  const errorMessage = (key, attributes, timeout) => {
    const message = i18n.te(key) ? i18n.t(key, attributes) : i18n.t('common.error.base')
    setMessage(message, 'error', timeout)
  }

  const formErrorMessages = (errors, timeout) => {
    Object.keys(errors).reduce((acc, error) => {
      acc = acc.concat(errors[error])
      return acc
    }, []).forEach(field => {
      const key = field.message
      const attributes = field.placeholders.reduce((acc, placeholder, index) => {
        acc[`placeholder_${index}`] = placeholder
        return acc
      }, {})
      errorMessage(key, attributes, timeout)
    })
  }

  return {
    errorMessage,
    formErrorMessages,
    setMessage,
    successMessage
  }
}
