import axios from '@/plugins/axios'
import jsonToFormData from '@/services/jsonToFormData'
import qs from 'qs'

export default {
  add (data, onUploadProgress) {
    const obj = jsonToFormData(data, {}, { nullsAsUndefineds: true })
    return axios.post('/assets', obj.data, {
      ...obj.config,
      onUploadProgress
    })
  },
  addVideo (params) {
    return axios.post('/videos', params)
  },
  delete (id) {
    return axios.delete(`/assets/${id}`)
  },
  deleteVideo (id) {
    return axios.delete(`/videos/${id}`)
  },
  get (params) {
    return axios.get(`/assets?${qs.stringify(params, { encode: false })}`)
  },
  getVideos (params) {
    return axios.get(`/videos?${qs.stringify(params, { encode: false })}`)
  },
  reorder (id, position) {
    return axios.post(`/assets/${id}/reorder`, { position })
  },
  update (id, params) {
    return axios.patch(`/assets/${id}`, params)
  },
  updateVideo (id, params) {
    return axios.patch(`/videos/${id}`, params)
  }
}
