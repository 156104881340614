<template>
  <validation-provider
    ref="provider"
    v-slot="{ errors }"
    :name="$attrs.name"
    :rules="iRules"
    :vid="vid"
  >
    <input
      :value="value"
      type="hidden"
    >
    <field-image-label
      :label="displayLabel"
      :error="validation && !!errors.length"
      :append-icon="appendIcon"
      class="mb-2"
    />
    <v-card
      v-if="!iValue"
      :disabled="disabled"
      :height="size"
      :width="size"
      :class="[...cardClass, !!errors.length ? 'document--error' : null]"
      outlined
      @click="openFile"
    >
      <v-card-text class="d-flex fill-height flex-column justify-center align-center">
        <v-icon
          :color="validation && !!errors.length ? 'error' : 'primary'"
          size="35"
        >
          fa-plus-circle
        </v-icon>
      </v-card-text>
    </v-card>
    <v-card
      v-else
      :disabled="disabled"
      :height="size"
      :width="size"
      :class="[...cardClass, !!errors.length ? 'document--error' : null]"
      outlined
    >
      <v-card-text>
        <field-image-menu
          v-model="menu"
          @delete="deleteImage"
          @download="downloadImage"
        />
        <v-img
          :src="imageUrl"
          max-height="170px"
          @click="openFile"
        />
      </v-card-text>
    </v-card>
    <input
      ref="file"
      :accept="accept"
      class="document__file"
      type="file"
      @change="loadFile"
    >
    <span
      v-if="errors"
      class="error--text"
    >
      {{ errors[0] }}
    </span>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { useValidation } from '@/compositions/validation'
import FieldImageLabel from './FieldImageLabel'
import FieldImageMenu from './FieldImageMenu'

export default {
  components: {
    FieldImageLabel,
    FieldImageMenu,
    ValidationProvider
  },
  props: {
    appendIcon: {
      type: String,
      default: null
    },
    disabled: Boolean,
    label: {
      type: String,
      default: null
    },
    size: {
      type: [Number, String],
      default: 200
    },
    value: {
      type: [File, Object],
      default: null
    },
    readonly: Boolean,
    rules: {
      type: [String, Object],
      default: () => ({})
    },
    validation: {
      type: Boolean,
      default: false
    },
    vid: {
      type: String,
      default: null
    }
  },
  setup (props) {
    const { iRules, displayLabel } = useValidation(props)

    return { iRules, displayLabel }
  },
  data () {
    return {
      isError: false,
      isSuccess: false,
      menu: false
    }
  },
  computed: {
    accept () {
      return '.jpg, .jpeg, .png'
    },
    cardClass () {
      return ['document']
    },
    imageUrl () {
      if (this.iValue?.urls_cached) {
        return JSON.parse(this.iValue.urls_cached).original
      } else if (this.iValue) {
        return URL.createObjectURL(this.iValue)
      }
      return null
    },
    iValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    deleteImage () {
      this.iValue = null
      this.$refs.file.value = null
      this.$emit('delete')
    },
    downloadImage () {
      const link = document.createElement('a')
      link.href = this.imageUrl
      link.setAttribute('download', '')
      link.setAttribute('target', '_blank')
      document.body.appendChild(link)
      link.click()
    },
    loadFile (input) {
      if (input.target.files.length) {
        this.iValue = input.target.files[0]
        this.$emit('add')
      }
    },
    openFile () {
      this.$refs.file.click()
    }
  }
}
</script>

<style lang="scss" scoped>
.document {
  text-align: center;
  cursor: pointer;
  width: 100%;

  &--new {
    cursor: pointer !important;
    border: 1px dashed var(--v-primary-base);

    &--is-upload {
      cursor: default !important;
    }
  }

  &--error {
    border: 1px dashed var(--v-error-base);
  }

  &--delete {
    border: 1px dashed var(--v-error-base);
  }

  &__text {
    width: 100%;

    &__title {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &__file {
    display: none;
  }
}
</style>
