<script setup>
import ColorPriorities from '@/constants/ColorPriorities'
import { useRefStore } from '@/stores/ref'
import { computed } from 'vue'

const emits = defineEmits(['input'])

const props = defineProps({
  color: {
    type: String,
    default: 'white'
  },
  value: {
    type: Number,
    default: null
  }
})

const { getRefs } = useRefStore()

const iValue = computed({
  get () {
    return props.value
      ? taskPriorities.value.find(f => f.id === props.value)
      : null
  },
  set (val) {
    emits('input', val)
  }
})
const taskPriorities = computed(() => {
  return getRefs?.refTaskPriorities ?? []
})

function getColor (slug) {
  return ColorPriorities[slug]?.color ?? null
}
</script>

<template>
  <v-menu
    v-bind="$attrs"
    bottom
  >
    <template
      v-if="iValue"
      #activator="{ on }"
    >
      <v-chip
        :color="color"
        label
        v-on="on"
      >
        <v-icon
          :color="getColor(iValue.slug)"
          class="mr-2"
          x-small
        >
          fa fa-circle
        </v-icon>
        {{ $t(`priority.${iValue.slug }`) }}
      </v-chip>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in taskPriorities"
        :key="index"
        @click="iValue = item.id"
      >
        <v-list-item-title>
          <v-icon
            :color="getColor(item.slug)"
            class="mr-2"
            x-small
          >
            fa fa-circle
          </v-icon>
          {{ $t(`priority.${ item.slug }`) }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
