<script setup>
import DialogHeader from '@/components/Dialogs/DialogHeader'
import { computed } from 'vue'

const emits = defineEmits(['input', 'close'])
const props = defineProps({
  hideHeader: Boolean,
  title: {
    type: String,
    default: null
  },
  show: Boolean,
  maxWidth: {
    type: [Number, String],
    default: 800
  },
  attach: {
    type: Boolean,
    default: false
  },
  closePosition: {
    type: String,
    default: 'left'
  }
})

const iValue = computed({
  get () {
    return props.show
  },
  set (val) {
    emits('input', val)
  }
})
</script>

<template>
  <v-dialog
    v-model="iValue"
    :max-width="maxWidth"
    :attach="attach"
    persistent
    @click:outside="$emit('close')"
  >
    <v-card>
      <dialog-header
        v-if="!hideHeader"
        :title="title"
        :close-position="closePosition"
        @close="$emit('close')"
      >
        <template #prepend>
          <slot name="header-prepend" />
        </template>
      </dialog-header>
      <v-card-text>
        <slot name="dialog-body" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
