export default {
  toEntityData (formData) {
    const entityData = {
      performerUserProfileId: formData.performerUserProfileId,
      refTaskPriorityId: formData.refTaskPriorityId,
      title: formData.title,
      typeId: formData.typeId
    }
    if (formData.id) {
      entityData.id = formData.id
    }
    if (formData.dueDate) {
      entityData.dueDate = formData.dueDate
    }
    if (!entityData.id) {
      entityData.refStatusId = formData.status
        .find(f => f.slug === 'in-progress').id ?? null
    }
    if (formData.entities) {
      entityData.entities = formData.entities
    }
    return entityData
  }
}
