
import { useUniverseStatusStore } from '@/stores/universeStatus'
import { useUniverseStore } from '@/stores/universe'
import { useAuthStore } from '@/stores/auth'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed } from 'vue'

export const useAppStore = defineStore('app', () => {
  const appIsReady = computed(() => {
    const universeStatusStore = useUniverseStatusStore()
    const universeStore = useUniverseStore()
    const authStore = useAuthStore()

    return !!universeStatusStore.list &&
      !!universeStore.settings &&
      !!universeStore.dictionary &&
      !!authStore.user
  })
  const locale = computed(() => {
    const universeStore = useUniverseStore()
    const universeSettings = universeStore?.getSettings ?? null

    return universeSettings?.app?.defaultRefLocale ?? null
  })

  return {
    appIsReady,
    locale
  }
}, { persist: true })

if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(acceptHMRUpdate(useAppStore, import.meta.webpackHot))
}
