import axios from '@/plugins/axios'

export default {
  getRefs () {
    return axios.get('/refs').then(response => response.data)
  },
  getRefRights () {
    return axios.get('/refs/rights?unlimited=1').then(response => response.data)
  }
}
