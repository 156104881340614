<script setup>
import { useUniverseStore } from '@/stores/universe'
import { computed } from 'vue'
import { storeToRefs } from 'pinia'

const emits = defineEmits(['input'])

const props = defineProps({
  items: {
    type: Array,
    default: () => ([])
  },
  value: Boolean
})

const universeStore = useUniverseStore()

const { settings } = storeToRefs(universeStore)

const iValue = computed({
  get () {
    return props.value
  },
  set (val) {
    emits('input', val)
  }
})
const logoFull = computed(() => settings.value?.theme?.logoFullUrl ?? null)
</script>

<template>
  <v-navigation-drawer
    v-model="iValue"
    mini-variant-width="75"
    class="app-menu"
    app
  >
    <v-list>
      <v-list-item>
        <v-img
          :src="logoFull"
          width="190px"
          height="25px"
        />
      </v-list-item>
      <v-list-item
        v-for="(menu, menuIndex) in items"
        :key="`menu-${menuIndex}`"
        :to="`/${menu.destination}`"
      >
        <v-list-item-title class="text-capitalize">
          {{ $t(`ref-menu.${menu.slug}`) }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
