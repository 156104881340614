export default {
  userEntitycustomFilter (item, queryText) {
    const displayName = item.displayName?.toLowerCase() ?? null
    const firstName = item.firstName?.toLowerCase() ?? null
    const lastName = item.lastName?.toLowerCase() ?? null
    const email = item.email?.toLowerCase() ?? null
    const searchText = queryText.toLowerCase()

    return displayName?.indexOf(searchText) > -1 ||
        firstName?.indexOf(searchText) > -1 ||
        lastName?.indexOf(searchText) > -1 ||
        email?.indexOf(searchText) > -1
  }
}
