import NotificationFetcher from '@/services/fetchers/Notification'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'
import _ from 'lodash'

const LIMIT = 10
let stopScroll = true
let pending = null

export const useNotificationStore = defineStore('notification', () => {
  const notifications = ref(null)
  const unread = ref(null)

  const getNotifications = computed(() => notifications.value)
  const getTotalUnreadNotification = computed(() => unread.value)

  function clear () {
    notifications.value = null
    unread.value = null
  }
  async function deleteNotif ({ notificationId }) {
    notifications.value = _.filter(notifications.value, n => n.id !== notificationId)
    await NotificationFetcher.delete(notificationId)
  }
  async function fetchNotifications ({ userId }) {
    const notifs = await NotificationFetcher.getNotifications(userId, null)

    stopScroll = (notifs && notifs.data.length < LIMIT)
    notifications.value = notifs?.data ?? null
    unread.value = notifs?.meta?.totalUnread ?? null
  }
  async function fetchMoreNotifications ({ userId, lastNotification }) {
    if (!stopScroll && pending !== lastNotification) {
      pending = lastNotification
      const notifs = await NotificationFetcher.getNotifications(userId, lastNotification)
      stopScroll = (notifs && notifs.data.length < LIMIT)
      notifications.value = notifs ? _.uniqBy(notifications.value.concat(notifs.data), n => n.id) : null
      unread.value = notifs?.meta?.totalUnread ?? null
      pending = null
    }
  }
  async function read ({ notificationId }) {
    unread.value--
    await NotificationFetcher.read(notificationId)
    notifications.value.find(oneNotification => (oneNotification.id === notificationId)).readAt = new Date()
  }
  async function readAll ({ userId }) {
    await NotificationFetcher.readAll()
    unread.value = 0
    await fetchNotifications({ userId })
  }

  return {
    notifications,
    unread,

    getNotifications,
    getTotalUnreadNotification,

    clear,
    deleteNotif,
    fetchNotifications,
    fetchMoreNotifications,
    read,
    readAll
  }
})

if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(acceptHMRUpdate(useNotificationStore, import.meta.webpackHot))
}
