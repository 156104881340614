<script setup>
import { useSnackbarStore } from '@/stores/snackbar'
import { computed, getCurrentInstance } from 'vue'

const root = getCurrentInstance().proxy

const snackbarStore = useSnackbarStore()

const isMobile = computed(() => root.$vuetify.breakpoint.mdAndDown)
const message = computed(() => snackbarStore.message)
const color = computed(() => snackbarStore.color)
const timeout = computed(() => snackbarStore.timeout)
const show = computed(() => snackbarStore.show)

function change (val) {
  if (!val) {
    snackbarStore.clear()
  }
}
</script>

<template>
  <v-snackbar
    v-if="show"
    :value="show"
    :class="[isMobile ? 'v-snack--mobile' : '', 'pt-0']"
    :top="isMobile"
    :bottom="!isMobile"
    :right="!isMobile"
    :color="color"
    :timeout="timeout"
    @input="change"
  >
    {{ message }}
    <template #action="{ attrs }">
      <v-btn
        v-bind="attrs"
        text
        @click="change(false)"
      >
        {{ $t('common.close') }}
      </v-btn>
    </template>
  </v-snackbar>
</template>
