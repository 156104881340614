import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useTaskStore = defineStore('task', () => {
  const dialog = ref(false)
  const entity = ref(null)
  const title = ref(null)

  const isManagerVisible = computed(() => dialog.value)
  const getEntity = computed(() => entity.value)
  const getTitle = computed(() => title.value)

  function hideDialog () {
    dialog.value = false
    title.value = null
    entity.value = null
  }
  function showDialog (payload) {
    entity.value = payload.entity
    title.value = payload.title
    dialog.value = true
  }

  return {
    dialog,
    entity,
    title,

    isManagerVisible,
    getEntity,
    getTitle,

    hideDialog,
    showDialog
  }
})

if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(acceptHMRUpdate(useTaskStore, import.meta.webpackHot))
}
