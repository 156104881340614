import { acceptHMRUpdate, defineStore } from 'pinia'
import { useUniverseStore } from '@/stores/universe'
import axios from 'axios'
import router from '@/router'
import { computed, ref } from 'vue'

export const useTranslationStore = defineStore('translation', () => {
  const messages = ref({})
  const loading = ref(false)

  const getLocaleMessages = computed(() => locale => messages.value[locale] ?? {})
  const getMessage = computed(() => (locale, key) => messages.value[locale][key] ?? null)
  const getMessages = computed(() => messages.value ?? [])
  const getVersion = computed(() => {
    const universeStore = useUniverseStore()
    return universeStore?.settings?.translation?.currentVersion ?? null
  })

  function clear () {
    messages.value = {}
  }
  async function loadTranslations (force) {
    if (force || !Object.keys(messages.value).length) {
      const msgs = {}
      const locales = window.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')

      try {
        loading.value = true
        const trads = (await axios({
          url: '/translations?unlimited=1',
          baseURL: window.env.VUE_APP_WORKPLACE_CORE_API_URL
        }))?.data?.data ?? []

        if (trads.length) {
          locales.forEach(key => {
            msgs[key] = trads.reduce((acc, val) => {
              acc[val.key] = val.label[key.replace('-', '_')] ?? null
              return acc
            }, {})
          })

          locales.forEach(key => {
            const matched = key.match(/[A-Za-z0-9-_]+\./i)
            if (matched && matched.length > 1) {
              const locale = matched[1]

              msgs[locale] = {
                ...locales(key),
                ...require(`vee-validate/dist/locale/${locale.split('-')[0]}`)
              }
            }
          })
          setMessages(msgs)
        }
      } catch (e) {
        if (e.response.status === 503) {
          redirectError('maintenance', e.response.message, { path: location.pathname, error: e.response })
          return false
        }
      } finally {
        loading.value = false
      }
    }
  }
  function redirectError (name, message, extra) {
    router.push({
      name,
      params:
      {
        message,
        path: extra?.path,
        error: extra?.error
      }
    })
  }
  function setLocaleMessages (payload) {
    messages.value[payload.locale] = payload.messages
  }
  function setMessage ({ key, label }) {
    const locales = window.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
    locales.forEach(locale => {
      messages.value[locale][key] = label[locale.replace('-', '_')]
    })
  }
  function setMessages (payload) {
    messages.value = payload
  }

  return {
    loading,
    messages,

    getLocaleMessages,
    getMessage,
    getMessages,
    getVersion,

    clear,
    loadTranslations,
    redirectError,
    setLocaleMessages,
    setMessage,
    setMessages
  }
}, { persist: true })

if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(acceptHMRUpdate(useTranslationStore, import.meta.webpackHot))
}
