<script setup>
import List from '@/components/List'
import { computed, getCurrentInstance } from 'vue'

const root = getCurrentInstance().proxy

const emits = defineEmits(['download', 'delete', 'input'])

const props = defineProps({
  disabled: Boolean,
  value: Boolean
})

const color = computed(() => props.disabled ? 'grey' : 'black')
const items = computed(() => [
  {
    title: root.$t('common.download'),
    icon: 'fa fa-download',
    action: () => {
      iValue.value = false
      emits('download')
    }
  },
  {
    title: root.$t('common.delete'),
    icon: 'fa fa-trash',
    action: () => {
      iValue.value = false
      emits('delete')
    }
  }
])
const iValue = computed({
  get: () => props.value,
  set: val => emits('input', val)
})
</script>

<template>
  <v-menu
    :value="value"
    :disabled="disabled"
    transition="slide-y-transition"
    attach
    @input="$emit('input', $event)"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        class="action-menu"
        icon
        v-on="on"
      >
        <v-icon
          small
          :color="color"
        >
          fas fa-ellipsis-v
        </v-icon>
      </v-btn>
    </template>
    <list :items="items" />
  </v-menu>
</template>

<style lang="scss" scoped>
.action-menu {
  z-index: 999 !important;
  position: absolute !important;
  right: 10px;
  top: 10px;
}

.v-menu {
  z-index: 999 !important;
}
</style>
