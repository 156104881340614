<script setup>
import NoteFetcher from '@/services/fetchers/Note'
import { useNoteStore } from '@/stores/note'
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  OrderedList,
  BulletList,
  ListItem,
  Bold,
  Italic,
  Underline
} from 'tiptap-extensions'
import { ref, onMounted, onBeforeUnmount } from 'vue'

const emits = defineEmits(['expand'])

const props = defineProps({
  note: {
    type: Object,
    required: true
  }
})

const noteStore = useNoteStore()

const editor = ref(null)
const isExpanded = ref(false)

onMounted(() => {
  editor.value = new Editor({
    autoFocus: true,
    extensions: [
      new OrderedList(),
      new BulletList(),
      new ListItem(),
      new Bold(),
      new Underline(),
      new Italic()
    ],
    content: props.note.content,
    onBlur: async (value) => {
      try {
        await NoteFetcher.edit(props.note.id, { content: value.event.target.innerHTML })
        noteStore.setEmittedNote(value.event.target.innerHTML)
      } catch (error) {
        throw new Error(error)
      }
    }
  })
})

onBeforeUnmount(() => {
  editor.value.destroy()
})

function toggleExpand () {
  isExpanded.value = !isExpanded.value
  emits('expand', isExpanded.value)
}
</script>

<template>
  <section class="note">
    <div class="primary note__header">
      <div class="note__action">
        <v-icon
          v-if="!isExpanded"
          color="white"
          class="note__icon"
          size="18"
          @click.stop="toggleExpand"
        >
          fas fa-expand-alt
        </v-icon>
        <v-icon
          v-if="isExpanded"
          color="white"
          class="note__icon"
          size="18"
          @click.stop="toggleExpand"
        >
          fas fa-compress-alt
        </v-icon>
        <v-icon
          color="white"
          class="note__icon"
          size="18"
          @click.stop="$emit('close')"
        >
          fas fa-times
        </v-icon>
      </div>
      <editor-menu-bar
        v-slot="{ commands, isActive }"
        :editor="editor"
      >
        <div class="note__menubar">
          <v-btn
            small
            :class="{ 'is-active': isActive.bold() }"
            @click="commands.bold"
          >
            <v-icon size="14">
              fa-bold
            </v-icon>
          </v-btn>

          <v-btn
            small
            :class="{ 'is-active': isActive.italic() }"
            @click="commands.italic"
          >
            <v-icon size="14">
              fa-italic
            </v-icon>
          </v-btn>

          <v-btn
            small
            :class="{ 'is-active': isActive.underline() }"
            @click="commands.underline"
          >
            <v-icon size="14">
              fa-underline
            </v-icon>
          </v-btn>

          <v-btn
            small
            :class="{ 'is-active': isActive.bullet_list() }"
            @click="commands.bullet_list"
          >
            <v-icon size="14">
              fa-list
            </v-icon>
          </v-btn>
        </div>
      </editor-menu-bar>
    </div>
    <div class="note__content">
      <h4 class="text-h4 note__title font-weight-bold">
        {{ $t('common.notes') }}
      </h4>
      <editor-content :editor="editor" />
    </div>
  </section>
</template>

<style lang="scss" scoped>
.note {
  &__header {
    padding: 8px 8px 16px 16px;
    height: 100px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }

  &__title {
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom-style: dashed;
    border-bottom-width: 1px;
  }

  :deep(.ProseMirror) {
    &:focus {
      outline: none;
    }
  }

  &__menubar {
    .v-btn {
      margin-right: 10px;

      &.is-active {
        background-color: var(--v-accent-base);
      }
    }
  }

  &__action {
    display: flex;
    justify-content: flex-end;
  }

  &__icon {
    margin-left: 24px;
  }

  &__content {
    padding: 32px 24px;
  }
}
</style>
