<template>
  <validation-provider
    v-slot="{ errors }"
    :name="$attrs.name"
    :rules="iRules"
    :vid="vid"
  >
    <v-label
      v-if="fixedLabel"
      :dark="fixedLabelDark"
    >
      {{ displayLabel }}
    </v-label>
    <v-select
      v-bind="$attrs"
      v-model="iValue"
      :background-color="backgroundColor"
      :clear-icon="'far fa-times-circle'"
      :clearable="clearable"
      :dense="dense"
      :error-messages="errors"
      :error="validation && errors.length > 0"
      :hide-details="!validation || errors.length === 0"
      :items="options"
      :item-text="itemText"
      :item-value="itemValue"
      :label="(!fixedLabel && displayLabel) || ''"
      :outlined="outlined"
      :prefix="$t(prefix)"
      v-on="$listeners"
    />
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { useValidation } from '@/compositions/validation'
import { useFieldStore } from '@/compositions/fieldStore'
import dot from 'dot-object'

export default {
  name: 'FieldList',
  components: {
    ValidationProvider
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'white'
    },
    clearable: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    fixedLabel: Boolean,
    fixedLabelDark: Boolean,
    items: {
      type: Array,
      default: null
    },
    itemText: {
      type: [String, Function],
      default: 'text'
    },
    itemValue: {
      type: String,
      default: 'value'
    },
    label: {
      type: String,
      default: null
    },
    outlined: {
      type: Boolean,
      default: true
    },
    prefix: {
      type: String,
      default: null
    },
    rules: {
      type: [String, Object],
      default: () => ({})
    },
    storeKey: {
      type: String,
      default: undefined
    },
    validation: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Object, Number, Array],
      default: null
    },
    vid: {
      type: String,
      default: null
    }
  },
  setup (props) {
    const { store, iStoreKey } = useFieldStore(props)
    const { iRules, displayLabel } = useValidation(props)

    return { store, iStoreKey, iRules, displayLabel }
  },
  computed: {
    iValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('change', val)
      }
    },
    options () {
      if (this.storeKey !== undefined) {
        return dot.pick(this.iStoreKey, this.store) ?? []
      } else if (this.items) {
        return this.items
      } else {
        return []
      }
    }
  },
  created () {
    this.$off('change')
  }
}
</script>
