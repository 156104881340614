<template>
  <validation-observer>
    <v-label
      v-if="fixedLabel && displayLabel"
      :dark="fixedLabelDark"
    >
      {{ displayLabel }}
    </v-label>
    <div class="currency-range">
      <div class="currency-range__input mr-3">
        <field-currency
          v-model="gte"
          v-bind="$attrs"
          :default-value="null"
          :label="(!fixedLabel && label || '')"
          :locale="locale"
          prefix="common.range.from"
          :rules="iRules"
          :validation="validation"
          vid="min"
        />
      </div>
      <div class="currency-range__input">
        <field-currency
          v-model="lte"
          v-bind="$attrs"
          :default-value="null"
          :label="(!fixedLabel && label || '')"
          :locale="locale"
          prefix="common.range.to"
          :rules="iRules"
          :validation="validation"
        />
      </div>
    </div>
  </validation-observer>
</template>

<script>
import FieldCurrency from '../FieldCurrency'
import { useValidation } from '@/compositions/validation'
import { ValidationObserver } from 'vee-validate'
import { useAppStore } from '@/stores/app'
import { mapState } from 'pinia'

export default {
  name: 'FieldCurrencyRange',
  components: {
    FieldCurrency,
    ValidationObserver
  },
  props: {
    fixedLabel: Boolean,
    fixedLabelDark: Boolean,
    label: {
      type: String,
      default: null
    },
    rules: {
      type: [String, Object],
      default: () => ({})
    },
    validation: {
      type: Boolean,
      default: false
    },
    vid: {
      type: String,
      default: null
    },
    value: {
      type: Object,
      default: null
    }
  },
  setup (props) {
    const { iRules, displayLabel } = useValidation(props, {
      greater_than_or_equal: '@min'
    })

    return { iRules, displayLabel }
  },
  computed: {
    lte: {
      get () {
        return this.value?.lte
      },
      set (val) {
        this.$emit('input', {
          lte: val,
          gte: this.gte
        })
      }
    },
    gte: {
      get () {
        return this.value?.gte
      },
      set (val) {
        this.$emit('input', {
          lte: this.lte,
          gte: val
        })
      }
    },
    ...mapState(useAppStore, {
      locale: 'locale'
    })
  }
}
</script>

<style lang="scss" scoped>
:deep(.currency-range) {
  display: flex;
  align-content: stretch;
  .currency-range__input {
    width: 100%;
  }
}
</style>
