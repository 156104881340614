import axios from '@/plugins/axios'
import jsonToFormData from '@/services/jsonToFormData'
import EntityTypes from '@/constants/EntityTypes'

const projectsRoute = `${EntityTypes.Project.description.toLowerCase()}s`

export default {
  async addChannel (id, name) {
    return (await axios.post(`${projectsRoute}/${id}/talk-channels/${name}/add-me`)).data
  },
  cancelAllAnswers (projectId, params) {
    const obj = jsonToFormData(params)
    return axios.post(`projects/${projectId}/cancel-all-answers`, obj.data, obj.config)
  },
  async delete (id) {
    return axios.delete(`${projectsRoute}/${id}`)
  },
  finalize (id) {
    return axios.post(`${projectsRoute}/${id}/finalize-internal`)
  },
  async get (id) {
    return (await axios.get(`${projectsRoute}/${id}`)).data
  },
  async getAnswer (id, needId, answerId, params) {
    return (await axios.get(`${projectsRoute}/${id}/needs/${needId}/answers/${answerId}`, { params })).data
  },
  async getAnswers (id, needId, params) {
    return (await axios.get(`${projectsRoute}/${id}/needs/${needId}/answers`, { params })).data
  },
  async getAnswersUsers (id, needId, answerId) {
    return (await axios.get(`${projectsRoute}/${id}/needs/${needId}/answers/${answerId}/users`)).data.data
  },
  async getChannels (id) {
    return (await axios.get(`${projectsRoute}/${id}/talk-channels`)).data
  },
  async getNews (id, params) {
    return (await axios
      .get(`${projectsRoute}/${id}/activities`, { params })).data
  },
  async getUsers (id) {
    return (await axios.get(`${projectsRoute}/${id}/users`)).data.data
  },
  async trash (id, params) {
    const obj = jsonToFormData(params)
    return axios.post(`/projects/${id}/trash`, obj.data, obj.config)
  },
  async untrash (id) {
    return axios.post(`/projects/${id}/untrash`)
  },
  async update (id, params) {
    const updateParams = Object.assign({}, params, {
      withNeeds: 1,
      withAnswers: 1,
      withQuotations: 1
    })
    return (await axios.patch(`${projectsRoute}/${id}`, updateParams)).data
  }
}
