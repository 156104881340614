import axios from 'axios'
import router from '@/router'
import { useAuthStore } from '@/stores/auth'
import Vue from 'vue'

const instance = axios.create({
  baseURL: window.env.VUE_APP_WORKPLACE_CORE_API_URL,
  headers: {
    'content-type': 'application/json; charset=utf-8'
  }
})

const excludedRoutes = [
  {
    uri: '/assets',
    methods: ['get'],
    status: [404]
  },
  {
    uri: '/quotation-settings',
    methods: ['get'],
    status: [404]
  },
  {
    uri: '/talk-channels',
    methods: ['get', 'post'],
    status: [404, 500]
  }
]

function shouldIntercept (error) {
  let intercept = true
  excludedRoutes.forEach(f => {
    const checkUri = error.response.config.url.indexOf(f.uri) !== -1
    const checkMethods = f.methods.length === 0 || f.methods.some(s => s === error.response.config.method)
    const checkStatus = f.status.some(s => s === error.response.status)
    if (checkMethods && checkUri && checkStatus) {
      intercept = false
    }
  })
  return intercept
}

function redirectError (name, message, extra) {
  router.push({
    name,
    params:
    {
      message,
      path: extra?.path,
      error: extra?.error
    }
  })
}

instance.interceptors.request.use(function (config) {
  const authStore = useAuthStore()

  if (Vue.$keycloak.isTokenExpired()) {
    authStore.logout()
    Vue.$keycloak.logout()
  } else if (Vue.$keycloak.token) {
    config.headers.Authorization = `Bearer ${Vue.$keycloak.token}`
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

instance.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response) {
    if (shouldIntercept(error)) {
      const errorStatus = error.response.status
      if (errorStatus === 401) {
        const authStore = useAuthStore()
        authStore.logout()
        Vue.$keycloak.logout()
      } else {
        const errMsg = `error.${error.response.status}`
        switch (errorStatus) {
          case 403:
          case 404:
            redirectError('error', errMsg)
            break
          case 500:
            redirectError('error', errMsg, { error: error.response })
            break
          case 503:
            redirectError('maintenance', errMsg, { path: location.pathname, error: error.response })
            break
        }
      }
    }
    return Promise.reject(error)
  }
})

export default instance
