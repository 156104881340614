<script setup>
import colors from '@/constants/AvatarColors'
import AvatarBadge from './AvatarBadge'
import { watch, ref, computed } from 'vue'
import _ from 'lodash'

const props = defineProps({
  disabled: Boolean,
  bordersStyle: {
    type: Object,
    default: () => ({})
  },
  url: {
    type: String,
    default: null
  },
  size: {
    type: [String, Number],
    default: 'large'
  },
  initials: {
    type: Array,
    default: null
  },
  tile: {
    type: Boolean,
    default: false
  },
  connected: {
    type: Boolean,
    default: null
  },
  rounded: {
    type: Boolean,
    default: false
  },
  local: {
    type: Boolean,
    default: false
  }
})

const error = ref(false)

const connectedSize = computed(() => {
  return {
    width: parseInt(avatarSize.value / 4),
    height: parseInt(avatarSize.value / 4)
  }
})
const avatar = computed(() => {
  if (!props.url && !props.initials) {
    return '/images/default-avatar.jpg'
  } else if (!props.local && props.url) {
    return `${props.url}?width=${props.size}&height=${props.size}&fit=cover`
  } else if (props.local) {
    return props.url
  }
  return null
})
const avatarSize = computed(() => {
  if (typeof props.size === 'number') {
    return props.size
  }
  return props.size === 'large' ? 110 : 85
})

const avatarStyle = computed(() => {
  return {
    ...props.bordersStyle,
    'border-radius': props.rounded ? '3px' : null
  }
})
const firstLetters = computed(() => {
  return props.initials && props.initials.map(n => n.substring(0, 1)).join('')
})
const headline = computed(() => {
  return typeof props.size !== 'number' || props.size >= 85 ? 'headline' : null
})
const color = computed(() => {
  if (props.disabled) {
    return 'grey'
  } else if (error.value || !props.url) {
    const hash = _.reduce(props.initials, (memo, val) =>
      (memo + val.charCodeAt(0)), 0) || 0
    return colors[hash % colors.length]
  }
  return null
})

watch(avatar, (val, prevVal) => {
  if (!_.isEqual(val, prevVal)) {
    error.value = false
  }
})
</script>

<template>
  <div class="avatar">
    <v-avatar
      :color="color"
      :size="avatarSize"
      :tile="tile"
      :title="$attrs.title"
      :style="avatarStyle"
    >
      <slot v-if="avatar && !error">
        <v-img
          :src="avatar"
          :height="avatarSize"
          :width="avatarSize"
          @error="error = true"
        />
      </slot>
      <span
        v-else
        :class="['white--text', headline]"
      >
        {{ firstLetters | uppercase }}
      </span>
    </v-avatar>
    <avatar-badge
      v-if="connected !== null"
      :width="`${connectedSize.width}px`"
      :height="`${connectedSize.height}px`"
      :connected="connected"
    />
  </div>
</template>

<style lang="scss" scoped>
.avatar {
  position: relative;
}
</style>
