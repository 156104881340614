<template>
  <div>
    <reset-filters
      v-if="isFilterable"
      :disabled="!hasFilters"
      @click="resetFilters"
    />
    <v-data-table
      v-bind="$attrs"
      :headers="headers"
      :items="items"
      :items-per-page.sync="itemsPerPage"
      :options="options"
      :loading="loading"
      :page="page"
      :server-items-length="totalItems"
      :class="{ 'custom-table': true, 'mt-5': true, 'elevation-1': !flat }"
      :show-expand="showExpand"
      hide-default-footer
      @item-expanded="$emit('item-expanded', $event)"
      @update:options="updateOptions"
      @update:page="updatePage"
      v-on="$listeners"
    >
      <template #header.data-table-select="props">
        <slot
          name="header.data-table-select"
          v-bind="props"
        />
      </template>
      <template
        v-for="headerSlot in headerSlots"
        #[headerSlot]="props"
      >
        {{ $t(props.header.text) }}
        <filter-menu
          v-if="props.header.filterable"
          :key="headerSlot"
          :value="menus[getSlotKey(headerSlot, 'header')]"
          :filter="filters[getSlotKey(headerSlot, 'header')]"
          @input="updateMenu(getSlotKey(headerSlot, 'header'), $event)"
        >
          <slot
            v-bind="props"
            :name="headerSlot"
            :value="filters[getSlotKey(headerSlot, 'header')]"
            :on="getEvents(headerSlot)"
          />
        </filter-menu>
      </template>
      <template
        v-for="slot in itemSlots"
        #[slot]="props"
      >
        <slot
          v-bind="props"
          :name="slot"
        />
      </template>
      <template #expanded-item="props">
        <slot
          name="expanded-item"
          v-bind="props"
        />
      </template>
    </v-data-table>
    <pagination
      v-if="pagination"
      v-model="page"
      :length="lastPage"
      :items-per-page="itemsPerPage"
      @items-per-page="itemsPerPage = $event"
    />
  </div>
</template>

<script>
import FilterMenu from './FilterMenu'
import ResetFilters from './ResetFilters'
import Pagination from '@/components/Pagination'
import { useMessages } from '@/compositions/messages'
import _ from 'lodash'

export default {
  name: 'DataTable',
  components: {
    FilterMenu,
    ResetFilters,
    Pagination
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    pagination: {
      type: Boolean,
      default: false
    },
    remote: {
      type: Object,
      required: true
    },
    headers: {
      type: Array,
      default: () => []
    },
    filters: {
      type: Object,
      default: () => ({})
    },
    flat: Boolean,
    orderBy: {
      type: Object,
      default: null
    },
    showExpand: Boolean
  },
  setup () {
    const { errorMessage } = useMessages()
    return { errorMessage }
  },
  data () {
    return {
      items: [],
      itemsPerPage: window.env.VUE_APP_ITEMS_PER_PAGE,
      lastPage: 1,
      menus: {},
      options: {
        sortBy: this.orderBy?.column ? [this.orderBy.column] : [],
        sortDesc: this.orderBy?.direction ? [this.orderBy?.direction === 'desc'] : []
      },
      page: 1,
      totalItems: 0
    }
  },
  computed: {
    itemSlots () {
      return Object.keys(this.$scopedSlots).filter(f =>
        f.startsWith('item.'))
    },
    isFilterable () {
      return this.headers?.some(s => s.filterable)
    },
    hasFilters () {
      return !!Object.keys(this.filters).length
    },
    headerSlots () {
      return Object.keys(this.$scopedSlots).filter(f =>
        f.startsWith('header.') && f !== 'header.data-table-select')
    }
  },
  watch: {
    itemsPerPage () {
      this.page = 1
      this.fetch()
    },
    totalItems (val) {
      this.$emit('update:total-items', val)
    },
    orderBy: {
      deep: true,
      handler (val) {
        const opts = { ...this.options }
        if (val?.column) {
          opts.sortBy = [val.column]
        }
        if (val?.direction) {
          opts.sortDesc = [val.direction === 'desc']
        }
        this.options = opts
      }
    }
  },
  methods: {
    buildFilterObject (params, f) {
      Object.keys(this.filters[f]).forEach(filter => {
        if (this.filters[f][filter]) {
          params[`${f}[${filter}]`] = this.filters[f][filter]
        }
      })
    },
    buildFilters (params) {
      if (this.filters) {
        Object.keys(this.filters).forEach(f => {
          if (this.filters[f]) {
            if (typeof this.filters[f] === 'object') {
              this.buildFilterObject(params, f)
            } else {
              params[f] = this.filters[f]
            }
          }
        })
      }
    },
    buildOrderBy (params) {
      if (this.options?.sortBy?.length) {
        const header = this.headers.find(f => f.value === this.options.sortBy[0])
        const sort = header?.sortable
        params['orderBy[column]'] = (sort && typeof sort !== 'boolean')
          ? sort
          : this.options.sortBy[0]
      }
      if (this.options?.sortDesc?.length) {
        params['orderBy[direction]'] = this.options.sortDesc[0] === true ? 'desc' : 'asc'
      }
    },
    async fetch () {
      const params = {
        page: this.page,
        resultsPerPage: this.itemsPerPage,
        ...this.remote.params
      }

      this.buildFilters(params)
      this.buildOrderBy(params)
      this.$emit('update:loading', true)
      try {
        const resp = await this.remote.callback(params)

        this.items = [...resp.data.data]
        this.totalItems = resp.data.meta?.total ?? resp.data.data.length
        this.lastPage = resp.data.meta?.last_page ?? 1
        this.page = resp.data.meta?.current_page ?? 1

        if (this.showExpand && this.items.length) {
          this.$emit('update:expanded', [this.items[0]])
        }
      } catch {
        this.errorMessage('common.error.base')
      } finally {
        this.$emit('update:loading', false)
      }
    },
    getEvents (slot) {
      const key = this.getSlotKey(slot, 'header')
      const cb = e => this.updateFilter(key, e)

      return {
        input: cb,
        change: cb
      }
    },
    getSlotKey (slot, slotType) {
      return slot.replace(slotType + '.', '') ?? null
    },
    resetFilters () {
      this.$emit('update:filters', {})
      this.$nextTick(() => {
        this.$emit('reset-filters')
      })
    },
    updateOrderBy (options) {
      if (options?.sortBy?.length) {
        const sortBy = options?.sortBy[0] ?? null
        const dir = options?.sortDesc[0] === true ? 'desc' : 'asc'
        const sortable = sortBy ? this.headers?.find(f =>
          f.value === sortBy)?.sortable : false
        const newSort = {
          column: this.sortable !== true ? sortBy : sortable ?? null,
          direction: options?.sortDesc.length ? dir : null
        }
        if (!_.isEqual(newSort, this.sortBy)) {
          this.$emit('update:order-by', {
            column: this.sortable !== true ? sortBy : sortable ?? null,
            direction: this.sortable ?? options?.sortDesc.length ? dir : null
          })
        }
      } else {
        this.$emit('update:order-by', null)
      }
    },
    updateFilter (key, val) {
      if (Array.isArray(this.filters[key])) {
        this.$emit('update:filters', { ...this.filters, [key]: [...val] })
      } else if (this.filters[key] !== val) {
        if (val && typeof val === 'object') {
          const newVal = Object.fromEntries(Object.entries(val).filter(([_k, v]) => v))
          this.$emit('update:filters', {
            ...this.filters,
            [key]: Object.keys(newVal).length ? newVal : null
          })
        } else {
          this.$emit('update:filters', { ...this.filters, [key]: val })
        }
      }
    },
    updateMenu (key, val) {
      this.menus = { ...this.menus, [key]: val }
      if (!val) {
        this.$emit('close:filter', key)
      }
    },
    updateOptions (options) {
      this.options = { ...options }
      this.updateOrderBy(options)
      this.fetch()
    },
    updatePage (page) {
      this.page = page
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.custom-table) {
  tr:hover {
    background-color: transparent !important;
  }

  a {
    text-decoration: none;
  }

  th.sortable {
    i.v-icon {
      margin-left: 5px;
    }
  }
}
</style>
