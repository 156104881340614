import { render, staticRenderFns } from "./Note.vue?vue&type=template&id=53c43e8e&scoped=true&"
import script from "./Note.vue?vue&type=script&setup=true&lang=js&"
export * from "./Note.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./Note.vue?vue&type=style&index=0&id=53c43e8e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53c43e8e",
  null
  
)

export default component.exports