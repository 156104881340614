import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { configure } from 'vee-validate'
import getUserLocale from '@/services/getUserLocale'

Vue.use(VueI18n)

const localeLocalStorage = JSON.parse(localStorage?.getItem('auth'))?.locale ?? null
const messages = JSON.parse(localStorage?.getItem('translation'))?.messages ?? null

const i18n = new VueI18n({
  dateTimeFormats: {
    'en-GB': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }
    },
    'fr-FR': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }
    }
  },
  fallbackLocale: window.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en-GB',
  locale: localeLocalStorage || getUserLocale() || window.env.VUE_APP_I18N_LOCALE || 'fr-FR',
  silentTranslationWarn: true,
  messages
})

configure({
  defaultMessage: (field, values) => {
    return i18n.t(`validation.${values._rule_}`, values)
  }
})

export default i18n
