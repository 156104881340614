<template>
  <validation-provider
    v-if="countries"
    v-slot="{ errors }"
    :name="$attrs.name"
    :rules="iRules"
    :vid="vid"
  >
    <v-label
      v-if="fixedLabel"
      :dark="fixedLabelDark"
    >
      {{ displayLabel }}
    </v-label>
    <v-select
      v-model="country"
      v-bind="$attrs"
      :background-color="backgroundColor"
      :error-messages="errors"
      :error="validation && !!errors.length"
      :hide-details="!validation || !errors.length"
      :items="countries"
      :label="(!fixedLabel && displayLabel) || ''"
      :outlined="outlined"
      :prefix="$t(prefix)"
    >
      <template #item="{ item }">
        <country-flag
          :country="getCountryCode(item)"
          size="small"
        />
        <span class="ml-2">{{ $t(item.text) }}</span>
      </template>
      <template #selection="{ item }">
        <country-flag
          :country="getCountryCode(item)"
          size="small"
        />
        <span
          v-if="showSelectionText"
          class="ml-2"
        >
          {{ $t(item.text) }}
        </span>
      </template>
    </v-select>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { useValidation } from '@/compositions/validation'
import CountryFlag from 'vue-country-flag'
import Vue from 'vue'

Vue.component('CountryFlag', CountryFlag)

export default {
  name: 'FieldCountry',
  components: {
    ValidationProvider
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'white'
    },
    countries: {
      type: Array,
      default: null
    },
    fixedLabel: Boolean,
    fixedLabelDark: Boolean,
    label: {
      type: String,
      default: null
    },
    outlined: {
      type: Boolean,
      default: true
    },
    prefix: {
      type: String,
      default: null
    },
    rules: {
      type: [String, Object],
      default: () => ({})
    },
    showSelectionText: {
      type: Boolean,
      default: true
    },
    validation: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: null
    },
    vid: {
      type: String,
      default: null
    }
  },
  setup (props) {
    const { iRules, displayLabel } = useValidation(props)

    return { iRules, displayLabel }
  },
  computed: {
    country: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    getCountryCode (country) {
      return country?.value?.split('-')[1] ?? null
    }
  }
}
</script>
