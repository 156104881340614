import axios from '../../plugins/axios'

export default {
  get (params) {
    return axios.get('profiles', {
      params
    })
  },
  remove (profileId) {
    return axios
      .delete(`profiles/${profileId}`)
  },
  create ({ slug, type }) {
    return axios
      .post('profiles', {
        slug,
        type
      })
  },
  getFacilitators () {
    return axios
      .get('profiles?types[]=facilitator')
  },
  getRights (profileId) {
    return axios
      .get(`profiles/${profileId}/ref-rights`)
  },
  setRights (profileId, data) {
    return axios
      .patch(`profiles/${profileId}`, {
        refRights: data
      })
  }
}
