import { computed } from 'vue'

export function useValidation (props, customRules) {
  const componentRules = computed(() => {
    if (typeof customRules === 'string') {
      const arrayRules = customRules?.split('|') ?? []
      return arrayRules
        .filter(rule => rule.trim() !== '')
        .reduce((accumulator, rule) => {
          const [key, value] = rule.split(':')
          const parsedRule = { [key]: value !== undefined ? value : true }
          return { ...accumulator, ...parsedRule }
        }, {})
    }
    return customRules
  })
  const isRequired = computed(() => iRules.value.required ?? false)
  const iRules = computed(() => (props.validation ? {
    ...componentRules.value,
    ...parentRules.value
  } : {}))
  const parentRules = computed(() => {
    if (typeof props.rules === 'string') {
      const arrayRules = props.rules?.split('|') ?? []
      return arrayRules
        .filter(rule => rule.trim() !== '')
        .reduce((accumulator, rule) => {
          const [key, value] = rule.split(':')
          const parsedRule = { [key]: value !== undefined ? value : true }
          return { ...accumulator, ...parsedRule }
        }, {})
    }
    return props.rules
  })
  const displayLabel = computed(() => props.label ? `${props.label}${isRequired.value ? '*' : ''}` : undefined)

  return {
    displayLabel,
    iRules,
    isRequired
  }
}
