export default {
  active: 'blue',
  inactive: 'grey',
  pending: 'orange',
  'in-progress': 'blue',
  won: 'green',
  lost: 'red',
  ready: 'green',
  failed: 'red'
}
