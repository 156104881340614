<template>
  <data-table
    ref="datatable"
    v-bind="$attrs"
    :headers="headers"
    :remote="remote"
    :loading.sync="loading"
    :filters="filters"
    show-select
    @update:total-items="$emit('update:total-items', $event)"
    @update:filters="$emit('update:filters', $event)"
    @update:order-by="$emit('update:order-by', $event)"
    @close:filter="closeFilter"
    @reset-filters="resetFilters"
  >
    <template #header.data-table-select />
    <template #header.id="{ header, value, on }">
      <field-integer
        :value="parseInt(value)"
        :label="$t(header.text)"
        :outlined="false"
        autocomplete="off"
        clearable
        v-on="on"
      />
    </template>
    <template #header.typeId="{ header, value, on }">
      <field-list
        :value="parseInt(value)"
        :label="$t(header.text)"
        :outlined="false"
        :items="taskTypes"
        :item-text="item => $t(item.value)"
        item-value="id"
        clearable
        v-on="on"
      />
    </template>
    <template #header.refStatusId="{ header, value, on }">
      <field-list
        :value="parseInt(value)"
        :label="$t(header.text)"
        :outlined="false"
        :items="taskStatuses"
        :item-text="i => $t(`ref-status.${i.slug}`)"
        item-value="id"
        clearable
        v-on="on"
      />
    </template>
    <template #header.title="{ header, value, on }">
      <field-string
        :value="value"
        :label="$t(header.text)"
        :outlined="false"
        autocomplete="off"
        clearable
        v-on="on"
      />
    </template>
    <template #header.dueDate="{ header, value, on }">
      <field-date-range
        :value="value"
        :label="$t(header.text)"
        :outlined="false"
        autocomplete="off"
        clearable
        v-on="on"
      />
    </template>
    <template #header.refTaskPriorityId="{ header, value, on }">
      <field-list
        :value="parseInt(value)"
        :label="$t(header.text)"
        :outlined="false"
        :items="taskPriorities"
        :item-text="i => $t(`priority.${i.slug}`)"
        item-value="id"
        clearable
        v-on="on"
      />
    </template>
    <template #header.ownerUserId="{ value, on }">
      <v-list v-if="owners.length">
        <v-list-item
          v-for="item in owners"
          :key="item.id"
        >
          <v-checkbox
            :value="item.id"
            :input-value="value"
            :ripple="false"
            v-on="on"
          />
          {{ item.displayName }}
        </v-list-item>
      </v-list>
      <span v-else>{{ $t('common.no-result') }}</span>
    </template>
    <template #header.performerUserId="{ value, on }">
      <v-list v-if="performers.length">
        <v-list-item
          v-for="item in performers"
          :key="item.id"
        >
          <v-checkbox
            :value="item.id"
            :input-value="value"
            :ripple="false"
            v-on="on"
          />
          {{ item.displayName }}
        </v-list-item>
      </v-list>
      <div
        v-else
        class="pa-5"
      >
        {{ $t('common.no-result') }}
      </div>
    </template>
    <template #header.entities="{ header }">
      {{ $t(header.text) }}
    </template>
    <template #header.actions="{ header }">
      {{ $t(header.text) }}
    </template>
    <template #item.data-table-select="{ item }">
      <v-tooltip right>
        <template #activator="{ on, attrs }">
          <v-simple-checkbox
            v-bind="attrs"
            :ripple="false"
            :input-value="item.refStatusId === idStatusClosed"
            :value="item.refStatusId === idStatusClosed"
            off-icon="far fa-circle"
            on-icon="far fa-check-circle"
            v-on="on"
            @input="completeTask($event, item.id)"
          />
        </template>
        <span>{{ $t('task.manager.mark-completed') }}</span>
      </v-tooltip>
    </template>
    <template #item.refStatusId="{ item }">
      {{ $t(getRefStatusLabel(item.refStatusId)) }}
    </template>
    <template #item.typeId="{ item }">
      {{ $t(getTaskTypeLabel(item)) }}
    </template>
    <template #item.title="{ item }">
      <a @click="$emit('edit-item', item)">
        {{ item.title }}
      </a>
    </template>
    <template #item.refTaskPriorityId="{ item }">
      <btn-dropdown-priority
        :disabled="loading || !canChangePriority(item)"
        :value="item.refTaskPriorityId"
        @input="changePriority(item, $event)"
      />
    </template>
    <template #item.ownerUserId="{ item }">
      <v-chip
        label
        color="white"
      >
        {{ getOwnerDisplayName(item) }}
      </v-chip>
    </template>
    <template #item.performerUserId="{ item }">
      <v-chip
        label
        color="white"
      >
        {{ getPerformerDisplayName(item) }}
      </v-chip>
    </template>
    <template #item.dueDate="{ item }">
      <btn-due-date
        v-model="menusDate[item.id]"
        :date="item.dueDate"
        @update:date="changeDate(item, $event)"
      />
    </template>
    <template #item.entities="{ item }">
      <v-chip
        v-for="entity in item.entities"
        :key="entity.id"
        class="ma-1"
        label
        small
      >
        <RouterLink :to="getEntityLink(entity)">
          {{ entity.entityName }}
        </RouterLink>
      </v-chip>
    </template>
    <template #item.actions="{ item }">
      <action-delete
        v-bind="item"
        :id-user="item.ownerUserProfile.user.id"
        @delete="deleteTask"
      />
    </template>
  </data-table>
</template>

<script>
import EntityTypes from '@/constants/EntityTypes'
import TaskFetcher from '@/services/fetchers/Tasks'
import profiles from '@/services/profiles'
import ActionDelete from './ActionDelete'
import BtnDueDate from '@/components/Buttons/BtnDueDate'
import BtnDropdownPriority from '@/components/Buttons/BtnDropdownPriority'
import DataTable from '@/components/DataTables/DataTable'
import { useMessages } from '@/compositions/messages'
import { useUniverseStore } from '@/stores/universe'
import { useAuthStore } from '@/stores/auth'
import { useUserStore } from '@/stores/user'
import { useRefStore } from '@/stores/ref'
import { RouterLink } from 'vue-router'
import { mapState } from 'pinia'
import _ from 'lodash'

export default {
  name: 'DataTableTask',
  components: {
    ActionDelete,
    BtnDueDate,
    BtnDropdownPriority,
    DataTable,
    RouterLink
  },
  props: {
    headers: {
      type: Array,
      required: true
    },
    filters: {
      type: Object,
      default: () => ({})
    },
    remote: {
      type: Object,
      required: true
    }
  },
  setup () {
    const { errorMessage, successMessage } = useMessages()
    return { errorMessage, successMessage }
  },
  data () {
    return {
      loading: false,
      menusDate: [],
      owners: [],
      performers: [],
      filtersChanged: false
    }
  },
  computed: {
    idStatusClosed () {
      return this.getRefs.refStatuses?.find(f =>
        f.slug === 'closed')?.id ?? null
    },
    idStatusInProgress () {
      return this.getRefs.refStatuses?.find(f =>
        f.slug === 'in-progress')?.id ?? null
    },
    taskPriorities () {
      return this.getRefs?.refTaskPriorities ?? []
    },
    taskStatuses () {
      return this.getRefs?.refStatuses?.filter(f =>
        ['in-progress', 'closed'].includes(f.slug))
    },
    taskTypes () {
      return this.getDictionary('task-type') ?? []
    },
    ...mapState(useUserStore, {
      rights: 'getRights'
    }),
    ...mapState(useAuthStore, {
      user: 'getUser'
    }),
    ...mapState(useRefStore, {
      getRefs: 'getRefs'
    }),
    ...mapState(useUniverseStore, {
      getDictionary: 'getDictionary'
    })
  },
  watch: {
    filters: {
      deep: true,
      handler (val, oldVal) {
        this.filtersChanged = !_.isEqual(val, oldVal)
      }
    }
  },
  async created () {
    this.performers = (await TaskFetcher.performers()).data.data
    this.owners = (await TaskFetcher.owners()).data.data
  },
  methods: {
    canChangePriority (item) {
      return item.ownerUserProfile?.user?.id === this.user?.id || this.rights.task.update
    },
    changeDate (item, date) {
      this.updateTask({
        id: item.id,
        dueDate: date
      })
    },
    changePriority (item, refTaskPriorityId) {
      this.updateTask({
        id: item.id,
        refTaskPriorityId
      })
    },
    async closeFilter (e) {
      this.$emit('close:filter', {
        changed: this.filtersChanged,
        key: e
      })
      if (this.filtersChanged) {
        await this.$refs.datatable.fetch()
        this.filtersChanged = false
      }
    },
    completeTask (completed, id) {
      this.updateTask({
        id,
        refStatusId: completed ? this.idStatusClosed : this.idStatusInProgress
      })
    },
    async deleteTask (id) {
      try {
        this.loading = true
        await TaskFetcher.delete(id)
        this.$refs.datatable.fetch()
        this.successMessage('common.delete.success')
      } catch {
        this.errorMessage('common.save.error')
      } finally {
        this.loading = false
      }
    },
    getDisplayName (key, item) {
      return item[key].user.id === this.user.id
        ? this.$t('common.me') : item[key].user.displayName
    },
    getEntityLink (entity) {
      const entityType = entity.entityType
      const entityRights = this.rights[entity.entityType.toLowerCase()]
      const canRead = entityRights?.read ?? false

      let uri = null
      if (canRead) {
        if (entityType === EntityTypes.Project.description) {
          uri = `/project/${entity.entityId}`
        } else if (entityType === EntityTypes.Answer.description) {
          if (profiles.userIsRetailer()) {
            uri = `/answer/${entity.entityId}`
          } else {
            const { projectId, needId, entityId } = entity
            uri = `/project/${projectId}/need/${needId}/answer/${entityId}`
          }
        } else if (entityType === EntityTypes.Product.description) {
          uri = `/product/${entity.entityId}`
        } else if (entityType === EntityTypes.Company.description) {
          uri = `/retailer/${entity.entityId}`
        }
      }
      return uri
    },
    getOwnerDisplayName (item) {
      return this.getDisplayName('ownerUserProfile', item)
    },
    getPerformerDisplayName (item) {
      return this.getDisplayName('performerUserProfile', item)
    },
    getRefStatusLabel (id) {
      const slug = this.getRefs?.refStatuses?.find(f => f.id === id)?.slug ?? null
      return slug ? `ref-status.${slug}` : null
    },
    getTaskTypeLabel (item) {
      return this.taskTypes.find(f => f.id === item.typeId).value
    },
    resetFilters () {
      this.$emit('reset-filters')
    },
    async updateTask (task) {
      try {
        this.loading = true
        await TaskFetcher.update(task)
        this.$refs.datatable.fetch()
        this.successMessage('common.save.success')
      } catch {
        this.errorMessage('common.save.error')
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
