import i18n from '@/plugins/vue-i18n'

export default {
  params: ['length'],
  message: (_field, { length }) => i18n.t('validation.min.string', {
    placeholder_1: length
  }),
  validate (value, { length }) {
    return value.length >= length
  }
}
