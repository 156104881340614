import TaskFetcher from '@/services/fetchers/Tasks'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useTaskEditorStore = defineStore('taskEditor', () => {
  const entities = ref(null)
  const task = ref(null)
  const dialog = ref(false)
  const saving = ref(false)
  const saved = ref(false)

  const isEditorVisible = computed(() => dialog.value)
  const isSaved = computed(() => saved.value)
  const isSaving = computed(() => saving.value)
  const getEntities = computed(() => entities.value)
  const getTask = computed(() => task.value)

  function hideDialog () {
    dialog.value = false
    task.value = null
    entities.value = null
  }
  async function saveTask (payload) {
    saving.value = true
    const p = payload?.id ? TaskFetcher.update : TaskFetcher.create
    try {
      const newTask = (await p(payload)).data.data
      task.value = payload?.id ? newTask : null
      saved.value = true
      setTimeout(() => {
        saved.value = false
      }, 1000)
    } catch (e) {
      throw new Error(e)
    } finally {
      saving.value = false
    }
  }
  function showDialog (payload) {
    task.value = payload.task
    entities.value = payload.entities
    dialog.value = true
  }

  return {
    entities,
    task,
    dialog,
    saving,
    saved,

    isEditorVisible,
    isSaved,
    isSaving,
    getEntities,
    getTask,

    hideDialog,
    saveTask,
    showDialog
  }
})

if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(acceptHMRUpdate(useTaskEditorStore, import.meta.webpackHot))
}
