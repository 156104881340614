import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useNoteStore = defineStore('note', () => {
  const emittedNote = ref(null)
  const noteObject = ref(null)
  const showNote = ref(false)

  const getEmittedNote = computed(() => emittedNote.value)
  const getNote = computed(() => noteObject.value)
  const getShowNote = computed(() => showNote.value)

  function clear () {
    showNote.value = false
    noteObject.value = null
    emittedNote.value = null
  }
  function setContent (payload) {
    noteObject.value = payload
  }
  function setEmittedNote (payload) {
    emittedNote.value = payload
  }
  function setShowNote (payload) {
    showNote.value = payload
  }

  return {
    emittedNote,
    noteObject,
    showNote,

    getEmittedNote,
    getNote,
    getShowNote,

    clear,
    setContent,
    setEmittedNote,
    setShowNote
  }
})

if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(acceptHMRUpdate(useNoteStore, import.meta.webpackHot))
}
