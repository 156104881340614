<script setup>
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

const props = defineProps({
  title: {
    type: String,
    default: null
  },
  subtitle: {
    type: String,
    default: null
  },
  avatar: {
    type: String,
    default: null
  },
  initials: {
    type: Array,
    default: null
  },
  description: {
    type: String,
    default: null
  },
  refNotificationType: {
    type: String,
    default: null
  },
  secondSubtitle: {
    type: String,
    default: null
  },
  seeMoreLink: {
    type: String,
    default: null
  },
  noSeeMoreLink: {
    type: Boolean,
    default: false
  },
  unread: {
    type: Boolean,
    default: true
  },
  date: {
    type: String,
    default: null
  },
  path: {
    type: String,
    default: null
  },
  statusType: {
    type: String,
    default: null
  },
  message: {
    type: String,
    default: null
  },
  senderId: {
    type: Number,
    default: null
  },
  type: {
    type: String,
    default: null
  }
})

const userStore = useUserStore()
const { getConnectedUserById } = storeToRefs(userStore)

const ctaLabel = computed(() => {
  if (props.type === 'admin') {
    return !props.seeMoreLink ? 'label.notification.see' : props.seeMoreLink
  } else if (props.type === 'project') {
    return 'label.project.see'
  }
  return 'label.answer.see'
})

function isPresent () {
  return props.senderId && !!getConnectedUserById.value(props.senderId)
}
</script>

<template>
  <div>
    <v-row>
      <v-col class="notif__avatar mr-3">
        <avatar
          :size="50"
          :url="avatar"
          :initials="initials"
          :connected="isPresent()"
        />
      </v-col>
      <v-col class="notif__head font-weight-regular">
        <div>{{ title }}</div>
        <div>{{ subtitle }}</div>
        <div v-html="secondSubtitle" />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="notif__content pt-1">
        <div
          class="font-weight-medium mb-1"
          :class="{
            'primary--text': statusType === 'default',
            'success--text': statusType === 'success' || statusType === 'action',
            'error--text': statusType === 'error'
          }"
          v-html="$t(message)"
        />
        <v-row class="d-flex justify-space-between align-end">
          <v-col cols="8">
            <div class="notif__content__date font-weight-regular grey--text text--darken-2 mb-1">
              {{ date }}
            </div>
            <div
              class="font-weight-regular"
              v-html="description"
            />
          </v-col>
          <v-col
            class="d-flex justify-end align-end"
            cols="4"
          >
            <v-btn
              v-if="path && !noSeeMoreLink"
              color="primary"
              :href="path"
              @click.prevent.stop="$emit('click')"
            >
              {{ $t(ctaLabel) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
