import { useUniverseStore } from '@/stores/universe'
import { useAuthStore } from '@/stores/auth'
import { storeToRefs } from 'pinia'

export default class {
  static async getComponentByUniverse (component) {
    const authStore = useAuthStore()
    const universeStore = useUniverseStore()

    const { user } = storeToRefs(authStore)
    const { getSettingByPath } = storeToRefs(universeStore)

    if (user.value === null) {
      throw new Error()
    }
    try {
      const universeLabel = getSettingByPath.value('theme.universeSlug', null)
      return await import(`@/overloads/${universeLabel}/${component}`)
    } catch (ex) {
      try {
        return await import(`@/${component}`)
      } catch {
        return await import('@/components/Default')
      }
    }
  }
}
