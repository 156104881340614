import Vue from 'vue'
import VueRouter from 'vue-router'
import qs from 'qs'
import translations from '@/services/translations'
import ComponentByUniverse from '@/services/ComponentByUniverse'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = async function push (location) {
  try {
    await originalPush.call(this, location)
  } catch (err) {
    if (location.path) {
      window.location.href = location.path
    }
  }
}

const SetProfile = () => import('@/views/SetProfile')
const SetUniverse = () => import('@/views/SetUniverse')

const Account = () => import('@/views/Account')
const AccountNotifications = () => import('@/views/Account/AccountNotifications')
const AccountAutomaticMessages = () => import('@/views/Account/AccountAutomaticMessages')

const Dashboard = () => import('@/views/Dashboard')
const Redirect = () => import('@/views/Redirect')

const Answer = () => import('@/views/Answer')
const Answers = () => import('@/views/Answers')
const ProjectAnswer = () => import('@/views/ProjectAnswer')
const Quotation = () => import('@/views/Quotation')

const Project = () => import('@/views/Project')
const Projects = () => import('@/views/Projects')
const Products = () => import('@/views/Products')
const Mailbox = () => import('@/views/Mailbox')
const Favorites = () => import('@/views/Favorites')

const Teams = () => import('@/views/Teams')
const Facilitators = () => import('@/views/Facilitators')
const Collaborators = () => import('@/views/Collaborators')
const Applicants = () => import('@/views/Applicants')
const RetailerExports = () => import('@/views/RetailerExports')
const Retailers = () => import('@/views/Retailers')
const QuotationSettings = () => import('@/views/QuotationSettings')
const CompanyRetailer = () => import('@/views/CompanyRetailer')
const Feedbacks = () => import('@/views/Feedbacks')
const ExpertSearch = () => import('@/views/ExpertSearch')

const Product = () => import('@/views/Product')
const ProductDescription = () => import('@/views/Product/Description')
const ProductDocuments = () => import('@/views/Product/Documents')
const ProductAttribution = () => import('@/views/Product/Attribution')
const ProductComments = () => import('@/views/Product/Comments')
const ProductBooklet = () => import('@/views/Product/Booklet')
const ProductNews = () => import('@/views/Product/News')
const ProductHistory = () => import('@/views/Product/History')
const ProductVisbilities = () => import('@/views/Product/Visibilities')

const Options = () => import('@/views/Options')
const OptionsCarousels = () => import('@/views/Options/Carousels')
const OptionsFavorites = () => import('@/views/Options/Favorites')
const OptionsResultHeaders = () => import('@/views/Options/ResultHeaders')
const OptionsCrossSelling = () => import('@/views/Options/CrossSelling')
const OptionsHistory = () => import('@/views/Options/History')

const TeamDialog = () => ComponentByUniverse.getComponentByUniverse('components/Dialogs/DialogTeam')
const FacilitatorDialog = () => import('@/components/Dialogs/DialogFacilitator')
const RetailerDialog = () => ComponentByUniverse.getComponentByUniverse('components/Dialogs/DialogRetailer')
const ApplicantDialog = () => import('@/components/Dialogs/DialogApplicant')
const CollaboratorDialog = () => import('@/components/Dialogs/DialogCollaborator')

const Tasks = () => import('@/views/Tasks')
const TaskDialog = () => import('@/views/Tasks/Dialog')

const Contract = () => import('@/views/Contract')
const Content = () => import('@/views/Content')
const Error = () => import('@/views/Error')
const Maintenance = () => import('@/views/Maintenance')

const AdminExports = () => import('@/views/Admin/Exports')
const AdminSettings = () => import('@/views/Admin/Settings')
const AdminTheming = () => import('@/views/Admin/Settings/Theming')
const AdminReference = () => import('@/views/Admin/Settings/Reference')
const AdminPimAttribute = () => import('@/views/Admin/Settings/PimAttribute')
const AdminProfiles = () => import('@/views/Admin/Settings/Profiles')
const AdminSetting = () => import('@/views/Admin/Settings/Setting')
const AdminFooter = () => import('@/views/Admin/Settings/Footer')
const AdminMedia = () => import('@/views/Admin/Settings/Media')
const AdminInsert = () => import('@/views/Admin/Settings/Insert')
const AdminTranslation = () => import('@/views/Admin/Translation')
const ValidationRules = () => import('@/views/Admin/Translation/ValidationRules')
const AdminFieldTester = () => import('@/views/Admin/FieldTester')

Vue.use(VueRouter)

const routes = [
  {
    path: '/user/set-universe',
    name: 'user-set-universe',
    component: SetUniverse
  },
  {
    path: '/user/set-profile',
    name: 'user-set-profile',
    component: SetProfile
  },
  {
    path: '',
    name: 'dashboard',
    component: Dashboard
  },
  {
    path: '/account',
    name: 'account',
    component: Account
  },
  {
    path: '/contract',
    name: 'contract',
    component: Contract
  },
  {
    path: '/content/homepage-visual',
    name: 'content-homepage-visual',
    component: Content
  },
  {
    path: '/account/automatic-messages',
    name: 'account-automatic-messages',
    component: AccountAutomaticMessages
  },
  {
    path: '/account/notifications',
    name: 'account-notifications',
    component: AccountNotifications
  }, {
    path: '/redirect',
    name: 'redirect',
    component: Redirect
  }, {
    path: '/quotation/setting',
    name: 'my-quotation-setting',
    component: QuotationSettings
  }, {
    path: '/quotation/setting/:companyId',
    name: 'quotation-setting',
    component: QuotationSettings,
    props: route => ({
      companyId: parseInt(route.params.companyId)
    })
  },
  {
    path: '/project/list',
    name: 'project-list',
    component: Projects
  },
  {
    path: '/answer/list',
    name: 'answer-list',
    component: Answers
  },
  {
    path: '/answer/:answerId',
    name: 'answer',
    props: route => ({
      answerId: parseInt(route.params.answerId)
    }),
    component: Answer
  },
  {
    path: '/answer/:answerId/quotation',
    name: 'quotation',
    component: Quotation,
    meta: { layout: 'empty' },
    props: route => ({
      answerId: parseInt(route.params.answerId)
    })
  },
  {
    path: '/project/:projectId/need/:needId/type/:needTypeId/expert-search',
    name: 'expert-search',
    component: ExpertSearch
  },
  {
    path: '/project/:projectId/need/:needId/answer/:answerId',
    component: ProjectAnswer,
    props: route => ({
      answerId: parseInt(route.params.answerId)
    })
  },
  {
    path: '/project/:projectId',
    name: 'project',
    component: Project
  },
  {
    path: '/team',
    component: Teams,
    children: [
      {
        path: '/team/list',
        name: 'team-list',
        component: Teams
      },
      {
        path: '/team/create',
        name: 'team-create',
        components: {
          default: Teams,
          dialog: TeamDialog
        }
      },
      {
        path: ':id',
        name: 'team-detail',
        components: {
          default: Teams,
          dialog: TeamDialog
        }
      }
    ]
  },
  {
    path: '/applicant',
    component: Applicants,
    children: [
      {
        path: '/applicant/list',
        name: 'applicant-list',
        component: Applicants
      },
      {
        path: '/applicant/create',
        name: 'applicant-create',
        components: {
          default: Applicants,
          dialog: ApplicantDialog
        }
      },
      {
        path: ':id/:userProfileId',
        name: 'applicant-detail',
        components: {
          default: Applicants,
          dialog: ApplicantDialog
        }
      }
    ]
  },
  {
    path: '/collaborator',
    component: Collaborators,
    children: [
      {
        path: '/collaborator/list',
        name: 'collaborator-list',
        component: Collaborators
      },
      {
        path: '/collaborator/create',
        name: 'collaborator-create',
        components:
        {
          default: Collaborators,
          dialog: CollaboratorDialog
        }
      },
      {
        path: ':id/:userProfileId',
        name: 'collaborator-detail',
        components:
        {
          default: Collaborators,
          dialog: CollaboratorDialog
        }
      }
    ]
  },
  {
    path: '/facilitator',
    component: Facilitators,
    children: [
      {
        path: '/facilitator/list',
        name: 'facilitator-list',
        component: Facilitators
      },
      {
        path: '/facilitator/create',
        name: 'facilitator-create',
        components:
        {
          default: Facilitators,
          dialog: FacilitatorDialog
        }
      },
      {
        path: ':id/:userProfileId',
        name: 'facilitator-detail',
        components:
        {
          default: Facilitators,
          dialog: FacilitatorDialog
        }
      }
    ]
  },
  {
    path: '/retailer/my-company',
    name: 'retailer-company',
    component: CompanyRetailer
  },
  {
    path: '/retailer',
    component: Retailers,
    children: [
      {
        path: '/retailer/list',
        name: 'retailer-list',
        component: Retailers
      },
      {
        path: '/retailer/create',
        name: 'retailer-create',
        components:
        {
          default: Retailers,
          dialog: RetailerDialog
        }
      },
      {
        path: ':id',
        name: 'retailer-detail',
        components:
        {
          default: Retailers,
          dialog: RetailerDialog
        }
      }
    ]
  },
  {
    path: '/retailer-exports',
    name: 'retailer-exports',
    component: RetailerExports
  },
  {
    path: '/:userType/:id/:userProfileId/comments',
    name: 'comments',
    component: Feedbacks
  },
  {
    path: '/options',
    name: 'options',
    component: Options,
    children: [
      {
        path: 'carousels',
        name: 'options-carousels',
        component: OptionsCarousels
      },
      {
        path: 'favorites',
        name: 'options-favorites',
        component: OptionsFavorites
      },
      {
        path: 'result-headers',
        name: 'options-result-headers',
        component: OptionsResultHeaders
      },
      {
        path: 'cross-selling',
        name: 'options-cross-selling',
        component: OptionsCrossSelling
      },
      {
        path: 'history',
        name: 'options-history',
        component: OptionsHistory
      }
    ]
  },
  {
    path: '/product/list',
    name: 'product-list',
    component: Products
  },
  {
    path: '/product/:id',
    name: 'product',
    component: Product,
    children: [
      {
        path: 'description',
        name: 'product-description',
        component: ProductDescription
      },
      {
        path: 'documents',
        name: 'product-documents',
        component: ProductDocuments
      },
      {
        path: 'attribution',
        name: 'product-attribution',
        component: ProductAttribution
      },
      {
        path: 'comments',
        name: 'product-comments',
        component: ProductComments
      },
      {
        path: 'booklet',
        name: 'product-booklet',
        component: ProductBooklet
      },
      {
        path: 'news',
        name: 'product-news',
        component: ProductNews
      },
      {
        path: 'history',
        name: 'product-history',
        component: ProductHistory
      },
      {
        path: 'visibilities',
        name: 'product-visibility',
        component: ProductVisbilities
      }
    ]
  },
  {
    path: '/mailbox',
    name: 'mailbox',
    component: Mailbox
  },
  {
    path: '/favorites',
    name: 'favorites',
    component: Favorites
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: Tasks,
    children: [
      {
        path: ':id',
        name: 'task',
        components: {
          default: Tasks,
          dialog: TaskDialog
        }
      }
    ]
  },
  {
    path: '/admin/settings',
    component: AdminSettings,
    children: [
      {
        path: '',
        name: 'admin-settings-general',
        component: AdminSetting,
        meta: { layout: 'admin' }
      },
      {
        path: '/admin/settings/footer',
        name: 'admin-settings-footer',
        component: AdminFooter,
        meta: { layout: 'admin' }
      },
      {
        path: 'theming',
        name: 'admin-settings-theming',
        component: AdminTheming,
        meta: { layout: 'admin' }
      },
      {
        path: '/admin/settings/reflist',
        name: 'admin-settings-reflist',
        component: AdminReference,
        meta: { layout: 'admin' }
      },
      {
        path: '/admin/settings/pim-attribute',
        name: 'admin-settings-pimattribute',
        component: AdminPimAttribute,
        meta: { layout: 'admin' }
      },
      {
        path: '/admin/settings/profiles',
        name: 'admin-settings-profiles',
        component: AdminProfiles,
        meta: { layout: 'admin' }
      },
      {
        path: '/admin/settings/media',
        name: 'admin-media',
        component: AdminMedia,
        meta: { layout: 'admin' }
      },
      {
        path: '/admin/settings/rules',
        name: 'validation-settings-rules',
        component: ValidationRules,
        meta: { layout: 'admin' }
      },
      {
        path: '/admin/settings/insert',
        name: 'admin-insert',
        component: AdminInsert,
        meta: { layout: 'admin' }
      },
      {
        path: '/admin/fields',
        name: 'admin-fields',
        component: AdminFieldTester,
        meta: { layout: 'admin' }
      }
    ]
  },
  {
    path: '/admin/translations',
    name: 'admin-translation',
    component: AdminTranslation,
    meta: { layout: 'admin' }
  },
  {
    path: '/admin/exports',
    name: 'admin-exports',
    component: AdminExports,
    meta: { layout: 'admin' }
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: Maintenance,
    meta: { layout: 'empty' }
  }, {
    path: '/error',
    name: 'error',
    component: Error
  }, {
    path: '*',
    redirect: {
      name: 'error',
      params: {
        error: 'not-found',
        message: 'page-not-found'
      }
    }
  }]

const router = new VueRouter({
  mode: 'history',
  base: window.env.BASE_URL,
  routes,
  parseQuery (query) {
    return qs.parse(query)
  },
  stringifyQuery (query) {
    const cleanQuery = Object.keys(query).reduce((acc, val) => {
      let value = query[val]
      if (typeof value === 'object') {
        value = Object.keys(value).reduce((acc2, v) => (
          value[v] ? { ...acc2, [v]: value[v] } : acc2
        ), {})
      }
      return { ...acc, [val]: value }
    }, {})
    const r = qs.stringify(cleanQuery)
    return r ? '?' + r : ''
  }
})

router.afterEach(async () => {
  await translations.checkVersion()
})

export default router
