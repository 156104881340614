export default [
  {
    text: 'task.title',
    sortable: false,
    align: 'start',
    value: 'title'
  },
  {
    text: 'task.typeId',
    sortable: false,
    align: 'start',
    value: 'typeId'
  },
  {
    text: 'task.due-date',
    sortable: false,
    align: 'center',
    value: 'dueDate'
  },
  {
    text: 'task.priority',
    sortable: false,
    align: 'center',
    value: 'refTaskPriorityId'
  },
  {
    text: 'task.owner',
    sortable: false,
    align: 'center',
    value: 'ownerUserId'
  },
  {
    text: 'task.performer',
    sortable: false,
    align: 'center',
    value: 'performerUserId'
  },
  {
    text: 'task.entities',
    sortable: false,
    align: 'center',
    value: 'entities'
  },
  {
    text: 'task.actions',
    value: 'actions',
    align: 'end',
    sortable: false
  }
]
