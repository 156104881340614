
<script setup>
import { useAuthStore } from '@/stores/auth'
import { useUserStore } from '@/stores/user'
import { computed } from 'vue'

defineEmits(['delete'])

const props = defineProps({
  id: {
    type: Number,
    required: true
  },
  idUser: {
    type: Number,
    required: true
  }
})

const { rights } = useUserStore()
const { user } = useAuthStore()

const canDelete = computed(() => user?.id === props.idUser || rights.task.update)
</script>

<template>
  <v-tooltip
    left
    :disabled="canDelete"
  >
    <template #activator="{ on }">
      <div
        class="d-inline-block"
        v-on="on"
      >
        <v-btn
          :disabled="!canDelete"
          icon
          @click="$emit('delete', id)"
        >
          <v-icon small>
            fa-times
          </v-icon>
        </v-btn>
      </div>
    </template>
    <span>{{ $t('task.cannot-delete') }}</span>
  </v-tooltip>
</template>
