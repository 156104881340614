import _ from 'lodash'
import { serialize } from 'object-to-formdata'

export default function (data, config, serializeOptions = {}) {
  return {
    data: serialize(data, { ...serializeOptions, indices: true }),
    config: _.merge(config, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  }
}
