import axios from '@/plugins/axios'

export default {

  /**
   * Make a request
   *
   * @param method
   * @param url
   * @param params
   */
  async makeRequest (method, url, params) {
    const response = await axios.request({
      method,
      url,
      params
    })
    return response?.data ?? null
  },
  /**
   * Get user's notifications
   *
   * @param userId
   * @param lastNotification
   * @return {Q.Promise<any> | * | Q.Promise<T | never> | PromiseLike<T | never> | Promise<T | never>}
   */
  getNotifications (userId, lastNotification) {
    return this.makeRequest('get', '/notifications', {
      userId,
      lastNotification
    })
  },
  /**
   * Mark notification as read
   *
   * @param notifId
   * @return {Promise<T | never>}
   */
  read (notifId) {
    return this.makeRequest('patch', '/notifications/' + notifId, {})
  },
  /**
   * Mark all notifications as read
   *
   * @param userId
   * @return {Promise<T | never>}
   */
  readAll () {
    return this.makeRequest('patch', '/notifications/user/mark-all-as-read', {
    })
  },
  /**
   * Delete notification
   *
   * @param notifId
   * @return {Q.Promise<any> | * | Q.Promise<T | never> | PromiseLike<T | never> | Promise<T | never>}
   */
  delete (notifId) {
    return this.makeRequest('delete', '/notifications/' + notifId, {})
  }
}
