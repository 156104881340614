export default Object.freeze({
  arrowRight: {
    color: 'black',
    content: 'fa fa-arrow-circle-right',
    size: 14
  },
  company: {
    color: 'black',
    content: 'fas fa-store-alt',
    size: 14
  },
  envelope: {
    color: 'black',
    content: 'fas fa-envelope',
    size: 14
  },
  'exclamation-circle': {
    color: 'black',
    content: 'fas fa-exclamation-circle',
    size: 14
  },
  flag: {
    color: 'black',
    content: 'fas fa-flag',
    size: 14
  },
  umbrella: {
    color: 'red',
    content: 'fa fa-umbrella-beach',
    size: 14
  },
  coins: {
    color: 'black',
    content: 'fas fa-coins',
    size: 14
  },
  phone: {
    color: 'black',
    content: 'fa fa-phone',
    size: 14
  },
  spinner: {
    color: 'black',
    content: 'fas fa-spinner',
    size: 14
  },
  userCog: {
    color: 'black',
    content: 'fa fa-user-cog',
    size: 11
  },
  userFriends: {
    color: 'black',
    content: 'fa fa-user-friends',
    size: 14
  },
  star: {
    color: 'primary',
    content: 'fa fa-star',
    size: 18
  },
  calendar: {
    color: 'black',
    content: 'fa-calendar',
    size: 14
  },
  bullseye: {
    color: 'black',
    content: 'fa-bullseye',
    size: 14
  },
  lock: {
    color: 'black',
    content: 'fa-lock',
    size: 14
  }
})
