<script setup>
import { computed, getCurrentInstance } from 'vue'
import moment from 'moment'

const root = getCurrentInstance().proxy
const emits = defineEmits(['update:date', 'input'])
const props = defineProps({
  chipColor: {
    type: String,
    default: 'white'
  },
  date: {
    type: String,
    default: null
  },
  disabled: {
    type: Boolean,
    default: false
  },
  formatDate: {
    type: Boolean,
    default: true
  },
  menuMaxWidth: {
    type: Number,
    default: 290
  },
  value: {
    type: Boolean,
    default: false
  }
})

const initialDueDate = computed(() => {
  return moment().format('YYYY-MM-DD')
})
const iDate = computed({
  get () {
    return props.date
      ? moment(props.date).format('YYYY-MM-DD')
      : null
  },
  set (val) {
    emits('update:date', val)
  }
})
const iValue = computed({
  get () {
    return props.value
  },
  set (val) {
    emits('input', val)
  }
})

function formatDueDate (date) {
  if (!date) return null

  const taskDate = moment(date).startOf('day')
  const today = moment().startOf('day')
  const nbDays = moment.duration(taskDate.diff(today)).asDays()

  if (props.formatDate) {
    if (nbDays === -1) {
      return root.$t('common.yesterday')
    } else if (nbDays === 0) {
      return root.$t('common.today')
    } else if (nbDays === 1) {
      return root.$t('common.tomorrow')
    }
  }
  return moment(date).format('LL')
}
</script>

<template>
  <v-menu
    v-model="iValue"
    :disabled="disabled"
    :max-width="menuMaxWidth"
    close-on-content-click
  >
    <template #activator="{ on, attrs }">
      <v-chip
        v-bind="attrs"
        :color="chipColor"
        label
        v-on="on"
      >
        {{ formatDueDate(date) }}
      </v-chip>
    </template>
    <v-date-picker
      v-model="iDate"
      :min="initialDueDate"
    />
  </v-menu>
</template>
