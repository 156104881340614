<template>
  <router-link
    v-if="hasRight"
    class="router-link"
    :title="$attrs.title"
    :to="to"
  >
    <slot />
  </router-link>
  <span v-else-if="visibleIfNoRight">
    <slot />
  </span>
  <span v-else />
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  hasRight: {
    type: [Boolean, Object, Number],
    default: false
  },
  name: {
    type: String,
    required: true
  },
  hash: {
    type: String,
    default: null
  },
  params: {
    type: Object,
    default: null
  },
  filters: {
    type: Object,
    default: null
  },
  visibleIfNoRight: {
    type: Boolean,
    default: true
  }
})

const to = computed(() => {
  const toObj = {
    name: props.name
  }
  if (props.params) {
    toObj.params = props.params
  }
  if (props.filters) {
    toObj.query = props.filters
  }
  if (props.hash) {
    toObj.hash = props.hash
  }
  return toObj
})
</script>
