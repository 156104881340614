<script setup>
import ProfileSelector from '@/components/ProfileSelector'
import { LogAsOrigin } from '@/components/LogAs'
import { useAuthStore } from '@/stores/auth'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
import { computed, getCurrentInstance, ref } from 'vue'

const root = getCurrentInstance().proxy

const authStore = useAuthStore()
const userStore = useUserStore()

const { loggedAs, user } = storeToRefs(authStore)
const { rights, profile } = storeToRefs(userStore)

const loggedAsOrigin = ref(false)

const actions = computed(() => {
  const items = []
  if (isAdmin.value) {
    items.push({
      icon: 'fa-cog',
      to: '/admin/settings',
      title: root.$t('sticker.admin.text')
    })
  }
  if (loggedAs.value) {
    items.push({
      icon: 'fa-sync-alt',
      title: root.$t('common.log-as-origin'),
      click: () => {
        loggedAsOrigin.value = true
      }
    })
  }
  return items
})
const isAdmin = computed(() => rights.value?.admin?.profileWrite ?? false)
const multiProfiles = computed(() => user.value?.profiles?.length > 0 ?? false)
const title = computed(() => `common.profiles.${profile.value.type}`)
</script>

<template>
  <v-card
    v-if="isAdmin || multiProfiles || loggedAs"
    class="sticker d-flex mr-5 py-2 px-4 align-center"
    style=""
    tile
    flat
  >
    <span class="mr-1">{{ $t(title) }}</span>
    <v-btn
      v-for="(action, idx) in actions"
      :key="idx"
      :to="action.to || null"
      :title="action.title"
      icon
      @click="action.click ? action.click() : null"
    >
      <v-icon small>
        {{ action.icon }}
      </v-icon>
    </v-btn>
    <profile-selector v-if="multiProfiles">
      <template #activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          icon
          :title="$t('sticker.switch')"
          v-on="on"
        >
          <v-icon small>
            fa-sync-alt
          </v-icon>
        </v-btn>
      </template>
    </profile-selector>
    <log-as-origin v-model="loggedAsOrigin" />
  </v-card>
</template>

<style lang="scss" scoped>
.v-card.sticker {
  z-index: 3 !important;
  position: fixed;
}
</style>
