<template>
  <div>
    <v-badge
      offset-x="30"
      offset-y="20"
      color="none"
      data-test="badge-avatar"
    >
      <template
        v-if="!disabled"
        #badge
      >
        <v-btn
          class="primary"
          data-test="badge-avatar-button"
          x-small
          dark
          fab
          @click="dialog = true"
        >
          <v-icon x-small>
            fas fa-pen
          </v-icon>
        </v-btn>
      </template>
      <avatar
        :url="value"
        :initials="initials"
        class="elevation-0"
        local
      />
    </v-badge>
    <dialog-simple
      :max-width="680"
      :show="dialog"
      :title="$t('common.avatar')"
      @close="closeDialog"
    >
      <template #dialog-body>
        <v-row
          no-gutters
          class="pb-10 pt-5"
        >
          <v-alert
            v-if="error"
            dense
            outlined
            type="error"
          >
            {{ error }}
          </v-alert>
          <avatar
            :url="previewAvatar"
            :initials="initials"
            class="elevation-0"
            local
          />
          <v-row class="flex-column ml-2">
            <v-col>
              <v-btn
                text
                class="mt-2"
                @click="onButtonClick"
              >
                <v-icon
                  left
                  small
                  v-text="'fas fa-cloud-upload-alt'"
                />
                {{ $t('common.modify') }}
              </v-btn>
              <input
                ref="uploader"
                class="d-none"
                type="file"
                accept="image/*"
                data-test="dialog-avatar-input-file"
                @change="onFileChanged"
              >
            </v-col>
            <v-col>
              <v-btn
                text
                data-test="dialog-avatar-remove-button"
                @click="removeAvatar"
              >
                <v-icon
                  left
                  small
                  v-text="'fas fa-trash-alt'"
                />
                {{ $t('common.delete') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              color="primary"
              class="mr-5"
              data-test="dialog-avatar-save-button"
              @click.stop="save"
            >
              {{ $t('common.save') }}
            </v-btn>
            <v-btn
              data-test="dialog-avatar-close-button"
              @click.stop="closeDialog"
            >
              {{ $t('common.cancel') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </dialog-simple>
  </div>
</template>

<script>
import DialogSimple from '@/components/Dialogs/DialogSimple'

export default {
  name: 'FieldAvatar',
  components: {
    DialogSimple
  },
  props: {
    disabled: Boolean,
    value: {
      type: String,
      default: null
    },
    initials: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      dialog: false,
      error: null,
      localAvatar: null,
      previewAvatar: this.value || ''
    }
  },
  watch: {
    value: {
      handler (val) {
        this.previewAvatar = val || ''
      }
    }
  },
  methods: {
    onButtonClick () {
      this.$refs.uploader.click()
    },
    onFileChanged (e) {
      this.previewAvatar = URL.createObjectURL(e.target.files[0])
      this.localAvatar = e.target.files[0]
    },
    save () {
      this.$emit('input', this.previewAvatar)
      this.$emit('update-avatar', this.localAvatar)
      this.dialog = false
    },
    removeAvatar () {
      this.localAvatar = null
      this.previewAvatar = null
      this.$refs.uploader.value = null
    },
    closeDialog () {
      this.dialog = false
    }
  }
}
</script>
