import { isValidPhoneNumber } from 'libphonenumber-js'
import i18n from '@/plugins/vue-i18n'

export default {
  params: ['lang', 'codeNumber'],
  validate: (v, { lang, codeNumber }) => {
    const regex = new RegExp('^\\+' + codeNumber)
    return (v && regex.test(v) && isValidPhoneNumber(v, lang)) || i18n.t('validation.phone.invalid')
  }
}
