import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import '@fortawesome/fontawesome-free/css/all.css'
import en from 'vuetify/src/locale/en.ts'
import fr from 'vuetify/src/locale/fr.ts'
import getUserLocale from '@/services/getUserLocale'
import VTiptap from '@peepi/vuetify-tiptap'

Vue.use(Vuetify)

const currentLocale = () => {
  return getUserLocale() ||
    window.env.VUE_APP_I18N_LOCALE ||
    window.env.VUE_APP_I18N_FALLBACK_LOCALE ||
    'fr-FR'
}

const vuetify = new Vuetify({
  icons: {
    iconfont: 'fa'
  },
  lang: {
    locales: {
      'en-GB': en,
      'fr-FR': fr
    },
    current: currentLocale()
  },
  theme: {
    options: {
      customProperties: true,
      variations: true
    },
    themes: {
      dark: {
        background: '#00a86b'
      },
      light: {
        background: '#000'
      }
    }
  }
})

Vue.use(VTiptap)

export default vuetify
