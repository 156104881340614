<script setup>
import socket from '@/services/socket'
import BellTop from './BellTop'
import BellBottom from './BellBottom'
import { useNotificationStore } from '@/stores/notification'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'

const props = defineProps({
  userId: {
    type: Number,
    required: true
  }
})

const notificationStore = useNotificationStore()

const { unread } = storeToRefs(notificationStore)

const newUnread = ref(false)

const someUnread = computed(() => {
  if (unread.value > 9) {
    return '9+'
  } else if (unread.value) {
    return unread.value
  } else {
    return null
  }
})

function refresh (noNew = false) {
  notificationStore.fetchNotifications({ userId: props.userId })
  if (!noNew) {
    newUnread.value = true
    // animation purpose
    setTimeout(() => { newUnread.value = false }, 1500)
  }
}

socket.listenNotifications(refresh, props.userId)
refresh(true)
</script>

<template>
  <v-btn
    class="notification-badge"
    data-test="btn-notifications"
    icon
    aria-label="notifications"
    @click.stop="$emit('open-notification')"
  >
    <v-badge
      bordered
      color="coral"
      :content="someUnread"
      :value="someUnread"
      overlap
    >
      <div
        class="notification-badge__bell"
        :class="{
          'notification-badge__bell--new': newUnread,
          'notification-badge__bell--unread': someUnread
        }"
      >
        <bell-top class="notification-badge__bell__top" />
        <bell-bottom class="notification-badge__bell__bottom" />
      </div>
    </v-badge>
  </v-btn>
</template>

<style lang="scss" scoped>
.notification-badge {
  &__bell {
    width: 30px;
    height: 30px;
    position: relative;
    transform-origin: top;

    &__top {
      position: absolute;
      top: 0;
      left: 0;
    }

    &__bottom {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &--new {
      animation-duration: 1.5s;
      animation-name: top;

      .notification-badge__bell__bottom {
        animation-duration: 1.5s;
        animation-name: bottom;
      }
    }
  }

  @keyframes bottom {
    0% {
      transform: translateX(0);
    }

    15% {
      transform: translateX(3px);
    }

    45% {
      transform: translateX(-3px);
    }

    75% {
      transform: translateX(3px);
    }

    90% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes top {
    0% {
      transform: rotate(0);
    }

    12% {
      transform: rotate(13deg);
    }

    36% {
      transform: rotate(-13deg);
    }

    60% {
      transform: rotate(13deg);
    }

    72% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(0);
    }
  }

  @keyframes appearit {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}
</style>
