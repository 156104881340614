import RightsFormatter from '@/services/RightsFormatter'
import AuthFetcher from '@/services/fetchers/Auth'
import ProfilesFetcher from '@/services/fetchers/Profiles'
import socket from '@/services/socket'
import { useAuthStore } from '@/stores/auth'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'
import _ from 'lodash'

export const useUserStore = defineStore('user', () => {
  const connectedUsers = ref(null)
  const menuItems = ref(null)
  const profile = ref(null)
  const rights = ref(null)

  const getRights = computed(() => rights.value)
  const getProfile = computed(() => profile.value)
  const getMenuItems = computed(() => menuItems.value)
  const getConnectedUserByTalkId = computed(() => {
    return (talkUserId) => _.find(connectedUsers.value, { talkUserId })
  })
  const getConnectedUserById = computed(() => {
    return (id) => _.find(connectedUsers.value, { id })
  })
  const getAllConnectedUsers = computed(() => connectedUsers.value)

  function clear () {
    rights.value = null
    profile.value = null
    menuItems.value = null
    connectedUsers.value = null
  }
  async function initConnectedUsers () {
    socket.listenPresence(users => {
      connectedUsers.value = users
    }, u => {
      connectedUsers.value = _.concat(connectedUsers.value, u)
    }, u => {
      connectedUsers.value = _.filter(connectedUsers.value, usr => usr.id !== u.id)
    })
  }
  async function setData (payload) {
    const profileRights = (await ProfilesFetcher.getRights(payload.profile.id)).data.data.map(r => r.code)

    profile.value = payload.profile
    menuItems.value = payload.menuItems
    rights.value = RightsFormatter.formatRights(profileRights)
  }
  async function setProfile (payload) {
    try {
      const { referrerUrl } = payload
      const { userProfileId } = payload.profile
      const response = await AuthFetcher.setProfile({ userProfileId, referrerUrl })
      const menuItems = response.data.data.refMenu

      const authStore = useAuthStore()
      authStore.saveTokens({ response })
      authStore.updateUser(response.data.data.user)
      await setData({ profile: payload.profile, menuItems })
      return response
    } catch (e) {
      throw new Error(e)
    }
  }

  return {
    connectedUsers,
    menuItems,
    profile,
    rights,

    getRights,
    getProfile,
    getMenuItems,
    getConnectedUserByTalkId,
    getConnectedUserById,
    getAllConnectedUsers,

    clear,
    initConnectedUsers,
    setData,
    setProfile
  }
}, { persist: true })

if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(acceptHMRUpdate(useUserStore, import.meta.webpackHot))
}
