<template>
  <div class="department">
    <div class="d-flex justify-end justify-space-between align-center">
      <v-label>{{ $t(label) }}</v-label>
      <field-checkbox
        :disabled="disabled"
        :label="$t('common.all-france')"
        :readonly="readonly"
        :value="all"
        @change="iValue = $event"
      />
    </div>
    <div
      v-if="!all"
      class="mt-2"
    >
      <field-autocomplete
        v-model="iValue"
        v-bind="$attrs"
        :disabled="disabled"
        :field="{
          key: 'localization'
        }"
        :items="departments"
        :label="$t(inputI18n)"
        :loading="loading"
        :multiple="multiple"
        :search-input.sync="search"
        :readonly="readonly"
        :return-object="false"
        item-text="label"
        item-value="value"
        hide-selected
        cache-items
        custom-item
      >
        <template #item="{ item }">
          {{ item.value.replace('dept_', '') }} - {{ item.label }}
        </template>
      </field-autocomplete>
    </div>
  </div>
</template>

<script>
import PimFetcher from '@/services/fetchers/Pim'
import { useUniverseStore } from '@/stores/universe'
import { mapActions, mapState } from 'pinia'
import { usePimStore } from '@/stores/pim'

export default {
  name: 'FieldDepartment',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    inputI18n: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Array, Boolean, String],
      default: null
    }
  },
  data () {
    return {
      items: [],
      loading: false,
      search: null
    }
  },
  computed: {
    all () {
      return this.iValue === true
    },
    departments () {
      const refs = this.getReferencesByKey(this.pimKey)
      refs.sort((a, b) => b.value - a.value)
      return refs
    },
    iValue: {
      get () {
        return this.value
      },
      set (val) {
        if (val === false) {
          this.$emit('input', this.multiple ? [] : null)
        } else {
          this.$emit('input', val)
        }
      }
    },
    pimKey () {
      return 'pim_presta_departement'
    },
    pimSlug () {
      return this.getSettingByPath('products.manifest.pim')['pim-slug']
    },
    ...mapState(useUniverseStore, ['getSettingByPath']),
    ...mapState(usePimStore, {
      getReferencesByKey: 'getReferencesByKey'
    })
  },
  watch: {
    search (val, prevVal) {
      if (val && val !== prevVal && val.length > 1) {
        this.loading = true
        this.fetchPim(val)
      }
    },
    value: {
      deep: true,
      handler (val, prevVal) {
        if (!prevVal && val) {
          this.fetchInitialValues(val)
        }
      }
    }
  },
  methods: {
    async fetchInitialValues () {
      if (Array.isArray(this.value) && this.value.length) {
        this.loading = true
        this.addReferences((await PimFetcher.get(this.pimSlug, {
          key: this.pimKey,
          values: this.value
        })).data.data)
        this.loading = false
      }
    },
    async fetchPim (search) {
      try {
        this.loading = true
        const items = (await PimFetcher.search(this.pimSlug, {
          key: this.pimKey,
          label: search
        })).data.data
        this.addReferences(items)
      } finally {
        this.loading = false
      }
    },
    ...mapActions(usePimStore, {
      addReferences: 'addReferences'
    })
  }
}
</script>

<style lang="scss" scoped>
.department {
  :deep(.v-input--checkbox) {
    padding-top: 0 !important;
  }
}
</style>
