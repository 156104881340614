import EntityTypes from './EntityTypes'

const ADMIN_PROFIL_READ = 'ADMIN_PROFIL_READ'
const ADMIN_PROFIL_WRITE = 'ADMIN_PROFIL_WRITE'

const ADMIN_REFLIST_READ = 'ADMIN_REFLIST_READ'
const ADMIN_REFLIST_WRITE = 'ADMIN_REFLIST_WRITE'

const ADMIN_PIM_ATTRIBUTE_READ = 'ADMIN_PIM_ATTRIBUTE_READ'
const ADMIN_PIM_ATTRIBUTE_WRITE = 'ADMIN_PIM_ATTRIBUTE_WRITE'

const ADMIN_TRANSLATION_READ = 'ADMIN_TRAD_READ'
const ADMIN_TRANSLATION_WRITE = 'ADMIN_TRAD_WRITE'

const ADMIN_STATS_READ = 'ADMIN_STATS_READ'
const ADMIN_EXPORTS_READ = 'ADMIN_EXPORTS_READ'

const ADMIN_PUB_READ = 'ADMIN_PUB_READ'
const ADMIN_PUB_WRITE = 'ADMIN_PUB_WRITE'

const APPLICANT_LIST_READ = 'APPLICANT_LIST_READ'
const APPLICANT_ANSWER_FEEDBACKS_READ = 'APPLICANT_ANSWER_FEEDBACKS_READ'
const APPLICANT_READ = 'APPLICANT_READ'
const APPLICANT_WRITE = 'APPLICANT_WRITE'

const LOG_AS_USER = 'LOG_AS_USER'

const TEAM_LIST_READ = 'TEAM_LIST_READ'
const TEAM_READ = 'TEAM_READ'
const TEAM_WRITE = 'TEAM_WRITE'

const PRODUCT_LIST_READ = 'PRODUCT_LIST_READ'
const PRODUCT_READ = 'PRODUCT_READ'
const PRODUCT_WRITE = 'PRODUCT_WRITE'
const PRODUCT_CREATE = 'PRODUCT_CREATE'
const PRODUCT_CREATE_SUB_PRODUCT = 'SUB_PRODUCT_CREATE'
const PRODUCT_NEWS_WRITE = 'PRODUCT_NEWS_WRITE'
const PRODUCT_NEWS_READ = 'PRODUCT_NEWS_READ'
const PRODUCT_HISTORY_READ = 'PRODUCT_HISTORY_READ'
const PRODUCT_PUBLISH_UNPUBLISH = 'PRODUCT_PUBLISH_UNPUBLISH'

const PROJECT_LIST_READ = 'PROJECT_LIST_READ'
const PROJECT_READ = 'PROJECT_READ'
const PROJECT_WRITE = 'PROJECT_WRITE'
const PROJECT_CHANGE_FACILITATOR = 'PROJECT_CHANGE_FACILITATOR'
const PROJECT_ADVANCED_WRITE = 'PROJECT_ADVANCED_WRITE'
const ALL_PROJECT_LIST_READ = 'ALL_PROJECT_LIST_READ'
const TEAM_PROJECT_LIST_READ = 'TEAM_PROJECT_LIST_READ'
const CANCEL_ALL_ANSWER_PROJECT = 'CANCEL_ALL_ANSWER_PROJECT'
const POST_DELETE = 'POST_DELETE'

const CANCEL_ALL_ANSWER_NEED = 'CANCEL_ALL_ANSWER_NEED'

const CONTRACT_READ = 'CONTRACT_READ'
const CONTRACT_WRITE = 'CONTRACT_WRITE'
const RETAILER_LIST_READ = 'RETAILER_LIST_READ'
const RETAILER_READ = 'RETAILER_READ'
const RETAILER_WRITE = 'RETAILER_WRITE'
const CRITERIA_READ = 'CRITERIA_READ'
const CRITERIA_WRITE = 'CRITERIA_WRITE'

const QUOTATION_WRITE = 'QUOTATION_WRITE'
const QUOTATION_READ = 'QUOTATION_READ'
const QUOTATION_SETUP = 'QUOTATION_SETUP'

const FACILITATOR_LIST_READ = 'FACILITATOR_LIST_READ'
const FACILITATOR_ANSWER_FEEDBACKS_READ = 'FACILITATOR_ANSWER_FEEDBACKS_READ'
const FACILITATOR_READ = 'FACILITATOR_READ'
const FACILITATOR_WRITE = 'FACILITATOR_WRITE'

const RETAILER_USER_LIST_READ = 'RETAILER_USER_LIST_READ'
const RETAILER_ANSWER_FEEDBACKS_READ = 'RETAILER_ANSWER_FEEDBACKS_READ'
const RETAILER_USER_READ = 'RETAILER_USER_READ'
const RETAILER_USER_WRITE = 'RETAILER_USER_WRITE'

const ANSWER_LIST_READ = 'ANSWER_LIST_READ'
const ALL_ANSWER_LIST_READ = 'ALL_ANSWER_LIST_READ'
const ANSWER_READ = 'ANSWER_READ'
const ANSWER_WRITE = 'ANSWER_WRITE'
const ANSWER_CHANGE_RETAILER_USER = 'ANSWER_CHANGE_RETAILER_USER'

const FACILITATOR = 'FACILITATOR'

const TASK_ASSIGN_ALL = 'TASK_ASSIGN_ALL'
const TASK_ASSIGN_TEAM = 'TASK_ASSIGN_TEAM'
const TASK_UPDATE = 'TASK_UPDATE'

const SUPER_API_ADMIN = 'SUPER_API_ADMIN'

const HOMEPAGE_VISUAL_READ = 'HOMEPAGE_VISUAL_READ'
const HOMEPAGE_VISUAL_WRITE = 'HOMEPAGE_VISUAL_WRITE'
const HOMEPAGE_VISUAL_CLEAR_CACHES = 'HOMEPAGE_VISUAL_CLEAR_CACHES'

const MESSAGE_RESPONSE_REQUEST_READ = 'MESSAGE_RESPONSE_REQUEST_READ'
const MESSAGE_RESPONSE_REQUEST_WRITE = 'MESSAGE_RESPONSE_REQUEST_WRITE'

const OPTIONS_READ = 'OPTIONS_READ'
const OPTIONS_WRITE = 'OPTIONS_WRITE'

export default {
  [EntityTypes.Applicant]: {
    listRead: APPLICANT_LIST_READ,
    feedbacksRead: APPLICANT_ANSWER_FEEDBACKS_READ,
    logAs: LOG_AS_USER,
    read: APPLICANT_READ,
    write: APPLICANT_WRITE
  },
  [EntityTypes.Team]: {
    listRead: TEAM_LIST_READ,
    read: TEAM_READ,
    write: TEAM_WRITE
  },
  [EntityTypes.Product]: {
    listRead: PRODUCT_LIST_READ,
    read: PRODUCT_READ,
    write: PRODUCT_WRITE,
    create: PRODUCT_CREATE,
    createSubProduct: PRODUCT_CREATE_SUB_PRODUCT,
    newsWrite: PRODUCT_NEWS_WRITE,
    newsRead: PRODUCT_NEWS_READ,
    historyRead: PRODUCT_HISTORY_READ,
    publishUnpublish: PRODUCT_PUBLISH_UNPUBLISH
  },
  [EntityTypes.Project]: {
    listRead: PROJECT_LIST_READ,
    read: PROJECT_READ,
    write: PROJECT_WRITE,
    changeFacilitator: PROJECT_CHANGE_FACILITATOR,
    advancedWrite: PROJECT_ADVANCED_WRITE,
    allListRead: ALL_PROJECT_LIST_READ,
    teamListRead: TEAM_PROJECT_LIST_READ,
    cancelAnswers: CANCEL_ALL_ANSWER_PROJECT,
    postDelete: POST_DELETE
  },
  [EntityTypes.Need]: {
    cancelAllAnswerNeeds: CANCEL_ALL_ANSWER_NEED
  },
  [EntityTypes.Retailer]: {
    listRead: RETAILER_LIST_READ,
    read: RETAILER_READ,
    write: RETAILER_WRITE,
    contractRead: CONTRACT_READ,
    contractWrite: CONTRACT_WRITE,
    criteriaRead: CRITERIA_READ,
    criteriaWrite: CRITERIA_WRITE
  },
  [EntityTypes.Facilitator]: {
    listRead: FACILITATOR_LIST_READ,
    feedbacksRead: FACILITATOR_ANSWER_FEEDBACKS_READ,
    logAs: LOG_AS_USER,
    read: FACILITATOR_READ,
    write: FACILITATOR_WRITE
  },
  [EntityTypes.Collaborator]: {
    listRead: RETAILER_USER_LIST_READ,
    feedbacksRead: RETAILER_ANSWER_FEEDBACKS_READ,
    messageResponseRequestRead: MESSAGE_RESPONSE_REQUEST_READ,
    messageResponseRequestWrite: MESSAGE_RESPONSE_REQUEST_WRITE,
    logAs: LOG_AS_USER,
    read: RETAILER_USER_READ,
    write: RETAILER_USER_WRITE
  },
  [EntityTypes.Answer]: {
    listRead: ANSWER_LIST_READ,
    allListRead: ALL_ANSWER_LIST_READ,
    read: ANSWER_READ,
    write: ANSWER_WRITE,
    changeRetailerUser: ANSWER_CHANGE_RETAILER_USER
  },
  [EntityTypes.Quotation]: {
    read: QUOTATION_READ,
    write: QUOTATION_WRITE,
    setup: QUOTATION_SETUP
  },
  [EntityTypes.Task]: {
    assignAll: TASK_ASSIGN_ALL,
    assignTeam: TASK_ASSIGN_TEAM,
    update: TASK_UPDATE
  },
  [EntityTypes.Admin]: {
    profileRead: ADMIN_PROFIL_READ,
    profileWrite: ADMIN_PROFIL_WRITE,
    refListRead: ADMIN_REFLIST_READ,
    refListWrite: ADMIN_REFLIST_WRITE,
    pimAttributeRead: ADMIN_PIM_ATTRIBUTE_READ,
    pimAttributeWrite: ADMIN_PIM_ATTRIBUTE_WRITE,
    statsRead: ADMIN_STATS_READ,
    exportRead: ADMIN_EXPORTS_READ,
    translationRead: ADMIN_TRANSLATION_READ,
    translationWrite: ADMIN_TRANSLATION_WRITE,
    pubWrite: ADMIN_PUB_WRITE,
    pubRead: ADMIN_PUB_READ,
    optionsRead: OPTIONS_READ,
    optionsWrite: OPTIONS_WRITE
  },
  [EntityTypes.Api]: {
    admin: SUPER_API_ADMIN,
    facilitator: FACILITATOR
  },
  [EntityTypes.ContentManagement]: {
    homepageVisualRead: HOMEPAGE_VISUAL_READ,
    homepageVisualWrite: HOMEPAGE_VISUAL_WRITE,
    homepageVisualClearCaches: HOMEPAGE_VISUAL_CLEAR_CACHES
  }
}
