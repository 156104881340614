import numeral from 'numeral'
import getUserLocale from '@/services/getUserLocale'

numeral.register('locale', 'fr-fr', {
  delimiters: {
    thousands: ' ',
    decimal: ','
  },
  abbreviations: {
    thousand: 'm',
    million: 'mn',
    billion: 'md',
    trillion: 'tn'
  },
  ordinal: function (number) {
    return number === 1 ? 'er' : 'ème'
  },
  currency: {
    symbol: '€'
  }
})

numeral.register('locale', 'en-gb', {
  delimiters: {
    thousands: '.',
    decimal: '.'
  },
  abbreviations: {
    thousand: 'K',
    million: 'M',
    billion: 'B',
    trillion: 'T'
  },
  currency: {
    symbol: '$'
  }
})

const defaultLocale = getUserLocale() ||
  window.env.VUE_APP_I18N_LOCALE ||
  window.env.VUE_APP_I18N_FALLBACK_LOCALE

numeral.locale(defaultLocale)

export default numeral
