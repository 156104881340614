import DamFetcher from '@/services/fetchers/Dam'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useAssetsStore = defineStore('assets', () => {
  const dam = ref(null)

  const getDamAsset = computed(() => {
    return (avatarDamAssetId) => {
      if (dam.value && dam.value[avatarDamAssetId]) {
        return dam.value[avatarDamAssetId]
      }
      return {}
    }
  })

  function clear () {
    dam.value = null
  }
  async function fetchProjectImage (payload) {
    const { avatarDamAssetId } = payload
    if (!dam.value || !dam.value[avatarDamAssetId]) {
      try {
        const remoteImage = (await DamFetcher.getDamAsset(avatarDamAssetId)).data.data
        if (remoteImage) {
          const newDamObject = Object.assign({}, dam.value, { [avatarDamAssetId]: remoteImage })
          dam.value = newDamObject
        }
      } catch (err) { }
    }
  }

  return {
    dam,
    getDamAsset,
    clear,
    fetchProjectImage
  }
}, { persist: true })

if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(acceptHMRUpdate(useAssetsStore, import.meta.webpackHot))
}
