import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useListingStore = defineStore('listing', () => {
  const query = ref(null)

  return {
    query
  }
})
