import axios from '../../plugins/axios'

export default {
  logAs (userProfileId) {
    return axios.get(`/auth/log-as/${userProfileId}`)
  },
  logAsOrigin () {
    return axios.get('/auth/log-as/origin')
  },
  login ({ username, password }) {
    return axios
      .post('/auth/login', {
        username,
        password
      })
  },
  setProfile ({ userProfileId, referrerUrl }) {
    return axios
      .post('/auth/login/profile', {
        userProfileId,
        referrerUrl
      })
  },
  setUniverse (universeId) {
    return axios.post('/auth/login/universe', { universeId })
  },
  ssoAuthToken () {
    return axios.get('/sso/auth/token')
  },
  requestPasswordReset ({ email }) {
    return axios
      .post('/auth/forgotten-password', {
        email
      })
  },
  resetPassword ({ password, email, token }) {
    return axios
      .post('/auth/reset-password', {
        password,
        email,
        token
      })
  },
  changePassword ({ oldPassword, newPassword }) {
    return axios
      .post('/auth/change-password', {
        oldPassword,
        newPassword
      })
  },
  getPasswordPolicy ({ email, token }) {
    return axios
      .get('/auth/reset-password/password-policy', {
        params: {
          email,
          token
        }
      })
  }
}
