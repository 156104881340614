import i18n from '@/plugins/vue-i18n'

export default {
  params: ['length'],
  validate: (value, { length }) => {
    // format americain uniquement
    if (!isNaN(value)) {
      if (!length) {
        return true
      }
      if (value.toString().match(new RegExp(`^[^.]+(.[0-9]{0,${length}})?$`))) {
        return true
      } else {
        return i18n.t('validation.numeric.precision', {
          placeholder_1: length
        })
      }
    }
    return i18n.t('validation.numeric')
  }
}
