<script setup>
import { computed } from 'vue'

const emits = defineEmits(['input'])
const props = defineProps({
  value: {
    type: Number,
    default: null
  }
})

const iValue = computed({
  get () {
    return props.value
  },
  set (val) {
    emits('input', val)
  }
})
</script>

<template>
  <v-slider
    v-model="iValue"
    v-bind="$attrs"
  />
</template>
