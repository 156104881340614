import UniverseStatusFetcher from '@/services/fetchers/UniverseStatus'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'
import _ from 'lodash'

export const useUniverseStatusStore = defineStore('universeStatus', () => {
  const list = ref([])

  const getUniverseStatuses = computed(() => {
    return (entityType) => _.filter(list.value, i => i.entityType === entityType)
  })
  const getUniverseStatusById = computed(() => {
    return (id) => list.value.find(status => status.id === parseInt(id)) ?? {}
  })
  const getUniverseStatusesByRefStatus = computed(() => {
    return (refStatus, entityType = null) => list.value.filter(universeStatus => {
      if (entityType) {
        return universeStatus.refStatusId === parseInt(refStatus) &&
          universeStatus.entityType.toLowerCase() === entityType.description.toLowerCase()
      }
      return universeStatus.refStatusId === parseInt(refStatus)
    })
  })

  function clear () {
    list.value = []
  }
  async function loadUniverseStatuses () {
    try {
      const response = await UniverseStatusFetcher.getUniverseStatuses()
      if (response?.data) {
        list.value = response.data
        return
      }
      throw new Error(['Failed to fetch the universe statuses: invalid response.'])
    } catch (error) {
      throw new Error(error.toJSON())
    }
  }

  return {
    list,
    getUniverseStatuses,
    getUniverseStatusById,
    getUniverseStatusesByRefStatus,
    clear,
    loadUniverseStatuses
  }
}, { persist: true })

if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(acceptHMRUpdate(useUniverseStatusStore, import.meta.webpackHot))
}
