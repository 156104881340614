import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'

const constants = {
  timeout: 5000,
  color: 'primary'
}

export const useSnackbarStore = defineStore('snackbar', () => {
  const color = ref(constants.color)
  const message = ref(null)
  const timeout = ref(constants.timeout)
  const show = ref(false)

  function setMessage (payload) {
    message.value = payload.message
    if (payload.color) {
      color.value = payload.color
    }
    if (payload.timeout) {
      timeout.value = payload.timeout
    }
    show.value = true
  }

  function clear () {
    show.value = false
    message.value = null
    color.value = constants.color
    timeout.value = constants.timeout
  }

  return {
    color,
    message,
    timeout,
    show,

    setMessage,
    clear
  }
}, { persist: true })

if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(acceptHMRUpdate(useSnackbarStore, import.meta.webpackHot))
}
