<template>
  <field-autocomplete
    v-model="select"
    v-bind="$attrs"
    :field="field"
    :items="items"
    :item-text="itemText"
    :item-value="itemValue"
    :loading="loading"
    :multiple="multiple"
    :search-input.sync="search"
    hide-selected
    return-object
    cache-items
  />
</template>

<script>
import FieldAutocomplete from '../FieldAutocomplete'
import getUserLocale from '@/services/getUserLocale'
import PimFetcher from '@/services/fetchers/Pim'
import { usePimStore } from '@/stores/pim'
import { mapActions, mapState } from 'pinia'

export default {
  name: 'FieldPimReference',
  components: {
    FieldAutocomplete
  },
  props: {
    field: {
      type: Object,
      required: true
    },
    itemText: {
      type: [String, Function],
      default: 'label'
    },
    itemValue: {
      type: [String, Function],
      default: 'id'
    },
    itemsPerPage: {
      type: Number,
      default: undefined
    },
    locale: {
      type: String,
      default: getUserLocale()
    },
    multiple: {
      type: Boolean,
      default: true
    },
    orderBy: {
      type: Object,
      default: null
    },
    value: {
      type: [Array, String],
      default: null
    }
  },
  data () {
    return {
      items: [],
      loading: false,
      search: null,
      select: null
    }
  },
  computed: {
    key () {
      return this.field.pimKey.split('::')[1]
    },
    slug () {
      return this.field.pimSlug
    },
    refLocaleCode () {
      return this.locale.replace('-', '_')
    },
    ...mapState(usePimStore, {
      references: 'getReferences'
    })
  },
  watch: {
    value (val) {
      if (!val) {
        this.select = null
      }
    },
    select (val) {
      this.emit(val)
    },
    search (val, prevVal) {
      if (!val || val === prevVal || val.length < 2) return
      this.fetchEntriesDebounced(val)
    }
  },
  async created () {
    const { key, slug, value, orderBy } = this

    if (value) {
      try {
        this.loading = true
        await this.fetchReferences({
          key,
          refLocaleCode: this.refLocaleCode,
          slug,
          values: Array.isArray(value) ? value : [value]
        })
      } finally {
        this.loading = true
      }
      this.setSelectValue()
    }
    try {
      const resp = (await PimFetcher.search(this.slug, {
        key,
        orderBy,
        refLocaleCode: this.refLocaleCode,
        resultsPerPage: this.itemsPerPage
      })).data.data
      this.items = [...resp, ...this.references?.filter(r => {
        return r.key === key && (this.locale ? r.locale === this.refLocaleCode : true)
      }) ?? []]
    } finally {
      this.loading = false
    }
  },
  methods: {
    setSelectValue () {
      const { key, value } = this
      const callbackFn = (v, reference) => {
        return reference.key === key &&
          reference.value === v &&
          (this.locale ? reference.locale === this.refLocaleCode : true)
      }

      let keysToKeep = []
      if (this.multiple) {
        keysToKeep = value.filter(v => this.references?.some(r => callbackFn(v, r))) ?? false
      }

      if (this.references) {
        this.select = this.multiple
          ? keysToKeep.map(v => this.references.find(r => callbackFn(v, r)))
          : this.references.find(r => callbackFn(value, r))
      }
    },
    emit (selected) {
      if (selected) {
        this.$emit('input', this.multiple ? selected.map(m => m.value) : selected.value)
      } else {
        this.$emit('input', null)
      }
    },
    async fetch (value) {
      const { key, orderBy } = this
      try {
        this.loading = true
        const resp = (await PimFetcher.search(this.slug, {
          key,
          orderBy,
          refLocaleCode: this.refLocaleCode,
          label: value
        })).data.data
        this.items = resp
      } finally {
        this.loading = false
      }
    },
    fetchEntriesDebounced (value) {
      this.$debounce(this.fetch, value)
    },
    ...mapActions(usePimStore, {
      fetchReferences: 'fetchReferences'
    })
  }
}
</script>
