import numeral from '@/plugins/numeral'
import i18n from '@/plugins/vue-i18n'

export default {
  params: ['target', 'notFormatted'],
  message: (_fieldName, placeholders) => i18n.t('validation.lte.numeric', {
    placeholder_1: placeholders.target
  }),
  validate (value, { target, notFormatted }) {
    if (!notFormatted) {
      if (numeral(value).value() <= numeral(target).value()) {
        return true
      }
    } else {
      if (parseFloat(value) <= parseFloat(target)) {
        return true
      }
    }
    return false
  }
}
