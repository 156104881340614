import { defineStore, acceptHMRUpdate } from 'pinia'
import { useAssetsStore } from './assets'
import { useUniverseStore } from './universe'
import EntityTypes from '@/constants/EntityTypes'
import ProjectFetcher from '@/services/fetchers/Project'
import ResourceFetcher from '@/services/fetchers/Resource'
import NeedFetcher from '@/services/fetchers/Need'
import { computed, ref } from 'vue'
import _ from 'lodash'

export const useProjectStore = defineStore('project', () => {
  const loading = ref(false)
  const deleting = ref(false)
  const currentProject = ref(null)
  const selectedNeedId = ref(null)
  const updating = ref(false)
  const reloadRecommendedProducts = ref(false)

  const getAnswers = computed(() => {
    if (!selectedNeedId.value) return []
    return currentProject.value?.needs?.find(f => f.id === selectedNeedId.value)?.answers ?? []
  })
  const getLoading = computed(() => loading.value)
  const getProject = computed(() => currentProject.value)
  const getDeleting = computed(() => deleting.value)
  const getNeed = computed(() => currentProject.value?.needs?.find(f => f.id === selectedNeedId.value) ?? null)
  const getNeeds = computed(() => currentProject.value?.needs ?? [])
  const getNeedTypes = computed(() => {
    const universeStore = useUniverseStore()
    return universeStore.getDictionary('need-type')
  })
  const getSelectedNeedId = computed(() => selectedNeedId.value)
  const getUpdating = computed(() => updating.value)
  const getReloadRecommendedProducts = computed(() => reloadRecommendedProducts.value)

  function addAnswerNeed (answer) {
    const need = currentProject.value.needs.find(f => f.id === answer.needId)
    currentProject.value = {
      ...currentProject.value,
      needs: [
        ...currentProject.value.needs.filter(f => f.id !== answer.needId),
        {
          ...need,
          answers: [...need.answers, answer]
        }
      ]
    }
  }

  function clear () {
    loading.value = false
    currentProject.value = null
    selectedNeedId.value = null
    updating.value = false
  }

  async function deleteProject () {
    try {
      const idProject = currentProject.value.id
      deleting.value = true
      currentProject.value = null
      await ProjectFetcher.delete(idProject)
    } catch (e) {
      throw new Error(e)
    } finally {
      deleting.value = false
    }
  }

  async function deleteNeed (needId) {
    try {
      updating.value = true
      await NeedFetcher.delete(currentProject.value.id, needId)
      const newNeeds = currentProject.value.needs.filter(n => n.id !== needId)
      if (needId === selectedNeedId.value) {
        selectedNeedId.value = newNeeds.length ? newNeeds[0].id : null
      }
      currentProject.value = { ...currentProject.value, needs: newNeeds }
    } finally {
      updating.value = false
    }
  }

  async function fetch (payload) {
    const params = {
      withNeeds: 1,
      withAnswers: 1,
      withQuotations: 1,
      withCompany: 1,
      ...(payload?.extraParams ?? {})
    }
    const project = (await ResourceFetcher.getById(
      EntityTypes.Project,
      payload.projectId,
      params
    )).data.data
    currentProject.value = { ...project }
  }

  async function fetchProject (payload) {
    try {
      loading.value = true
      await fetch(payload)
      selectedNeedId.value = currentProject.value?.needs[0]?.id ?? null
      await fetchProjectNeedsImage()
    } finally {
      loading.value = false
    }
  }

  async function fetchProjectNeedsImage () {
    const universeStore = useUniverseStore()
    _.each(universeStore.getDictionary('need-type'), async (nt) => {
      const avatarDamAssetId = nt.extra?.avatarDamAssetId
      if (nt.extra?.avatarDamAssetId) {
        const assetsStore = useAssetsStore()
        await assetsStore.fetchProjectImage({ avatarDamAssetId })
      }
    })
  }

  async function finalize () {
    try {
      updating.value = true
      await ProjectFetcher.finalize(currentProject.value.id)
      await fetchProject({ projectId: currentProject.value.id })
    } finally {
      updating.value = false
    }
  }

  function setProject (payload) {
    currentProject.value = payload
  }

  function setSelectedNeedId (payload) {
    selectedNeedId.value = payload
  }

  function setReloadRecommendedProducts (payload) {
    reloadRecommendedProducts.value = payload
  }

  function setNeed (payload) {
    const need = currentProject.value.needs.findIndex(f => f.id === payload.id)
    currentProject.value.needs[need] = payload
  }

  async function updateProject (payload) {
    try {
      updating.value = true
      currentProject.value = (await ProjectFetcher.update(currentProject.value.id, payload)).data
    } finally {
      updating.value = false
    }
  }

  return {
    currentProject,
    deleting,
    loading,
    selectedNeedId,
    updating,
    reloadRecommendedProducts,

    getAnswers,
    getDeleting,
    getLoading,
    getProject,
    getNeed,
    getNeeds,
    getNeedTypes,
    getSelectedNeedId,
    getUpdating,
    getReloadRecommendedProducts,

    addAnswerNeed,
    clear,
    deleteProject,
    deleteNeed,
    fetch,
    fetchProject,
    finalize,
    setProject,
    setSelectedNeedId,
    setReloadRecommendedProducts,
    setNeed,
    updateProject
  }
})

if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(acceptHMRUpdate(useProjectStore, import.meta.webpackHot))
}
