<script setup>
import MenuItemIcon from './MenuItemIcon'

defineProps({
  items: {
    type: Array,
    default: () => ([])
  },
  showIcons: {
    type: Boolean,
    default: true
  }
})

function classListItemTitle (menuItem) {
  return {
    'font-weight-bold': menuItem?.enlight ?? ''
  }
}
function executeAction (menuItem) {
  if (menuItem.action) {
    menuItem.action()
  }
}
</script>

<template>
  <v-list class="list">
    <v-list-item
      v-for="(menuItem, menuIndex) in items"
      :key="menuIndex"
      :data-test="'action-' + menuItem.icon"
      @click="executeAction(menuItem)"
    >
      <v-list-item-icon
        v-if="showIcons"
        class="mr-0"
      >
        <menu-item-icon
          :name="menuItem.icon"
          :enlight="menuItem.enlight"
        />
      </v-list-item-icon>
      <v-list-item-title :class="classListItemTitle(menuItem)">
        {{ menuItem.title }}
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>
