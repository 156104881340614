import Vue from 'vue'
import numeral from '@/plugins/numeral'
import moment from '@/plugins/moment'
import entityTypes from '@/constants/EntityTypes'
import axios from '@/plugins/axios'

const utils = {
  numeral,
  moment
}

const utilsPlugins = {
  install () {
    Vue.prototype.$utils = utils

    Vue.prototype.$axios = axios

    Vue.prototype.$entityTypes = entityTypes

    Vue.prototype.$debounce = (callback, value, timeout = 500) => {
      clearTimeout(this._timerId)
      this._timerId = setTimeout(() => {
        callback(value)
      }, timeout)
      return this._timerId
    }
  }
}

Vue.use(utilsPlugins)
