import { render, staticRenderFns } from "./MenuItemIcon.vue?vue&type=template&id=5c5abaf2&scoped=true&"
import script from "./MenuItemIcon.vue?vue&type=script&setup=true&lang=js&"
export * from "./MenuItemIcon.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./MenuItemIcon.vue?vue&type=style&index=0&id=5c5abaf2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c5abaf2",
  null
  
)

export default component.exports