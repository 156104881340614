<template>
  <div>
    <field-entity-user-profile-facilitator
      v-if="editable"
      v-bind="$attrs"
      v-model="users"
      :disabled="disabled"
      return-object
      multiple
      v-on="$listeners"
    />
    <div
      v-else
      class="d-flex align-center mt-5 mb-3"
    >
      <v-icon
        size="14"
        color="black"
        class="mr-2"
      >
        fas fa-users
      </v-icon>

      <custom-router-link
        :has-right="canRead && teamId !== null"
        name="facilitator-list"
        :filters="{ 'teamId': teamId }"
      >
        {{ totalUsers }}
        <span>{{ $tc('common.facilitator-count', totalUsers) }}</span>
      </custom-router-link>

      <a
        v-if="canWrite"
        class="d-flex ml-2"
      >
        <v-icon
          small
          left
          v-text="'fas fa-sync'"
        />
        <span
          class="goto-text"
          @click="setEditUsers"
        >{{ $t('common.modify') }}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FieldTeamUserFacilitator',
  components: {
    FieldEntityUserProfileFacilitator: () => import('../FieldEntityUserProfileFacilitator')
  },
  props: {
    canRead: Boolean,
    canWrite: Boolean,
    disabled: Boolean,
    teamId: {
      type: Number,
      default: null
    },
    value: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      editUsers: false
    }
  },
  computed: {
    editable () {
      return !this.disabled && this.editUsers
    },
    totalUsers () {
      return this.users?.length
    },
    users: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('change', val)
      }
    }
  },
  watch: {
    teamId: {
      immediate: true,
      handler () {
        if (!this.teamId) {
          this.editUsers = false
        }
        this.users = []
      }
    }
  },
  methods: {
    setEditUsers () {
      this.editUsers = true
    }
  }
}
</script>
