export default Object.freeze({
  low: {
    color: 'success'
  },
  medium: {
    color: 'orange'
  },
  high: {
    color: 'error'
  }
})
