<template>
  <v-app>
    <Header
      v-if="appIsReady && profile"
      v-model="drawer"
      :items="menuItems.admin"
      :profile="profile"
      :user="user"
      @click:notification="openNotification"
    />

    <mobile-navigation
      v-if="appIsReady && $vuetify.breakpoint.mobile"
      v-model="drawer"
      :items="menuItems.admin"
    />
    <v-navigation-drawer
      v-model="drawerRight"
      :width="widthDrawerRight"
      :clipped="$vuetify.breakpoint.mdAndUp"
      temporary
      right
      app
      disable-route-watcher
      @input="drawRightIsUp"
    >
      <notifications
        v-if="showNotification && user && profile"
        ref="notification"
        :is-expanded="drawerRightExpanded"
        :user-id="profile.userProfileId"
        :profile-type="profile.type"
        @close="closeDrawerRight"
        @expand="drawerRightExpand"
      />
    </v-navigation-drawer>

    <snackbar />

    <v-main>
      <div class="d-flex justify-end">
        <v-card
          class="d-flex mr-5 py-2 px-4 align-center"
          style="z-index: 3 !important; position: fixed;"
          tile
          flat
        >
          <span class="mr-1">{{ title }}</span>
          <v-btn
            to="/"
            icon
          >
            <v-icon small>
              fa-arrow-circle-left
            </v-icon>
          </v-btn>
        </v-card>
      </div>
      <v-container
        fill-height
        fluid
        class="px-0 pb-0"
        align-start
      >
        <slot />
        <Footer
          v-if="appIsReady"
          :color="footerColor"
          class="align-self-end flex-grow-1"
          data-test="footer"
        />
      </v-container>
    </v-main>
    <global-events target="window" />
  </v-app>
</template>

<script setup>
import { useLayout } from '@/compositions/layout'
import Snackbar from '@/components/Snackbar'
import Notifications from '@/components/Notifications'
import MobileNavigation from './Authenticated/MobileNavigation'
import Header from './Authenticated/Header'
import Footer from './Authenticated/Footer'
import { useAuthStore } from '@/stores/auth'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
import { computed, getCurrentInstance } from 'vue'

const root = getCurrentInstance().proxy

const userStore = useUserStore()
const authStore = useAuthStore()

const { profile, menuItems } = storeToRefs(userStore)
const { user } = storeToRefs(authStore)

const {
  appIsReady,
  drawer,
  drawerRight,
  drawerRightExpanded,
  footerColor,
  showNotification,
  widthDrawerRight,
  closeDrawerRight,
  drawerRightExpand,
  drawRightIsUp,
  openNotification
} = useLayout()

const title = computed(() => root.$t('sticker.admin.text'))
</script>
