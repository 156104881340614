import i18n from '@/plugins/vue-i18n'
import UniverseFetcher from '@/services/fetchers/Universe'
import { useTranslationStore } from '@/stores/translation'
import { useUniverseStore } from '@/stores/universe'
import { useAuthStore } from '@/stores/auth'

export default {
  async checkVersion () {
    const authStore = useAuthStore()
    const universeStore = useUniverseStore()
    const translationsStore = useTranslationStore()

    if (authStore.getUser) {
      const result = (await UniverseFetcher.getUniverseSettings({ key: 'translation' }))?.data?.data ?? null
      const currentVersion = result ? result[0]?.value?.currentVersion ?? null : null
      if (!currentVersion || translationsStore.getVersion !== currentVersion) {
        await translationsStore.loadTranslations(true)
        this.setMessages()
        if (currentVersion) {
          universeStore.updateSetting(result[0])
        }
      }
    } else {
      await translationsStore.loadTranslations(true)
      this.setMessages()
    }
  },
  setMessages () {
    const translationsStore = useTranslationStore()
    const messages = translationsStore.getMessages
    Object.keys(messages).forEach(f => {
      i18n.setLocaleMessage(f, messages[f])
    })
  }
}
