import jsonToFormData from '@/services/jsonToFormData'
import axios from '@/plugins/axios'

export default {
  edit (projectId, needId, params) {
    return axios
      .patch(`projects/${projectId}/needs/${needId}`, Object.assign({}, params, {
        withAnswers: 1
      }))
  },
  cancelAllAnswers (projectId, needId, params) {
    const obj = jsonToFormData(params)
    return axios.post(`projects/${projectId}/needs/${needId}/cancel-all-answers`, obj.data, obj.config)
  },
  create (projectId, params) {
    return axios.post(`projects/${projectId}/needs`, params)
  },
  delete (projectId, needId) {
    return axios.delete(`projects/${projectId}/needs/${needId}`)
  }
}
