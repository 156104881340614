import moment from 'moment'
import i18n from '@/plugins/vue-i18n'

export default {
  params: ['target'],
  message: (_fieldName, placeholders) => i18n.t('validation.after', {
    placeholder_1: moment(placeholders.target).format('L')
  }),
  validate (value, { target }) {
    let val = moment(value, 'L')
    let mi = moment(target, 'L')

    if (!target || !value) {
      return true
    }
    if (!val.isValid()) {
      val = moment(value)
    }
    if (!mi.isValid()) {
      mi = moment(target)
    }
    return val.isAfter(mi) || val.isSame(mi)
  }
}
