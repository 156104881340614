<script setup>
import { ValidationObserver } from 'vee-validate'
import { useSnackbarStore } from '@/stores/snackbar'
import { computed, getCurrentInstance, ref } from 'vue'

const root = getCurrentInstance().proxy

const emits = defineEmits(['closed', 'validated'])

defineProps({
  cancellable: {
    type: Boolean,
    default: true
  },
  disabled: {
    type: Boolean,
    default: false
  },
  cancelLabel: {
    type: String,
    default: null
  },
  confirmLabel: {
    type: String,
    default: null
  },
  emptyActions: Boolean
})

const snackbarStore = useSnackbarStore()

const hasError = ref(false)

const buttonType = computed(() => hasError.value ? 'error' : 'primary')

function showError () {
  hasError.value = true
  snackbarStore.setMessage({
    message: root.$t('common.form.error'),
    color: 'error'
  })
  setTimeout(() => {
    hasError.value = false
  }, 1000)
}
async function submit () {
  const isValid = await root.$refs.observer.validate()
  isValid ? emits('validated', true) : showError()
}
function cancel () {
  emits('closed')
}
</script>

<template>
  <validation-observer ref="observer">
    <v-form
      ref="form"
      @submit.prevent="submit"
    >
      <slot />
      <slot
        v-if="!emptyActions"
        name="actions"
      >
        <div class="mt-5">
          <v-btn
            :disabled="disabled"
            :type="buttonType"
            data-test="form-validation-save"
            color="primary"
            class="mr-5"
            submit
          >
            {{ confirmLabel ? $t(confirmLabel) : $t('common.save') }}
          </v-btn>
          <v-btn
            v-if="cancellable"
            :disabled="disabled"
            color="primary"
            outlined
            @click.prevent="cancel"
          >
            {{ cancelLabel ? $t(cancelLabel) : $t('common.cancel') }}
          </v-btn>
        </div>
      </slot>
    </v-form>
  </validation-observer>
</template>
