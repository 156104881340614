import Echo from 'laravel-echo'
import emitter from 'tiny-emitter/instance'

const defer = function () {
  let res
  const promise = new Promise(function (resolve) {
    res = resolve
  })
  return {
    resolve: res,
    promise
  }
}

const instanciateEcho = function (token) {
  return new Echo({
    broadcaster: 'socket.io',
    host: window.env.VUE_APP_REAL_TIME_HOST,
    client: require('socket.io-client'),
    auth: {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  })
}

let socketInfosProm = defer()

const getSocketInfos = async function () {
  return socketInfosProm && socketInfosProm.promise
}

const technicalId = function (entity) {
  return `Workplace\\Core\\Api\\Broadcast\\Channels\\${entity}Channel`
}

export default {
  async init (userId, token) {
    const echo = instanciateEcho(token)
    echo.connector.socket.on('connect', () => {
      socketInfosProm.resolve({
        userId,
        token,
        echo
      })
    })
  },
  async disconnect () {
    const socketInfo = await getSocketInfos()
    socketInfo.echo.disconnect()
    socketInfosProm = defer()
  },
  async listenNotifications (onNotification, userId) {
    const socketInfo = await getSocketInfos()
    socketInfo.echo.private(`notification.${userId}`).notification(onNotification)
  },
  async listenChannel (entity, onTask) {
    const socketInfo = await getSocketInfos()
    socketInfo.echo.private(`broadcast.tasks.${socketInfo.userId}`).on(technicalId(entity), onTask)
  },
  async listenPresence (here, joining, leaving) {
    const socketInfo = await getSocketInfos()
    socketInfo.echo
      .join('broadcast.presence')
      .here(here)
      .joining(joining)
      .leaving(leaving)
  },
  async listenBroadcast (entity, id) {
    const socketInfo = await getSocketInfos()
    socketInfo.echo.private(`broadcast.${entity.description.toLowerCase()}-${id}`)
      .on(technicalId(entity.description), params => {
        emitter.emit(`${entity.description.toLowerCase()}-${id}.${params.type ?? 'status.updated'}`)
      })
  },
  async listenTalk (id) {
    const socketInfo = await getSocketInfos()
    socketInfo.echo.private(`broadcast.channel-${id}`)
      .on(technicalId('Talk'), () => {
        emitter.emit(`channel-${id}.status.updated`)
      })
  },
  async leaveBroadcast (entity, id) {
    const socketInfo = await getSocketInfos()
    socketInfo.echo.leave(`broadcast.${entity.description.toLowerCase()}-${id}`)
  },
  async leaveTalk (id) {
    const socketInfo = await getSocketInfos()
    socketInfo.echo.leave(`broadcast.channel-${id}`)
  }
}
