<script setup>
import icons from '@/constants/IconsForItemList'
import { computed } from 'vue'

const props = defineProps({
  iconName: {
    type: String,
    default: null
  },
  iconColor: {
    type: String,
    default: null
  },
  iconAlone: {
    type: Boolean,
    default: false
  },
  itemClass: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    required: false,
    default: ''
  },
  value: {
    type: [Number, String],
    default: null
  },
  link: {
    type: [Object, String],
    default: null
  },
  isUnderLink: {
    type: Boolean,
    default: false
  },
  size: {
    type: String,
    values: ['small'],
    default: null
  },
  marginBottom: {
    type: Boolean,
    default: true
  }
})

const hasValue = computed(() => !!props.value || props.value === 0)
const icon = computed(() => icons[props.iconName] ?? null)
const isMailTo = computed(() => props.link.substring && props.link.substring(0, 6) === 'mailto')
const isSmall = computed(() => props.size === 'small')
const itemClasses = computed(() => {
  const classes = ['item-list']
  classes.push(props.marginBottom ? 'item-list--margin-bottom' : '')
  classes.push(isSmall.value ? 'item-list--small' : '')
  classes.push(props.itemClass ?? '')
  return classes
})
</script>

<template>
  <div
    :class="itemClasses"
    class="d-flex align-center"
  >
    <span
      v-if="iconName"
      class="item-list__icon-container"
      :class="{ 'item-list__icon-container--alone': iconAlone }"
    >
      <v-icon
        :size="icon.size"
        :color="iconColor || icon.color"
      >{{ icon.content }}</v-icon>
    </span>
    <template v-if="link">
      <a
        v-if="isMailTo"
        :href="link"
        class="item-list__label"
      >{{ label }}</a>
      <router-link
        v-else
        :to="link"
        class="item-list__link"
      >
        <span
          v-if="hasValue"
          class="item-list__value"
        >{{ value }} </span>
        <span
          class="item-list__label"
          v-html="label"
        />
      </router-link>
    </template>
    <template v-else>
      <span
        v-if="hasValue"
        class="item-list__value"
        :class="{ 'item-list__link': isUnderLink }"
      >{{ value }}&nbsp;</span>
      <span
        :class="{
          'item-list__link': isUnderLink,
          'item-list__label--with-value': hasValue
        }"
        class="item-list__label text-truncate"
        v-html="label"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
.item-list {

  &--margin-bottom {
    margin-bottom: 0.4rem;
  }

  &__icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    margin-right: 0.6rem;
    flex-shrink: 0;

    &--alone {
      width: 1rem;
      justify-content: flex-start;
      background-color: transparent;
    }
  }

  &__link {
    text-decoration: underline;
  }

  &__value {
    font-size: 1.4rem
  }

  &__label {
    font-size: 1rem;

    &--with-value {
      margin-top: 0.35rem;
      margin-left: 0.4rem;
    }
  }

  &--small .item-list__label {
    font-size: 0.8rem;
  }
}
</style>
