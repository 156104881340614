import StatusColors from '../constants/StatusColors'
import { useRefStore } from '@/stores/ref'

export default {
  refStatuses () {
    const refStore = useRefStore()
    return refStore.getRefs?.refStatuses ?? []
  },
  get (slug) {
    const statuses = this.refStatuses()
    return Object.keys(statuses).map(k => this.refStatuses()[k]).find(s => s.slug === slug)
  },
  getById (id) {
    return this.refStatuses()?.find(refStatus => refStatus.id === parseInt(id)) ?? null
  },
  getColorById (id) {
    const slug = this.getById(id)?.slug ?? null
    return slug ? StatusColors[slug] : null
  },
  decisionStatusFromStatusId (id) {
    switch (id) {
      case 10:
        return 'error'
      case 6:
        return 'in-progress'
      case 9:
      default:
        return 'success'
    }
  }
}
