<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="closeOnContentClick"
    :disabled="$attrs.disabled"
    :nudge-right="nudgeRight"
    :offset-y="offsetY"
    :transition="transition"
    max-width="290px"
    min-width="290px"
  >
    <template #activator="{ on, attrs }">
      <validation-provider
        v-slot="{ errors }"
        :name="$attrs.name"
        :rules="dateRules"
        :vid="vid"
      >
        <v-label
          v-if="fixedLabel && displayLabel"
          :dark="fixedLabelDark"
        >
          {{ displayLabel }}
        </v-label>
        <v-text-field
          v-bind="{ ...$attrs, ...attrs}"
          :background-color="backgroundColor"
          :value="dateFormatted"
          :append-icon="appendIcon"
          :dense="dense"
          :error-messages="errors"
          :error="validation && !!errors.length"
          :hide-details="!validation || !errors.length"
          :label="(!fixedLabel && displayLabel) || ''"
          :outlined="outlined"
          :prefix="$t(prefix)"
          v-on="{
            ...on,
            click: !$attrs.readonly && on.click ? on.click : () => {}
          }"
          @change="dateFormatted = $event"
        />
      </validation-provider>
    </template>
    <v-date-picker
      color="primary"
      :first-day-of-week="firstDayOfWeek"
      :picker-date.sync="iPickerDate"
      :max="$attrs.max"
      :min="$attrs.min"
      :no-title="noTitle"
      :value="value"
      @change="changeDate"
    />
  </v-menu>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { useValidation } from '@/compositions/validation'

export default {
  name: 'FieldDatetime',
  components: {
    ValidationProvider
  },
  props: {
    appendIcon: {
      type: String,
      default: 'fas fa-calendar-alt'
    },
    backgroundColor: {
      type: String,
      default: 'white'
    },
    closeOnContentClick: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: true
    },
    firstDayOfWeek: {
      type: [String, Number],
      default: 1
    },
    fixedLabel: Boolean,
    fixedLabelDark: Boolean,
    label: {
      type: String,
      default: null
    },
    noTitle: {
      type: Boolean,
      default: true
    },
    nudgeRight: {
      type: [Number, String],
      default: 40
    },
    offsetY: {
      type: Boolean,
      default: true
    },
    outlined: {
      type: Boolean,
      default: true
    },
    pickerDate: {
      type: String,
      default: null
    },
    prefix: {
      type: String,
      default: null
    },
    rules: {
      type: [String, Object],
      default: () => ({})
    },
    transition: {
      type: [Boolean, String],
      default: 'scale-transition'
    },
    validation: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Object, String],
      default: null
    },
    vid: {
      type: String,
      default: null
    }
  },
  setup (props) {
    const { iRules, displayLabel } = useValidation(props)

    return { iRules, displayLabel }
  },
  data () {
    return {
      menu: false
    }
  },
  computed: {
    iPickerDate: {
      get () {
        return this.pickerDate
      },
      set (val) {
        this.$emit('update:picker-date', val)
      }
    },
    /**
     * This computed property allows us to ignore
     * date rule provided by API that is not applicable
     * to this component.
     */
    dateRules () {
      if (this.validation && this.iRules) {
        return Object.keys(this.iRules).reduce((object, key) => {
          if (key !== 'date') {
            object[key] = this.iRules[key]
          }
          return object
        }, {})
      }
      return null
    },
    dateFormatted: {
      get () {
        if (this.value) {
          return this.$utils.moment(this.value).format('L')
        } else {
          return null
        }
      },
      set (iso) {
        let date = null
        if (!iso || !this.$utils.moment(iso).isValid()) {
          date = null
        } else {
          date = this.$utils.moment(iso, 'L').format('YYYY-MM-DD')
        }
        this.changeDate(date)
      }
    }
  },
  methods: {
    changeDate (isodate) {
      this.$emit('change', isodate ?? null)
    }
  }
}
</script>
