import moment from 'moment'
import getUserLocale from '@/services/getUserLocale'

import 'moment/locale/fr'
import 'moment/locale/en-gb'

moment.defineLocale('fr-fr', {
  parentLocale: 'fr'
})

moment.updateLocale('en-gb', {
  longDateFormat: {
    L: 'YYYY/MM/DD'
  }
})

const defaultLocale = getUserLocale() ||
  window.env.VUE_APP_I18N_LOCALE ||
  window.env.VUE_APP_I18N_FALLBACK_LOCALE

moment.locale(defaultLocale)

export default moment
