<template>
  <div>
    <v-label
      v-if="fixedLabel"
      :dark="fixedLabelDark"
    >
      {{ displayLabel }}
    </v-label>
    <div class="integer-range">
      <div class="integer-range__input mr-3">
        <field-integer
          v-model="gte"
          v-bind="$attrs"
          :label="(!fixedLabel && label || undefined)"
          :prefix="fromPrefix"
          :rules="iRules"
          :validation="validation"
          type="number"
        />
      </div>
      <div class="integer-range__input">
        <field-integer
          v-model="lte"
          v-bind="$attrs"
          :label="(!fixedLabel && label || undefined)"
          :prefix="toPrefix"
          :rules="maxFieldRules"
          :validation="validation"
          type="number"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useValidation } from '@/compositions/validation'
import FieldInteger from '../FieldInteger'
import _ from 'lodash'

export default {
  name: 'FieldIntegerRange',
  components: {
    FieldInteger
  },
  props: {
    fixedLabel: Boolean,
    fixedLabelDark: Boolean,
    label: {
      type: String,
      default: null
    },
    rules: {
      type: [String, Object],
      default: () => ({})
    },
    validation: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: null
    }
  },
  setup (props) {
    const { iRules, displayLabel } = useValidation(props)

    return { iRules, displayLabel }
  },
  computed: {
    fromPrefix () {
      return this.$t('common.range.from')
    },
    toPrefix () {
      return this.$t('common.range.to')
    },
    lte: {
      get () {
        return this.value?.lte
      },
      set (val) {
        this.$emit('input', { lte: val, gte: this.gte })
      }
    },
    gte: {
      get () {
        return this.value?.gte
      },
      set (val) {
        this.$emit('input', { lte: this.lte, gte: val })
      }
    },
    maxFieldRules () {
      if (this.validation) {
        const iRules = _.cloneDeep(this.iRules)
        const alreadyDefinedGreater = iRules.greater_than_or_equal
        if (alreadyDefinedGreater && this.gte) {
          iRules.greater_than_or_equal = Math.max(this.gte, alreadyDefinedGreater)
        } else if (this.gte) {
          iRules.greater_than_or_equal = this.gte
        }
        return iRules
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.integer-range) {
  display: flex;
  align-content: stretch;

  .integer-range__input {
    width: 100%;
  }
}
</style>
